<template>
  <div style="overflow: hidden; ">
    <div class="page-bg"></div>
      <a-card :bordered="false" class="card" hoverable :body-style="{ padding: state.isCellphone ? '20px 15px' : '50px' }">
        <a-spin :spinning="state.loading">
          <a-form
            :model="state.formState"
            :rules="rules"
            autocomplete="off"
            @finish="handleSearch"
          >
            <a-row type="flex" justify="center" wrap :gutter="16">
              <a-col :xs="18" :sm="16" :md="12" :lg="10">
                <a-form-item name="planNo">
                  <a-input
                    v-model:value="state.formState.planNo"
                    :placeholder="$t('public.please_enter_the_tracking_number')"
                    :maxlength="32"
                    size="large"
                    allow-clear
                    @keydown.enter.exact.native="handleSearch"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item>
                  <a-button type="primary" size="large" html-type="submit">{{ $t('common.query') }}</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

          <template v-if="state.isSearched">
            <Empty v-if="isAllEmpty" />
            <template v-else>
              <a-row class="mb-4" justify="center" :gutter="32" wrap>
                <a-col v-if="state.fromAddress">
                  <a-row style="height: 40px;" type="flex" justify="center" align="middle">
                    <a-col>
                      <span class="default-font">{{ $t('common.shipping_country') }}: {{ $i18n.locale === 'zh-CN' ? state.fromAddress.countryCnName : state.fromAddress.countryEnName }}</span>
                      <span v-if="state.fromAddress.iso2" class="default-font">({{ state.fromAddress.iso2 }})</span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col v-if="state.toAddress">
                  <a-row style="height: 40px;" type="flex" justify="center" align="middle">
                    <a-col>
                      <span class="default-font">{{ $t('common.destination_country') }}: {{ $i18n.locale === 'zh-CN' ? state.toAddress.countryCnName : state.toAddress.countryEnName }}</span>
                      <span v-if="state.toAddress.iso2" class="default-font">({{ state.toAddress.iso2 }})</span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col>
                  <a-row style="height: 40px;" type="flex" justify="center" align="middle">
                    <a-col>
                      <span class="default-font">{{ $t('warehouse.total_volume') }}: {{ cmCubicToM(state.totalVolume) }}m<sup>3</sup></span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col>
                  <a-row style="height: 40px;" type="flex" justify="center" align="middle">
                    <a-col>
                      <span class="default-font">{{ $t('warehouse.total_weight') }}: {{ gToKg(state.totalBoxWeight) }}kg</span>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-collapse
                v-model:activeKey="state.activeBoxesKey"
                :bordered="false"
                expandIconPosition="right"
                style="background: rgb(255, 255, 255)"
              >
                <template #expandIcon="{ isActive }">
                  <caret-right-outlined :rotate="isActive ? 90 : 0" />
                </template>
                <a-collapse-panel key="1" class="mb-4" style="border: 0;">
                  <template #header>
                    <div style="border-bottom: 1px solid #d9d9d9; font-weight: bold; font-weight: bold;">{{ $t('warehouse.box_list') }}</div>
                  </template>
                  <Empty v-if="isBoxesEmpty" />
                  <a-row v-else wrap>
                    <a-col
                      v-for="(item, index) in state.boxesList"
                      :key="index"
                      :xs="24" :sm="24" :md="12"
                    >
                      <a-row style="height: 40px;" type="flex" justify="center" align="middle">
                        <a-col>
                          <span class="default-font">
                            {{ item.boxNo }} ({{ item.transportBoxInfo.length || '-' }}x{{ item.transportBoxInfo.width || '-' }}x{{ item.transportBoxInfo.height || '-' }}cm {{ gToKg(item.transportBoxInfo.weight) || '-' }}kg)
                          </span>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-collapse-panel>
                
                <a-collapse-panel key="2" style="border: 0;">
                  <template #header>
                    <div style="border-bottom: 1px solid #d9d9d9; font-weight: bold;">{{ $t('warehouse.trajectory') }}</div>
                  </template>
                  <Empty v-if="isTracksEmpty" />
                  <a-timeline v-else class="mt-4 pl-4">
                    <a-timeline-item
                      v-for="(item, index) in state.tracksList"
                      :key="index"
                    >
                      <a-row type="flex" :gutter="16">
                        <a-col flex="0 0 200px">
                          <div>
                            {{ $filters.utcToCurrentTime(item.trackTime, 'YYYY-MM-DD HH:mm', item.timezone) }}
                          </div>
                          <small style="color: #777;">{{ $t('account.timezone') }}: {{ item.timezone }}</small>
                        </a-col>
                        <a-col flex="1 0 200px" class="default-font">
                          <span v-if="!!item.shippingSpaceStatus">{{ $t($enumLangkey('shippingSpaceStatus', item.shippingSpaceStatus)) }}</span>
                          <span v-else-if="!!item.transportPlanStatus">{{ $t($enumLangkey('transportStatus', item.transportPlanStatus)) }}</span>
                          <template v-for="(additional, ind) in item.additionals" :key="ind">
                            <span v-if="additional.key"> ({{ additional.key }} {{ $filters.utcToCurrentTime(additional.value, 'YYYY-MM-DD HH:mm', additional.timezone) }})</span>
                          </template>
                        </a-col>
                      </a-row>
                    </a-timeline-item>
                  </a-timeline>
                </a-collapse-panel>
              </a-collapse>
            </template>
          </template>
          <Search v-else />
        </a-spin>
      </a-card>
    <WechatDot />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onBeforeUnmount, reactive } from "vue";
import { Spin, Space, Row, Col, Card, Form, Input, Button, Timeline, Collapse } from "ant-design-vue";
import WechatDot from './components/WechatDot.vue';
import Empty from './components/Empty.vue';
import Search from './components/Search.vue';
import { useI18n } from "vue-i18n/index";
import { throttle } from 'lodash'
import { gToKg, cmCubicToM } from '@/utils/general.js'
import { searchTransportTrack } from "@/api/modules/public/index.js";

export default defineComponent({
  name: "public_se_tracking",
  components: {
    ASpace: Space,
    ASpin: Spin,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AButton: Button,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    WechatDot,
    Empty,
    Search,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      loading: false,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      formState: {
        planNo: null,
      },
      boxesList: [],
      tracksList: [],
      fromAddress: null,
      toAddress: null,
      totalBoxWeight: null,
      totalVolume: null,
      
      isSearched: false,
      activeBoxesKey: ['2'],
      isCellphone: false,
    });

    const rules = {
      planNo: [
        { required: true, whitespace: true, message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("public.tracking_number")]) },
      ]
    }

    const isAllEmpty = computed(() => {
      return state.boxesList.length === 0 && state.tracksList.length === 0;
    })

    const isBoxesEmpty = computed(() => {
      return state.boxesList.length === 0;
    })

    const isTracksEmpty = computed(() => {
      return state.tracksList.length === 0;
    })

    const handleSearch = throttle((data) => {
      state.loading = true;
      data = Object.assign({}, data);
      data.planNo = data.planNo.trim();
      searchTransportTrack(data).then(({ result }) => {
        let { tracks, boxes, fromAddress, toAddress, totalBoxWeight, totalVolume } = result;
        state.fromAddress = fromAddress;
        state.toAddress = toAddress;
        state.totalBoxWeight = totalBoxWeight || 0;
        state.totalVolume = totalVolume || 0;

        if (Array.isArray(boxes)) {
          state.boxesList = boxes;
        } else {
          state.boxesList = [];
        }
        
        if (Array.isArray(tracks)) {
          state.tracksList = tracks;
        } else {
          state.tracksList = [];
        }
      }).catch(error => {
      }).finally(() => {
        state.isSearched = true;
        state.loading = false;
      })
    }, 2000, { trailing: false });

    const handleWindowResize = () => {
      let windowWidth = document.documentElement.clientWidth || window.innerWidth;
      if (windowWidth < 576) {
        state.isCellphone = true;
      } else {
        state.isCellphone = false;
      }
    }

    onMounted(() => {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleWindowResize);
    });

    return {
      state,
      rules,
      isAllEmpty,
      isBoxesEmpty,
      isTracksEmpty,
      handleSearch,
      gToKg,
      cmCubicToM,
    };
  },
});
</script>

<style lang="less" scoped>
.page-bg {
  height: 480px;
  background: url("../../assets/image/public/logistics-banner.png") no-repeat;
  background-size: cover;
  z-index: 1;
}

.card {
  width: 80%;
  max-width: 800px;
  min-height: 400px;
  margin: -300px auto 30px;
  z-index: 2;
}

.default-font {
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  .card {
    width: 94%;
    margin-top: -450px;
  }
}
</style>