import client from "../../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  //-------------------------------第一步 -------------------------
  create: "/api/WarehouseService/TransitUserInBound/CreatePlan",
  //-------------------------------第二步 -------------------------
  getAddShipmentDetail:
    "/api/WarehouseService/TransitUserInBound/GetSencondStep",
  getImportShipmentTemplate:
    "/api/WarehouseService/TransitUserInBound/GetImportShipmentTemplate",
  importShipment: "/api/WarehouseService/TransitUserInBound/ImportShipment",
  //-------------------------------第三步 -------------------------
  getPackingList:
    "/api/WarehouseService/TransitUserInBound/GetShipmentBoxPagedList",
  getShipmentList:
    "/api/WarehouseService/TransitUserInBound/GetShipmentPagedList",
  getShipmentListDropdownBox:
    "/api/WarehouseService/TransitUserInBound/GetShipmentNameList",

  //装箱清单的操作----------------
  updateBoxInfo: "/api/WarehouseService/TransitUserInBound/updateShipmentBox",
  deleteBox: "/api/WarehouseService/TransitUserInBound/RemoveShipmentBox",
  getPrintBoxSETB:
    "/api/WarehouseService/TransitUserPrintInBound/PrintSEShpimentBox/SeShipmentBoxLabel.pdf",

  //货件列表的操作-----------------
  getShipmentDetail:
    "/api/WarehouseService/TransitUserInBound/GetShipmentDetailPagedList",
  deleteShipment: "/api/WarehouseService/TransitUserInBound/DeleteShipment",
  //货件批量打印
  getPrintShipmentSETableBatch:
    "/api/WarehouseService/TransitUserPrintInBound/BatchPrintSEShpiment/BatchSeShipmentLabel.pdf",
  //货件的标签
  getPrintShipmentSETable:
    "/api/WarehouseService/TransitUserPrintInBound/PrintSEShpiment/SeShipmentLabel.pdf",
  //货件的所有箱标签
  getPrintShipmentInSETBAll:
    "/api/WarehouseService/TransitUserPrintInBound/BatchPrintSEShpimentBox/BatchSeShipmentBoxLabel.pdf",
  //创建新货件时
  addShipment: "/api/WarehouseService/TransitUserInBound/AddShipment",
  //原有货件添加箱情况下
  batchAddShipmentBox:
    "/api/WarehouseService/TransitUserInBound/BatchAddShipmentBox",

  getCost: "/api/WarehouseService/TransitUserInBound/RefreshInBoundFee",

  cancelPlan: "/api/WarehouseService/TransitUserInBound/CancelPlan",
  completeInBoundPlan:
    "/api/WarehouseService/TransitUserInBound/CompleteInBoundPlan",

  updateAppointmentTime:
    "/api/WarehouseService/TransitUserInBound/UpdateWareZoneAppointmentDate",
  editShipmentRepresentedShop: "/api/WarehouseService/TransitUserInBound/EditShipmentRepresentedShop",
};

export const create = ({
  warehouseId,
  wareZoneAppointmentDate,
  transportationMethod,
  deliveryType,
  trackingNo,
  containerType,
  containerNo,
  planName,
}) => {
  return client.request({
    url: Api.create,
    data: {
      warehouseId,
      wareZoneAppointmentDate,
      transportationMethod,
      deliveryType,
      trackingNo,
      containerType,
      containerNo,
      planName,
    },
    method: "post",
  });
};

export const getAddShipmentDetail = (planId) => {
  return client.request({
    url: Api.getAddShipmentDetail,
    params: { planId },
    method: "get",
  });
};

export const joinDownloadTemplateUrl = () => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.getImportShipmentTemplate
  );
  return url;
};

export const importShipment = (data) => {
  return client.request({
    url: Api.importShipment,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getPackingList = ({
  planId,
  containerBoxStatus,
  boxType,
  inWarehouseErrorCustomerType,
  shipmentName,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getPackingList,
    data: {
      planId,
      containerBoxStatus,
      boxType,
      inWarehouseErrorCustomerType,
      shipmentName,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getShipmentList = ({
  planId,
  name,
  seShipmentNo,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getShipmentList,
    data: { planId, name, seShipmentNo, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getShipmentListDropdownBox = (planId) => {
  return client.request({
    url: Api.getShipmentListDropdownBox,
    params: { planId },
    method: "get",
  });
};

/// <param name="box">
/// {canScanedBoxNo,length,width,height,weight}
/// </param>
export const updateBoxInfo = ({
  planId,
  shipmentId,
  shipmentDetailId,
  box,
}) => {
  return client.request({
    url: Api.updateBoxInfo,
    data: { planId, shipmentId, shipmentDetailId, box },
    method: "post",
  });
};

export const deleteBox = ({ planId, shipmentId, shipmentDetailId }) => {
  return client.request({
    url: Api.deleteBox,
    data: { planId, shipmentId, shipmentDetailId },
    method: "post",
  });
};

export const getPrintBoxSETB = ({ planId, shipmentId, shipmentDetailId }) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintBoxSETB);
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  url = urlHelper.addQueryString(url, "shipmentDetailId", shipmentDetailId);
  return url;
};


export const getPrintShipmentSETableBatch = (planId) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintShipmentSETableBatch);
  url = urlHelper.addQueryString(url, "planId", planId);
  return url;
};


export const getPrintShipmentSETable = ({ planId, shipmentId }) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintShipmentSETable);
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  return url;
};


export const getPrintShipmentInSETBAll = ({ planId, shipmentId }) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintShipmentInSETBAll);
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  return url;
};

export const getShipmentDetail = ({
  shipmentId,
  skipCount,
  maxResultCount,
}) => {
  return client.request({
    url: Api.getShipmentDetail,
    data: { shipmentId, skipCount, maxResultCount },
    method: "post",
  });
};

export const deleteShipment = ({ planId, shipmentId }) => {
  return client.request({
    url: Api.deleteShipment,
    data: { planId, shipmentId },
    method: "post",
  });
};

/// <param name="box">
/// {canScanedBoxNo,length,width,height,weight}
/// </param>
export const addShipment = ({ name, planId, representedShopNo, boxes }) => {
  return client.request({
    url: Api.addShipment,
    data: { name, planId, representedShopNo, boxes },
    method: "post",
  });
};

/// <param name="box">
/// {canScanedBoxNo,length,width,height,weight}
/// </param>
export const batchAddShipmentBox = ({ shipmentId, planId, representedShopNo, boxes }) => {
  return client.request({
    url: Api.batchAddShipmentBox,
    data: { shipmentId, planId, representedShopNo, boxes },
    method: "post",
  });
};

export const getCost = (planId) => {
  return client.request({
    url: Api.getCost,
    params: { planId },
    method: "get",
  });
};

export const cancelPlan = (planId) => {
  return client.request({
    url: Api.cancelPlan,
    data: { planId },
    method: "post",
  });
};

export const completeInBoundPlan = (planId,payFee) => {
  return client.request({
    url: Api.completeInBoundPlan,
    data: { planId,payFee },
    method: "post",
  });
};

export const updateAppointmentTime = ( planId, wareZoneAppointmentDate ) => {
  return client.request({
    url: Api.updateAppointmentTime,
    data: { planId, wareZoneAppointmentDate },
    method: "post",
  });
};

export const editShipmentRepresentedShop = (data) => {
  return client.request({
    url: Api.editShipmentRepresentedShop,
    data,
    method: "POST",
  });
};
