<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('common.edit')"
    :centered="true"
    :mask-closable="false"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      :model="state.formState"
      :label-col="{ style: { width: '150px'} }"
      :wrapper-col="{ span: 15 }"
    >
      <a-form-item :label="$t('account.customer_number')" name="representedShopNo">
        <a-input v-model:value="state.formState.representedShopNo" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, Modal, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { editShipmentRepresentedShop } from "@/api/modules/transit/inPlan/plan.js";

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shipmentId: String,
    shopNo: String,
  },
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        representedShopNo: null,
      },
    })

    const handleConfirm = async () => {
      try {
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState, { shipmentId: props.shipmentId });
        await editShipmentRepresentedShop(data);
        message.success(vueI18n.t('common.succeed'));
        close();
        emit('confirm');
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => [props.visible, props.shopNo], ([visible, shopNo]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          state.formState.representedShopNo = shopNo ?? null;
        }
      }
    }, { immediate: true })

    return {
      state,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>