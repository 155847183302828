import client from "@/api/client";

const api = {
  // 库存管理-获取货件详情分页列表
  getTransitShipmentDetailListPaged: "/api/WarehouseService/TransitShipmentDetail/GetListPaged",
  // 货件管理-获取所有货件分页列表
  getTransitShipmentListPaged: "/api/WarehouseService/TransitShipment/GetListPaged",
  // 货件管理-获取货件分页列表-代理我的
  getRepresentShopListPaged: "/api/WarehouseService/TransitShipment/GetRepresentShopListPaged",
}

export const getTransitShipmentDetailListPaged = (data) => {
  return client.request({
    url: api.getTransitShipmentDetailListPaged,
    data,
    method: "POST",
  });
};

export const getTransitShipmentListPaged = (data) => {
  return client.request({
    url: api.getTransitShipmentListPaged,
    data,
    method: "POST",
  });
};

export const getRepresentShopListPaged = (data) => {
  return client.request({
    url: api.getRepresentShopListPaged,
    data,
    method: "POST",
  });
};