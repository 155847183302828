<template>
  <a-modal
    :visible="visible"
    :mask-closable="false"
    :title="$t('warehouse.track_info')"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <!-- 默认插槽 -->
      <slot ></slot>
      <a-row :gutter="24" >
        <a-col flex="0 1 150px" style="text-align: right">
          <slot name="numberName"></slot>
        </a-col>
        <a-col flex="1 1 300px">
          <slot name="no"></slot>
        </a-col>
      </a-row>
      <a-empty
        class="mt-4"
        v-if="!list || list.length == 0"
        :image="Empty.PRESENTED_IMAGE_SIMPLE"
      />
      <a-timeline v-else class="ml-5 mt-4">
      <div style="max-height: 500px; overflow-y: auto;" class="p-1">
        <a-timeline-item v-for="(item, index) in list" :key="index">
           <div v-if="item.timezone">
            {{ $filters.utcToSpecificTime(item.trackTime, item.timezone,dateFormat) }} (Time Zone: {{item.timezone}})
          </div>
          <div v-else>{{ $filters.utcToCurrentTime(item.trackTime,dateFormat) }}  (Time Zone: {{getTimezone()}})</div>
          <div>
            <span>{{ getters.language === 'zh-CN' ? item.trackCnRemark : item.trackRemark }}</span>
            <template v-for="(additional, ind) in item.additionals" :key="ind">
              <span v-if="additional.key"> ({{ additional.key }} {{ $filters.utcToCurrentTime(additional.value, 'YYYY-MM-DD HH:mm', additional.timezone) }})</span>
            </template>
          </div>
        </a-timeline-item>
      </div>
      </a-timeline>
    </a-spin>
    <template #footer>
      <a-button @click="handleCancel">{{ $t("common.close") }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";
import { Modal, Spin, Row, Col, Timeline, Button, Empty } from "ant-design-vue";
import { useStore } from "vuex";
import { getTimezone } from "@/hooks/login/index";

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    AButton: Button,
    AEmpty: Empty,
  },
  props: {
    visible: {
      required: true,
      default: false,
    },
    loading: {
      required: false,
      default: false,
    },
    list: {
      required: true,
      default: [],
    },
    dateFormat:{
      required: false,
      default: "yyyy-MM-DD HH:mm",
    },
    emits: ["update:visible"],
  },
  setup(props, { emit }) {
    const { getters } = useStore();

    const handleCancel = () => {
      emit("update:visible", false);
    };
    return {
      Empty,
      getTimezone,
      getters,
      handleCancel,
    };
  },
});
</script>
