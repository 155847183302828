export default {
  "enum": {
    "empty": "",
    "transport_characteristics_1": "General Cargo",
    "transport_characteristics_2": "Supporting Battery",
    "transport_characteristics_4": "Built In Battery",
    "transport_characteristics_8": "Independent Battery",
    "transport_characteristics_16": "Liquid",
    "transport_characteristics_32": "Weak Magnetism",
    "transport_characteristics_64": "Magnetic & Electrified",
    "transport_characteristics_128": "Textile",
    "battery_type_1": "A",
    "battery_type_2": "AA",
    "battery_type_3": "AAA",
    "battery_type_4": "AAAA",
    "battery_type_5": "SC",
    "battery_type_6": "C",
    "battery_type_7": "D",
    "battery_type_8": "N",
    "battery_type_9": "F",
    "battery_type_10": "CR2",
    "battery_type_11": "CR5",
    "battery_type_12": "CR123A",
    "battery_type_13": "Nonstandard Battery",
    "battery_type_14": "Product Specific Battery",
    "battery_type_15": "Lithium Metal Battery",
    "battery_type_16": "Lithium Polymer Battery",
    "battery_type_17": "Lithium Ion Battery",
    "battery_type_18": "9V",
    "battery_type_19": "6V",
    "battery_type_20": "12V",
    "battery_type_21": "P76",
    "battery_type_22": "LR41",
    "battery_type_23": "LR44",
    "battery_type_24": "LR63",
    "battery_type_25": "LR60",
    "battery_type_26": "LR59",
    "battery_type_27": "LR66",
    "battery_type_28": "LR48",
    "battery_type_29": "LR69",
    "battery_type_30": "LR57",
    "battery_type_31": "LR45",
    "battery_type_32": "LR54",
    "battery_type_33": "LR55",
    "battery_type_34": "LR58",
    "battery_type_35": "LR43",
    "battery_type_36": "MR-9",
    "battery_type_37": "CR2025",
    "battery_type_38": "CR2016",
    "battery_type_39": "CR2032",
    "battery_type_40": "CR2430",
    "battery_type_41": "CR2450",
    "battery_type_42": "CR2412",
    "battery_type_43": "CR3032",
    "battery_type_44": "CR2330",
    "battery_type_45": "CR2320",
    "battery_type_46": "CR2325",
    "battery_ingredient_1": "Lithium Ion",
    "battery_ingredient_2": "Lithium Manganese Dioxide",
    "battery_ingredient_3": "Lithium Polymer",
    "battery_ingredient_4": "Lithium Metal",
    "battery_ingredient_5": "Lithium",
    "battery_ingredient_6": "Lithium Air",
    "battery_ingredient_7": "Lead Acid Agm",
    "battery_ingredient_8": "Lithium Cobalt",
    "battery_ingredient_9": "Lithium Nickel Cobalt Aluminum",
    "battery_ingredient_10": "Lithium NMickel Manganese Cobalt",
    "battery_ingredient_11": "Lithium Phosphate",
    "battery_ingredient_12": "Lithium Titanate",
    "battery_ingredient_13": "Nickel Iron",
    "battery_ingredient_14": "Nickel Zinc",
    "battery_ingredient_15": "Silver Calcium",
    "battery_ingredient_16": "Silver Zinc",
    "battery_ingredient_17": "Zinc Air",
    "battery_ingredient_18": "Lithium Thionyl Chloride",
    "battery_ingredient_19": "Nickel Oxyhydroxide",
    "battery_ingredient_20": "Zinc Chloride",
    "battery_ingredient_21": "Zinc Carbon",
    "battery_ingredient_22": "Mercury Oxide",
    "battery_ingredient_23": "Sealed Lead Acid",
    "battery_ingredient_24": "NiMh",
    "battery_ingredient_25": "Alkaline",
    "battery_ingredient_26": "NiCAD",
    "battery_ingredient_27": "Silver Oxide",
    "battery_ingredient_28": "Lead Calcium",
    "battery_ingredient_29": "Aluminum Oxygen",
    "battery_ingredient_30": "Lead Acid",
    "battery_ingredient_31": "Zinc",
    "battery_ingredient_32": "Manganese",
    "product_warehouse_status_0": "Waiting for stocking in",
    "product_warehouse_status_1": "Stocked In",
    "product_warehouse_status_2": "Waiting for stocking out",
    "product_warehouse_status_3": "Stocked Out",
    "box_status_10": "Waiting for stocking in",
    "box_status_20": "Stocked In",
    "box_status_30": "Waiting for service",
    "box_status_40": "Waiting for stocking out",
    "box_status_50": "Waiting for handing over/Stocked Out",
    "box_status_60": "Handed Over",
    "in_warehouse_status_10": "Planning",
    "in_warehouse_status_20": "Waiting for payment",
    "in_warehouse_status_30": "Distributed",
    "in_warehouse_status_40": "Stocking In",
    "in_warehouse_status_50": "Completed",
    "in_warehouse_status_200": "Cancel",
    "out_plan_status_1": "Planning",
    "out_plan_status_2": "Waiting for stocking out",
    "out_plan_status_3": "Stocking Out",
    "out_plan_status_4": "Stocking out completed",
    "out_plan_status_5": "Cancel out-stock plan",
    "warehouse_type_1": "Stocking Warehouse",
    "warehouse_type_2": "Distribution Warehouse",
    "warehouse_type_4": "Logistics Center",
    "warehouse_type_8": "中转仓",
    "in_warehouse_type_1": "Transferred from Stocking Warehouse",
    "in_warehouse_type_2": "Transferred from Distribution Warehouse",
    "in_warehouse_type_3": "Other Stocking In",
    "in_warehouse_type_5": "Direct Stocking In",
    "in_warehouse_type_6": "Allocation Stocking In",
    "out_warehouse_type_1": "Warehouse Allocation",
    "out_warehouse_type_2": "Transferred to local Distribution Warehouse",
    "out_warehouse_type_3": "FBA Warehouse",
    "out_warehouse_type_4": "Other adress",
    "out_warehouse_type_5": "Mercado Libre",
    "product_in_warehouse_error_type_1": "Overtime on receiving",
    "product_in_warehouse_error_type_2": "Incorrect product",
    "survey_status_1": "Request investigation",
    "survey_status_2": "Investigation completed",
    "product_error_dispose_type_11": "漏发",
    "product_error_dispose_type_121": "仓库丢失",
    "product_error_dispose_type_122": "未成功接收",
    "product_error_dispose_type_123": "仓库已找到货",
    "product_error_dispose_type_21": "放弃入库并销毁",
    "product_error_dispose_type_22": "更换货号",
    "product_error_dispose_type_23": "更换标签",
    "product_error_dispose_type_12": "请求调查",
    "logistics_way_1": "Ocean+Courier",
    "logistics_way_2": "Air+Courier",
    "logistics_way_3": "Business Courier",
    "logistics_way_4": "Ocean+Trucking",
    "logistics_way_5": "Post Service",
    "logistics_way_6": "卡派",
    "delivery_way_10": "DHL",
    "delivery_way_20": "UPS",
    "delivery_way_30": "USPS",
    "delivery_way_40": "FEDEX",
    "delivery_way_50": "ESTAFETA",
    "delivery_way_60": "PAQUETEXPRESS",
    "delivery_way_70": "AMZL",
    "delivery_way_80": "MEXICOPOST",
    "delivery_way_90": "GRUPOAMPM",
    "delivery_way_100": "MERCADOLIBRELOGISTIC",
    "delivery_way_110": "JTEXPRESSMX",
    "delivery_way_120": "REDPACK",
    "delivery_way_130": "IMILE",
    "delivery_way_9980": "TRUCK",
    "delivery_way_9990": "OTHERS",
    "delivery_status_1": "Waiting for handing over",
    "delivery_status_2": "Waiting for handing over-reservation delay",
    "delivery_status_3": "Waiting for logistics plan",
    "delivery_status_4": "Completed",
    "label_dispose_1": "Lable by user",
    "label_dispose_2": "Lable by warehouse",
    "agent_order_status_10": "Order placed.",
    "agent_order_status_20": "Cancel",
    "agent_order_status_30": "In processing",
    "agent_order_status_40": "Shipped out",
    "agent_order_status_50": "Delivered",
    "agent_order_status_60": "Shipment canceled",
    "pay_way_10": "Pay with balance",
    "pay_way_20": "Pay with credit",
    "warehouse_fee_10": "Storage Fee",
    "warehouse_fee_20": "Discharge Fee",
    "warehouse_fee_30": "Label Fee",
    "warehouse_fee_40": "Outbound Handling Fee",
    "warehouse_fee_50": "Order Handling Fee",
    "warehouse_fee_60": "Pallet Fee",
    "warehouse_fee_70": "Packing Fee",
    "warehouse_fee_80": "Collect Fee",
    "warehouse_fee_90": "Allocation Fee",
    "warehouse_fee_110": "Shipping Fee",
    "warehouse_fee_120": "Delivery Area Surcharge",
    "warehouse_fee_130": "Commercial Area Delivery Charge",
    "warehouse_fee_140": "Category Surcharge",
    "order_logistics_fee_10": "Storage Fee",
    "order_logistics_fee_20": "Discharge Fee",
    "order_logistics_fee_30": "Label Fee",
    "order_logistics_fee_40": "Outbound Handling Fee",
    "order_logistics_fee_50": "Order Handling Fee",
    "order_logistics_fee_60": "Pallet Fee",
    "order_logistics_fee_70": "Packing Fee",
    "order_logistics_fee_80": "Collect Fee",
    "order_logistics_fee_90": "Allocation Fee",
    "order_logistics_fee_100": "Compensation Fee",
    "order_logistics_fee_110": "Breaking Appointment Fee",
    "order_logistics_fee_120": "Return Service Fee",
    "order_logistics_fee_130": "Standard Inspection Fee",
    "order_logistics_fee_140": "Disposal Fee",
    "order_logistics_fee_150": "Extra Function Inspection Fee",
    "order_logistics_fee_160": "Manual Recognization Fee",
    "order_logistics_fee_170": "3rd Party Logistics Fee",
    "order_logistics_fee_180": "暂存费",
    "order_logistics_fee_190": "合并包装费",
    "order_logistics_fee_500": "Shipping Fee",
    "order_logistics_fee_501": "DDP Pre-declaration Fee",
    "order_logistics_fee_502": "Receipt Signature Fee",
    "order_logistics_fee_510": "General Trading Customs Declaration Fee",
    "order_logistics_fee_520": "Special Product Surcharge",
    "order_logistics_fee_530": "Product Name Surcharge",
    "order_logistics_fee_540": "Extra Page Surcharge",
    "order_logistics_fee_550": "Non-FBA Address Surcharge",
    "order_logistics_fee_560": "Delivery Area Surcharge",
    "order_logistics_fee_570": "Extended Delivery Area Surcharge",
    "order_logistics_fee_580": "Remote Delivery Surcharge",
    "order_logistics_fee_590": "Over Size Surcharge",
    "order_logistics_fee_600": "Large Parcel Surcharge",
    "order_logistics_fee_610": "Tail Delivery Surcharge",
    "order_logistics_fee_690": "Other Surcharge",
    "order_logistics_fee_700": "Extra Surcharge",
    "order_logistics_fee_1000": "Recharge",
    "order_logistics_fee_1010": "Manual deduction",
    "allot_plan_status_20": "Planned",
    "allot_plan_status_30": "Processing",
    "allot_plan_status_40": "Allocated",
    "allot_plan_status_50": "已取消",
    "aging_0": "Sign for Receipt",
    "aging_10": "Pick Up",
    "destination_type_1": "SE Warehouse",
    "destination_type_2": "FBA Warehouse",
    "destination_type_3": "Other Address",
    "destination_type_4": "Mercado Libre",
    "transport_routes_1": "International Shipping",
    "transport_routes_2": "Domestic Shipping",
    "transport_status_10": "Planning",
    "transport_status_20": "Waiting for Pick Up",
    "transport_status_30": "Pick Up on door",
    "transport_status_40": "Pick Up completed",
    "transport_status_50": "Operation center waiting for receiving",
    "transport_status_60": "Receiving completed by operation center",
    "transport_status_70": "Waiting for audit",
    "transport_status_80": "Waiting for payment",
    "transport_status_90": "Payment Settled",
    "transport_status_100": "In Shipping",
    "transport_status_110": "Arrived at the Dest Warehouse",
    "transport_status_120": "Out For Delivery",
    "transport_status_130": "Delivered",
    "transport_status_999": "Cancelled",
    "overdraft_status_1": "Normal",
    "overdraft_status_2": "Overdue",
    "transaction_type_10": "Payment",
    "transaction_type_20": "Compensation",
    "transaction_type_30": "Recharge",
    "transaction_type_40": "Refund",
    "business_type_10": "Recharge",
    "business_type_20": "Stocking out from stock warehouse",
    "business_type_30": "Order Distribution",
    "business_type_40": "Shipping Plan",
    "business_type_50": "Storage Compensation",
    "business_type_60": "Shipping Compensation",
    "business_type_70": "FCL reservation for stocking in",
    "business_type_80": "Stored in stock warehouse",
    "business_type_90": "Stored in distribution warehouse",
    "business_type_100": "Allocated to stock warehouse",
    "business_type_110": "Customized Service",
    "business_type_120": "Return Cargo Handling",
    "business_type_130": "Storage Warehouse Inbound",
    "business_type_140": "Distribution Warehouse Inbound",
    "business_type_150": "Breaking Reservation Fee",
    "business_type_160": "Shipping Label",
    "business_type_170": "Return Plan",
    "business_type_180": "Manual Refund",
    "audit_state_0": "Unsubmitted",
    "audit_state_1": "Auditing",
    "audit_state_2": "Audit Passed",
    "audit_state_3": "Audit Failed",
    "recharge_type_10": "Remittance",
    "recharge_type_20": "Payoneer",
    "recharge_type_30": "企业支付宝",
    "recharge_status_10": "Confirming",
    "recharge_status_20": "Succeeded",
    "recharge_status_30": "Failed",
    "recharge_status_40": "待提交",
    "recharge_status_50": "已取消",
    "container_type_1": "20GP",
    "container_type_2": "40GP",
    "container_type_3": "40HQ",
    "container_type_4": "45HQ",
    "full_container_in_warehouse_type_10": "Reserved",
    "full_container_in_warehouse_type_30": "Reservation Broke",
    "full_container_in_warehouse_type_31": "Starting Discharge",
    "full_container_in_warehouse_type_40": "Discharge Completed",
    "reject_in_account_1": "Payment was rejected",
    "reject_in_account_2": "Payment unreceived",
    "reject_in_account_3": "Incorrect payment amount",
    "transportation_delivery_way_1": "SE Logistics",
    "transportation_delivery_way_2": "Self pick up",
    "self_mention_type_1": "Truck",
    "self_mention_type_2": "Courier",
    "return_plan_source_type_1": "Amazon",
    "return_plan_source_type_2": "Walmart",
    "return_plan_source_type_3": "3rd party warehouse",
    "return_plan_source_type_4": "Others",
    "return_plan_record_status_10": "Stocked In",
    "return_plan_record_status_20": "Disposal",
    "return_plan_status_10": "Planned",
    "return_plan_status_20": "Receiving",
    "return_plan_status_30": "Completed",
    "return_plan_status_90": "Cancelled",
    "waybill_operation_status_1": "Forecasted",
    "waybill_operation_status_2": "Trcking to be online",
    "waybill_operation_status_3": "To be received and signed.",
    "waybill_operation_status_4": "Received and Signed",
    "waybill_operation_status_5": "Cancelled",
    "waybill_operation_status_6": "手动取消",
    "waybill_operation_status_999": "Exception",
    "return_plan_fnsku_type_1": "Scannable Information",
    "return_plan_fnsku_type_2": "Manual Recognized",
    "agent_order_logistics_type_1": "SE Logistics",
    "agent_order_logistics_type_2": "3rd Party Logistics Waybill",
    "fee_calculation_method_0": "模板计算",
    "fee_calculation_method_1": "卡车接口",
    "pack_material_type_0": "外包装",
    "pack_material_type_1": "合并包装",
    "pack_material_type_2": "缠膜包装",
    "shipping_space_status_10": "In distribution.",
    "shipping_space_status_20": "The goods have left the shipping warehouse.",
    "shipping_space_status_30": "The goods have arrived at the port of departure.",
    "shipping_space_status_40": "Customs declaration in progress.",
    "shipping_space_status_45": "Customs inspection.",
    "shipping_space_status_50": "The goods have been cleared for customs.",
    "shipping_space_status_60": "Plan to leave the port.",
    "shipping_space_status_65": "Cargo rejection by shipping line due to space shortages.",
    "shipping_space_status_70": "The goods have left the port.",
    "shipping_space_status_80": "Plan to arrive at the port.",
    "shipping_space_status_90": "The goods have arrived at the port.",
    "shipping_space_status_100": "Customs clearance in progress.",
    "shipping_space_status_110": "Goods have cleared customs and are released.",
    "shipping_space_status_120": "Goods arrived at the overseas warehouse.",
  }
}