export const getDynamicRoutes = () => {
  return [
    {
      id: 1,
      parent: 0,
      path: "/dashboard",
      name: "dashboard_index",
      type: "router",
      icon: "DashboardOutlined",
      title: "overview",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.overview",
    },
    {
      id: 2,
      parent: 0,
      path: "/product",
      name: "product_management",
      type: "group",
      icon: "MacCommandOutlined",
      title: "Product Management",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.product_management",
    },
    {
      id: 201,
      parent: 2,
      path: "/product/list",
      name: "product_list",
      type: "router",
      icon: "BarsOutlined",
      title: "Product List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.product_list",
    },
    {
      id: 202,
      parent: 2,
      path: "/product/edit/:id",
      name: "product_edit",
      type: "router",
      icon: "DashboardOutlined",
      title: "Product Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.product_edit",
    },
    {
      id: 203,
      parent: 2,
      path: "/product/create/:id?",
      name: "product_create",
      type: "router",
      icon: "DashboardOutlined",
      title: "Product Create",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.product_create",
    },
    {
      id: 204,
      parent: 2,
      path: "/product/inventory",
      name: "product_inventory",
      type: "router",
      icon: "UngroupOutlined",
      title: "Product inventory",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.product_inventory",
    },
    {
      id: 3,
      parent: 0,
      path: "/storage",
      name: "storage_management",
      type: "group",
      icon: "ShopOutlined",
      title: "Storage Management",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.storage_management",
    },
    {
      id: 301,
      parent: 3,
      path: "/storage/inventory",
      name: "storage_inventory",
      type: "router",
      icon: "TableOutlined",
      title: "Storage Inventory",
      sort: 1,
      link: "",
      hidden: false,
      text: "menu.storage_inventory",
    },
    {
      id: 302,
      parent: 3,
      path: "/storage/inbound/list",
      name: "storage_inbound_list",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Warehousing Plan",
      sort: 2,
      link: "",
      hidden: false,
      text: "menu.storage_in_warehouse_plan",
    },
    {
      id: 303,
      parent: 3,
      path: "/storage/inbound/create/:id?",
      name: "storage_inbound_create",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Storage Inbound Create",
      sort: 3,
      link: "",
      hidden: true,
      text: "menu.storage_inbound_create",
    },
    {
      id: 304,
      parent: 3,
      path: "/storage/inbound/confirm/:id",
      name: "storage_inbound_confirm",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Storage Inbound Confirm",
      sort: 4,
      link: "",
      hidden: true,
      text: "menu.storage_inbound_confirm",
    },
    {
      id: 305,
      parent: 3,
      path: "/storage/inbound/shipping/:id",
      name: "storage_inbound_shipping",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Storage Inbound Shipping",
      sort: 5,
      link: "",
      hidden: true,
      text: "menu.storage_inbound_shipping",
    },
    {
      id: 306,
      parent: 3,
      path: "/storage/inbound/detail/:id",
      name: "storage_inbound_detail",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Storage Inbound Detail",
      sort: 6,
      link: "",
      hidden: true,
      text: "menu.storage_inbound_detail",
    },
    {
      id: 307,
      parent: 3,
      path: "/storage/outplan",
      name: "storage_out_plan",
      type: "router",
      icon: "DeleteColumnOutlined",
      title: "Out Warehouse Plan",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.storage_out_plan",
    },
    {
      id: 308,
      parent: 3,
      path: "/storage/outplandetails/:id",
      name: "storage_out_plan_details",
      type: "router",
      icon: "BarsOutlined",
      title: "Warehousing Out Warehouse Plan Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.storage_out_plan_details",
    },
    {
      id: 309,
      parent: 3,
      path: "/storage/selectoutWay",
      name: "storage_select_out_way",
      type: "router",
      icon: "BarsOutlined",
      title: "Select Out Warehouse Plan Way",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.storage_create_out_plan",
    },
    {
      id: 312,
      parent: 3,
      path: "/storage/confirmoutplan/:id",
      name: "storage_confirm_out_plan",
      type: "router",
      icon: "BarsOutlined",
      title: "Add Box Info",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.storage_confirm_out_plan",
    },
    {
      id: 320,
      parent: 3,
      path: "/storage/addboxinfo",
      name: "storage_add_box_info",
      type: "router",
      icon: "BarsOutlined",
      title: "Add Box Info",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.storage_add_box_info",
    },
    {
      id: 330,
      parent: 3,
      path: "/storage/labelchange",
      name: "storage_label_change_list",
      type: "router",
      icon: "InteractionOutlined",
      title: "Label Change List",
      sort: 30,
      link: "",
      hidden: false,
      text: "menu.storage_label_change_list",
    },
    {
      id: 331,
      parent: 3,
      path: "/storage/labelchange/:id",
      name: "storage_label_change_detail",
      type: "router",
      icon: "InteractionOutlined",
      title: "Label Change Detail",
      sort: 31,
      link: "",
      hidden: true,
      text: "menu.storage_label_change_detail",
    },
    {
      id: 332,
      parent: 3,
      path: "/storage/labelchange/create",
      name: "storage_label_change_create",
      type: "router",
      icon: "InteractionOutlined",
      title: "Create Label Change",
      sort: 32,
      link: "",
      hidden: true,
      text: "menu.storage_label_change_create",
    },
    {
      id: 333,
      parent: 3,
      path: "/storage/labelchange/uploadtags/:id",
      name: "storage_label_change_uploadtags",
      type: "router",
      icon: "InteractionOutlined",
      title: "Create Label Change",
      sort: 33,
      link: "",
      hidden: true,
      text: "menu.storage_label_change_create",
    },
    {
      id: 334,
      parent: 3,
      path: "/storage/labelchange/confirm/:id",
      name: "storage_label_change_confirm",
      type: "router",
      icon: "InteractionOutlined",
      title: "Create Label Change",
      sort: 34,
      link: "",
      hidden: true,
      text: "menu.storage_label_change_create",
    },
    {
      id: 6,
      parent: 0,
      path: "/consignment",
      name: "consignment_send_management",
      type: "group",
      icon: "GoldOutlined",
      title: "Consignment Management",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.consignment_send_management",
    },
    {
      id: 601,
      parent: 6,
      path: "/consignment/inbound/list",
      name: "consignment_inbound_list",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Consignment In Plan List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.consignment_in_plan",
    },
    {
      id: 630,
      parent: 6,
      path: "/consignment/inbound/create/:id?",
      name: "consignment_inbound_create",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Consignment Inbound Create",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.consignment_inbound_create",
    },
    {
      id: 631,
      parent: 6,
      path: "/consignment/inbound/confirm/:id",
      name: "consignment_inbound_confirm",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Consignment Inbound Confirm",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.consignment_inbound_confirm",
    },
    {
      id: 632,
      parent: 6,
      path: "/consignment/inbound/shipping/:id",
      name: "consignment_inbound_shipping",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Consignment Inbound Shipping",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.consignment_inbound_shipping",
    },
    {
      id: 632,
      parent: 6,
      path: "/consignment/inbound/detail/:id",
      name: "consignment_inbound_detail",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "Consignment Inbound Detail",
      sort: 320,
      link: "",
      hidden: true,
      text: "menu.consignment_inbound_detail",
    },
    {
      id: 603,
      parent: 6,
      path: "/consignment/inventory",
      name: "consignment_inventory",
      type: "router",
      icon: "TableOutlined",
      title: "Inventory",
      sort: 30,
      link: "",
      hidden: false,
      text: "menu.consignment_inventory",
    },
    {
      id: 604,
      parent: 6,
      path: "/consignment/order/list",
      name: "consignment_order_list",
      type: "router",
      icon: "OrderedListOutlined",
      title: "Shipping Order",
      sort: 40,
      link: "",
      hidden: false,
      text: "menu.consignment_order_list",
    },
    {
      id: 605,
      parent: 6,
      path: "/consignment/order/create",
      name: "consignment_order_create",
      type: "router",
      icon: "GoldOutlined",
      title: "Create Order",
      sort: 50,
      link: "",
      hidden: true,
      text: "menu.consignment_order_create",
    },
    {
      id: 606,
      parent: 6,
      path: "/consignment/order/addproduct",
      name: "consignment_order_add_product",
      type: "router",
      icon: "GoldOutlined",
      title: "Add Product",
      sort: 60,
      link: "",
      hidden: true,
      text: "menu.consignment_order_add_product",
    },
    {
      id: 607,
      parent: 6,
      path: "/consignment/order/selectlogistics",
      name: "consignment_order_select_logistics",
      type: "router",
      icon: "GoldOutlined",
      title: "Select Logistics",
      sort: 70,
      link: "",
      hidden: true,
      text: "menu.consignment_order_select_logistics",
    },
    {
      id: 608,
      parent: 6,
      path: "/consignment/orderdetails/:id",
      name: "consignment_order_details",
      type: "router",
      icon: "GoldOutlined",
      title: "Order Details",
      sort: 80,
      link: "",
      hidden: true,
      text: "menu.consignment_order_details",
    },
    {
      id: 609,
      parent: 6,
      path: "/consignment/allot/plans",
      name: "consignment_allot_plans",
      type: "router",
      icon: "FileDoneOutlined",
      title: "Allot To Store Plan",
      sort: 90,
      link: "",
      hidden: false,
      text: "menu.consignment_allot_plans",
    },
    {
      id: 610,
      parent: 6,
      path: "/consignment/allot/create",
      name: "consignment_allot_create",
      type: "router",
      icon: "GoldOutlined",
      title: "Create Allot",
      sort: 10,
      link: "",
      hidden: true,
      text: "menu.consignment_allot_create",
    },
    {
      id: 611,
      parent: 6,
      path: "/consignment/allot/setbox/:warehouseId?/:warehouseNo?",
      name: "consignment_allot_set_box",
      type: "router",
      icon: "GoldOutlined",
      title: "Set Box",
      sort: 110,
      link: "",
      hidden: true,
      text: "menu.consignment_allot_set_box",
    },
    {
      id: 612,
      parent: 6,
      path: "/consignment/allot/confirm",
      name: "consignment_allot_confirm",
      type: "router",
      icon: "GoldOutlined",
      title: "Confirm Plan",
      sort: 120,
      link: "",
      hidden: true,
      text: "menu.consignment_allot_confirm",
    },
    {
      id: 613,
      parent: 6,
      path: "/consignment/allot/plandetails/:id",
      name: "consignment_allot_plan_details",
      type: "router",
      icon: "GoldOutlined",
      title: "Allot Plan Details",
      sort: 130,
      link: "",
      hidden: true,
      text: "menu.consignment_allot_plan_details",
    },
    {
      id: 614,
      parent: 6,
      path: "/consignment/returnchannel/list",
      name: "consignment_return_channel_list",
      type: "router",
      icon: "ExportOutlined",
      title: "Channel Return plan",
      sort: 140,
      link: "",
      hidden: false,
      text: "menu.consignment_return_channel_list",
    },
    {
      id: 615,
      parent: 6,
      path: "/consignment/returnchannel/create",
      name: "consignment_return_channel_create",
      type: "router",
      icon: "ExportOutlined",
      title: "Create Channel Return plan",
      sort: 150,
      link: "",
      hidden: true,
      text: "menu.consignment_return_channel_create",
    },
    {
      id: 617,
      parent: 6,
      path: "/consignment/returnchannel/detail/:id",
      name: "consignment_return_channel_detail",
      type: "router",
      icon: "ExportOutlined",
      title: "Channel Return plan detail",
      sort: 170,
      link: "",
      hidden: true,
      text: "menu.consignment_return_channel_detail",
    },
    {
      id: 618,
      parent: 6,
      path: "/consignment/order/bulkimport",
      name: "consignment_order_bulk_import",
      type: "router",
      icon: "ExportOutlined",
      title: "consignment order bulk import",
      sort: 180,
      link: "",
      hidden: true,
      text: "menu.consignment_order_bulk_import",
    },
    {
      id: 619,
      parent: 6,
      path: "/consignment/returntemporarystorage",
      name: "consignment_return_temporary_storage",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "Return Temporary Storage",
      sort: 190,
      link: "",
      hidden: false,
      text: "menu.consignment_return_temporary_storage",
    },
    {
      id: 620,
      parent: 6,
      path: "/consignment/returntemporarystorageshop",
      name: "consignment_return_temporary_storage_shop",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "Return Temporary Storage Shop Management",
      sort: 200,
      link: "",
      hidden: false,
      text: "menu.consignment_return_temporary_storage_shop",
    },
    {
      id: 621,
      parent: 6,
      path: "/consignment/inventoryadjustment",
      name: "consignment_inventory_adjustment",
      type: "router",
      icon: "OneToOneOutlined",
      title: "Inventory Adjustment",
      sort: 210,
      link: "",
      hidden: false,
      text: "menu.consignment_inventory_adjustment",
    },
    {
      id: 7,
      parent: 0,
      path: "/transport",
      name: "transport_manage",
      type: "group",
      icon: "CarOutlined",
      title: "transport",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transport_manage",
    },
    {
      id: 751,
      parent: 7,
      path: "/transport/planlist",
      name: "transport_plan",
      type: "router",
      icon: "CodeOutlined",
      title: "Transport Plan",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transport_plan",
    },
    {
      id: 753,
      parent: 7,
      path: "/transport/createplan",
      name: "transport_create_plan",
      type: "router",
      icon: "FileOutlined",
      title: "Crate Plan",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_create_plan",
    },
    {
      id: 756,
      parent: 7,
      path: "/transport/setplaninfo/:id",
      name: "transport_set_plan_info",
      type: "router",
      icon: "FileOutlined",
      title: "Set Info",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_set_plan_info",
    },
    {
      id: 758,
      parent: 7,
      path: "/transport/confirmplan/:id",
      name: "transport_confirm_plan",
      type: "router",
      icon: "FileOutlined",
      title: "Confirm Plan",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_confirm_plan",
    },
    {
      id: 760,
      parent: 7,
      path: "/transport/plandetails/:id",
      name: "transport_plan_details",
      type: "router",
      icon: "FileOutlined",
      title: "Plan Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_plan_details",
    },
    {
      id: 761,
      parent: 7,
      path: "/transport/appointment/list",
      name: "transport_appointment_list",
      type: "router",
      icon: "HddOutlined",
      title: "Transport Appointment List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transport_appointment_list",
    },
    {
      id: 762,
      parent: 7,
      path: "/transport/appointment/create",
      name: "transport_appointment_create",
      type: "router",
      icon: "FileOutlined",
      title: "Transport Appointment Create",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_appointment_create",
    },
    {
      id: 763,
      parent: 7,
      path: "/transport/appointment/details/:id",
      name: "transport_appointment_details",
      type: "router",
      icon: "FileOutlined",
      title: "Transport Appointment Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transport_appointment_details",
    },
    {
      id: 764,
      parent: 7,
      path: "/transport/import",
      name: "transport_import",
      type: "router",
      icon: "FileOutlined",
      title: "Transport Import",
      sort: 64,
      link: "",
      hidden: true,
      text: "menu.transport_import",
    },
    {
      id: 8,
      parent: 0,
      path: "/account",
      name: "account",
      type: "group",
      icon: "UserOutlined",
      title: "Account",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.account",
    },
    {
      id: 800,
      parent: 8,
      path: "/account/info",
      name: "account_info",
      type: "router",
      icon: "",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.account_info",
    },
    {
      id: 801,
      parent: 8,
      path: "/account/restpwd",
      name: "account_restpwd",
      type: "router",
      icon: "",
      title: "",
      sort: 1,
      link: "",
      hidden: true,
      text: "menu.account_restpwd",
    },
    {
      id: 802,
      parent: 8,
      path: "/account/announcement",
      name: "account_announcement",
      type: "router",
      icon: "",
      title: "Announcement",
      sort: 2,
      link: "",
      hidden: true,
      text: "menu.account_announcement",
    },
    {
      id: 9,
      parent: 0,
      path: "/settings",
      name: "settings",
      type: "router",
      icon: "SettingOutlined",
      title: "Settings",
      sort: 0,
      link: "",
      hidden: true,
      text: "common.settings",
    },
    {
      id: 10,
      parent: 0,
      path: "/about",
      name: "about",
      type: "router",
      icon: "PlusOutlined",
      title: "About",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.about",
    },
    {
      id: 11,
      parent: 0,
      path: "",
      name: "sdas",
      type: "link",
      icon: "LinkOutlined",
      title: "SDAS",
      sort: 1,
      link: "https://www.saleasy.com",
      hidden: true,
      text: "menu.sdas",
    },
    {
      id: 13,
      parent: 0,
      path: "/finance",
      name: "finance",
      type: "group",
      icon: "TransactionOutlined",
      title: "finance",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance",
    },
    {
      id: 1301,
      parent: 13,
      path: "/finance/inventory",
      name: "finance_inventory",
      type: "router",
      icon: "ProfileOutlined",
      title: "Fee Inventory",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance_inventory",
    },
    {
      id: 1302,
      parent: 13,
      path: "/finance/inventory/details/:id",
      name: "finance_details",
      type: "router",
      icon: "TransactionOutlined",
      title: "Fee Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.finance_details",
    },
    {
      id: 1303,
      parent: 13,
      path: "/finance/inventoryunsettled",
      name: "finance_inventory_unsettled",
      type: "router",
      icon: "ExceptionOutlined",
      title: "Finance Inventory Unsettled",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance_inventory_unsettled",
    },
    {
      id: 1304,
      parent: 13,
      path: "/finance/accountbalance",
      name: "finance_account_balance",
      type: "router",
      icon: "WalletOutlined",
      title: "Account Balance",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance_account_balance",
    },
    {
      id: 1305,
      parent: 13,
      path: "/finance/rechargerecord",
      name: "finance_recharge_record",
      type: "router",
      icon: "FileSearchOutlined",
      title: "recharge record",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.recharge_record",
    },
    {
      id: 1306,
      parent: 13,
      path: "/finance/bill",
      name: "finance_bill",
      type: "router",
      icon: "AccountBookOutlined",
      title: "bill",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance_bill",
    },
    {
      id: 14,
      parent: 0,
      path: "/customer",
      name: "customer",
      type: "group",
      icon: "MessageOutlined",
      title: "Customer Service ",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.customer_service",
    },
    {
      id: 1401,
      parent: 14,
      path: "/customer/inboundsurvey",
      name: "inbound_survey",
      type: "router",
      icon: "ExceptionOutlined",
      title: "In Anomaly Investigation",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.inbound_survey",
    },
    {
      id: 15,
      parent: 0,
      path: "/printwaybill",
      name: "print_waybill",
      type: "group",
      icon: "InteractionOutlined",
      title: "Print waybill ",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.print_waybill",
    },
    {
      id: 1501,
      parent: 15,
      path: "/printwaybill/list",
      name: "print_waybill_list",
      type: "router",
      icon: "UnorderedListOutlined",
      title: "Print waybill list",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.print_waybill_list",
    },

    {
      id: 1502,
      parent: 15,
      path: "/printwaybill/create/:id?/:iscopy?",
      name: "print_waybill_create",
      type: "router",
      icon: "CodeOutlined",
      title: "Print waybill create",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.print_waybill_create",
    },
    {
      id: 1503,
      parent: 15,
      path: "/printwaybill/confirm/:id",
      name: "print_waybill_confirm",
      type: "router",
      icon: "ControlOutlined",
      title: "Print waybill confirm ",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.print_waybill_confirm",
    },
    {
      id: 1504,
      parent: 15,
      path: "/printwaybill/details/:id",
      name: "print_waybill_details",
      type: "router",
      icon: "ExceptionOutlined",
      title: "Print waybill details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.print_waybill_details",
    },
    {
      id: 1505,
      parent: 15,
      path: "/printwaybill/batchimport",
      name: "print_waybill_batch_import",
      type: "router",
      icon: "ExceptionOutlined",
      title: "Print waybill batch import",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.print_waybill_batch_import",
    },
    {
      id: 16,
      parent: 0,
      path: "/authorizationmanage",
      name: "authorization_manage",
      type: "group",
      icon: "ClusterOutlined",
      title: "authorization manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.authorization_manage",
    },
    {
      id: 1601,
      parent: 16,
      path: "/authorizationmanage/list",
      name: "authorization_manage_list",
      type: "router",
      icon: "UnorderedListOutlined",
      title: "authorization_manage_list",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.authorization_manage_list",
    },
    {
      id: 17,
      parent: 0,
      path: "/user",
      name: "user_management",
      type: "group",
      icon: "TeamOutlined",
      title: "user management",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.user_management",
    },
    {
      id: 1701,
      parent: 17,
      path: "/user/rolelist",
      name: "user_role_list",
      type: "router",
      icon: "ContactsOutlined",
      title: "user_role_list",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.user_role_list",
    },
    {
      id: 1702,
      parent: 17,
      path: "/user/account/list",
      name: "user_account_list",
      type: "router",
      icon: "CreditCardOutlined",
      title: "user_account_list",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.user_account_list",
    },
    {
      id: 1703,
      parent: 17,
      path: "/user/account/details/:id",
      name: "user_account_details",
      type: "router",
      icon: "MonitorOutlined",
      title: "User Account Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.user_account_details",
    },
    {
      id: 1704,
      parent: 17,
      path: "/user/address",
      name: "user_address",
      type: "router",
      icon: "RocketOutlined",
      title: "User Address",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.user_address",
    },
    {
      id: 18,
      parent: 0,
      path: "/transit",
      name: "transit",
      type: "group",
      icon: "CodeOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit",
    },
    {
      id: 1807,
      parent: 18,
      path: "/transit/inplan/list",
      name: "transit_in_plan_list",
      type: "router",
      icon: "DeleteRowOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit_in_plan_list",
    },
    {
      id: 1802,
      parent: 18,
      path: "/transit/inplan/create",
      name: "transit_in_plan_create",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_in_plan_create",
    },
    {
      id: 1804,
      parent: 18,
      path: "/transit/inplan/addshipment/:id",
      name: "transit_in_plan_add_shipment",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_in_plan_add_shipment",
    },
    {
      id: 1805,
      parent: 18,
      path: "/transit/inplan/edit/:id",
      name: "transit_in_plan_edit",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_in_plan_edit",
    },
    {
      id: 1806,
      parent: 18,
      path: "/transit/inplan/detail/:id",
      name: "transit_in_plan_detail",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_in_plan_detail",
    },
    {
      id: 1808,
      parent: 18,
      path: "/transit/outplan/list",
      name: "transit_out_plan_list",
      type: "router",
      icon: "DeleteColumnOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit_out_plan_list",
    },
    {
      id: 1809,
      parent: 18,
      path: "/transit/outplan/create",
      name: "transit_out_plan_create",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_out_plan_create",
    },
    {
      id: 1804,
      parent: 18,
      path: "/transit/outplan/addshipment",
      name: "transit_out_plan_add_shipment",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_out_plan_add_shipment",
    },
    {
      id: 1805,
      parent: 18,
      path: "/transit/outplan/confirm",
      name: "transit_out_plan_confirm",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_out_plan_confirm",
    },
    {
      id: 1805,
      parent: 18,
      path: "/transit/outplan/detail/:id",
      name: "transit_out_plan_detail",
      type: "router",
      icon: "ContactsOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.transit_out_plan_detail",
    },
    {
      id: 1823,
      parent: 18,
      path: "/transit/inventory",
      name: "transit_inventory",
      type: "router",
      icon: "CloudServerOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit_inventory",
    },
    {
      id: 1825,
      parent: 18,
      path: "/transit/shipment",
      name: "transit_shipment",
      type: "router",
      icon: "WalletOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit_shipment",
    },
    {
      id: 1826,
      parent: 18,
      path: "/transit/shipmentlist",
      name: "transit_shipment_list",
      type: "router",
      icon: "FileTextOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transit_shipment_list",
    },
    {
      id: 1850,
      parent: 18,
      path: "/transit/labelchange",
      name: "transit_label_change_list",
      type: "router",
      icon: "InteractionOutlined",
      title: "",
      sort: 50,
      link: "",
      hidden: false,
      text: "menu.transit_label_change_list",
    },
    {
      id: 1851,
      parent: 18,
      path: "/transit/labelchange/:id",
      name: "transit_label_change_detail",
      type: "router",
      icon: "InteractionOutlined",
      title: "",
      sort: 51,
      link: "",
      hidden: true,
      text: "menu.transit_label_change_detail",
    },
    {
      id: 1852,
      parent: 18,
      path: "/transit/labelchange/create",
      name: "transit_label_change_create",
      type: "router",
      icon: "InteractionOutlined",
      title: "",
      sort: 52,
      link: "",
      hidden: true,
      text: "menu.transit_label_change_create",
    },
    {
      id: 1853,
      parent: 18,
      path: "/transit/labelchange/uploadtags/:id",
      name: "transit_label_change_uploadtags",
      type: "router",
      icon: "InteractionOutlined",
      title: "",
      sort: 53,
      link: "",
      hidden: true,
      text: "menu.transit_label_change_create",
    },
    {
      id: 1854,
      parent: 18,
      path: "/transit/labelchange/confirm/:id",
      name: "transit_label_change_confirm",
      type: "router",
      icon: "InteractionOutlined",
      title: "",
      sort: 54,
      link: "",
      hidden: true,
      text: "menu.transit_label_change_create",
    },
    {
      id: 19,
      parent: 0,
      path: "/setting",
      name: "setting",
      type: "group",
      icon: "SettingOutlined",
      title: "",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.setting",
    },
    {
      id: 1901,
      parent: 19,
      path: "/account/info",
      name: "setting_user",
      type: "router",
      icon: "UserOutlined",
      title: "",
      sort: 10,
      link: "",
      hidden: false,
      text: "menu.account_info",
    },
    {
      id: 1902,
      parent: 19,
      path: "/setting/notice",
      name: "setting_notice",
      type: "router",
      icon: "NotificationOutlined",
      title: "",
      sort: 20,
      link: "",
      hidden: false,
      text: "menu.setting_notice",
    },
  ];
};
