<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.confirm_out_warehouse_plan") }}
    </template>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="2" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.set_out_plan')" />
            <a-step :title="$t('warehouse.add_shipment')" />
            <a-step class="mr-3" :title="$t('warehouse.confirm_out_plan')" />
          </a-steps>
        </a-col>
      </a-row>

      <a-row class="mt-3" :gutter="[0, 12]">
        <a-col :span="24"
          >{{ $t("warehouse.out_plan_name") }}: {{ data.planName }}</a-col
        >
        <a-col :span="8"
          >{{ $t("warehouse.out_warehouse") }}:
          {{ data.inventoryWarehouseAddress?.warehouseNo }}</a-col
        >
        <a-col :span="16">
          {{ $t("warehouse.warehouse_address") }}:
          {{ getAddress(data.inventoryWarehouseAddress) }}
          <span class="ml-2" v-if="data.inventoryWarehouseAddress">
            {{ $t("logistics.contacts") }}:
            {{ getLinkman(data.inventoryWarehouseAddress) }}
          </span>
        </a-col>
        <a-col :span="8"
          >{{ $t("warehouse.out_warehouse_type") }}:
          {{ $t($enumLangkey("destinationType", data.destinationType)) }}
        </a-col>
        <a-col
          :span="8"
          v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
          >{{ $t("warehouse.shipment_not_set") }}:
          {{ $t($enumLangkey("sendoutType", data.sendoutType)) }}
        </a-col>
        <!-- <a-col
          :span="8"
          v-if="
            data.destinationType != destinationTypeEnum.OtherAddress &&
            data.destinationType != destinationTypeEnum.SeWarehouse
          "
          >{{ $t("warehouse.recipients") }}:
          {{ data.address?.linkMan }}
        </a-col>
        <a-col
          :span="8"
          v-if="
            data.destinationType != destinationTypeEnum.OtherAddress &&
            data.destinationType != destinationTypeEnum.SeWarehouse
          "
          >{{ $t("warehouse.phone") }}: +{{ data.address?.phoneCountryCode }}
          {{ data.address?.linkManPhone }}
        </a-col> -->
        <a-col :span="8" v-if="data.destinationType == destinationTypeEnum.FBA"
          >{{ $t("warehouse.fba_warehouse_code") }}:
          {{ data.targetAddress?.name }}</a-col
        >
        <a-col
          :span="8"
          v-if="data.destinationType == destinationTypeEnum.mercadolibre"
          >{{ $t("warehouse.mercado_libre_warehouse_code") }}:
          {{ data.targetAddress?.name }}</a-col
        >

        <a-col
          :span="16"
          v-if="
            data.destinationType == destinationTypeEnum.FBA ||
            data.destinationType == destinationTypeEnum.mercadolibre
          "
        >
          {{ $t("logistics.destination_address") }}:
          {{ getAddress(data.targetAddress) }}
        </a-col>

        <a-col
          :span="16"
          v-if="data.destinationType == destinationTypeEnum.OtherAddress"
        >
          {{ $t("logistics.destination_address") }}:
          {{ getAddress(data.address) }}
          <span class="ml-2" v-if="data.address">
            {{ $t("logistics.contacts") }}:
            {{ getLinkman(data.address) }}
          </span>
        </a-col>
      </a-row>
      <a-row
        v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
        :gutter="[0, 12]"
        class="mt-2"
      >
        <a-col
          :span="8"
          v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
          >{{ $t("warehouse.se_warehouse_code") }}:
          {{ data.targetAddress?.warehouseNo }}</a-col
        >
        <a-col :span="16">
          {{ $t("logistics.destination_address") }}:
          {{ getAddress(data.targetAddress) }}
          <span class="ml-2">
            {{ $t("logistics.contacts") }}:
            {{ getLinkman(data.targetAddress) }}
          </span>
        </a-col>
      </a-row>

      <a-table
        class="mt-2"
        :columns="columns"
        :data-source="data.list"
        :pagination="false"
        size="small"
        @expand="handleGetRowDetail"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
         <template #proxy_tenant_info="{ record }">
          <div v-if="record.representedShop">
            {{record.representedShop.shopName}}
            <div>{{record.representedShop.shopNo}}</div>
          </div>
          <span v-else>-</span>
        </template>
        <template #box_amount="{ record }">
          {{ record.boxCount?.actual }}
        </template>

        <template #shipment_volume="{ record }">
          {{ cmCubicToM(record.volume?.actual) }}
          <span v-if="record.volume?.actual">m<sup>3</sup></span>
        </template>

        <template #shipment_weight="{ record }">
          {{ gToKg(record.weight?.actual) }}
          <span v-if="record.weight?.actual">kg</span>
        </template>

        <template #uploadTitle>
          <span
            style="color: red"
            v-if="
              data.destinationType == destinationTypeEnum.FBA ||
              data.destinationType == destinationTypeEnum.mercadolibre
            "
            >*</span
          >
          {{ $t("warehouse.reservation_file") }}
        </template>

        <template #upload="{ record }">
          <div
            style="position: relative; height: 100%"
            :class="
              data.destinationType != destinationTypeEnum.FBA &&
              data.destinationType != destinationTypeEnum.mercadolibre &&
              record.scheduleFileUrl
                ? 'mb-3'
                : ''
            "
          >
            <UploadLetter
              accept=" .jpeg,.jpg,.png,.pdf"
              v-model:showFileUrl="record.scheduleFileUrl"
            ></UploadLetter>
            <a-tooltip
              placement="right"
              v-if="
                data.destinationType != destinationTypeEnum.FBA &&
                data.destinationType != destinationTypeEnum.mercadolibre &&
                record.scheduleFileUrl
              "
            >
              <template #title>{{ $t("common.delete") }}</template>
              <span class="delete-label" @click="record.scheduleFileUrl = null">
                <DeleteOutlined />
              </span>
            </a-tooltip>
          </div>
        </template>

        <template #footer>
          <a-row :gutter="[12, 12]">
            <a-col
              >{{ $t("common.total") }}:
              {{
                $t("warehouse.p0_outbound_shipments", [data.total.shipment])
              }}</a-col
            >
            <a-col>{{ data.total.box ?? "-" }}{{ $t("warehouse.box") }}</a-col>
            <a-col v-if="data.total.volume"
              >{{ cmCubicToM(data.total.volume) }}
              <span>m<sup>3</sup></span>
            </a-col>
            <a-col v-if="data.total.weight">
              {{ gToKg(data.total.weight) }}
              <span>kg</span>
            </a-col>
          </a-row>
        </template>
        <template #expandedRowRender="{ record }">
          <a-table
            :columns="innerColumns"
            :loading="record.loading"
            size="small"
            :data-source="record?.innerData"
            :pagination="false"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #out_data="{ record }">
              {{ record.checkBoxSize?.length }}x
              {{ record.checkBoxSize?.width }}x
              {{ record.checkBoxSize?.height }}cm |
              {{ cmCubicToM(record.checkBoxSize?.volume) }} m<sup>3</sup>
              <div>{{ gToKg(record.checkBoxSize?.weight) }}kg</div>
            </template>
          </a-table>
        </template>
      </a-table>

      <!-- 打托服务 -->
      <div class="mt-2">
        <span style="color: red">*</span>
        {{ $t("warehouse.hit_torr_serve") }}:
        <a-select
          style="min-width: 700px"
          v-model:value="data.daycareServiceId"
          @change="handleChangeTransitDeliveryMethod"
          :loading="dropdownData.hitTorrServeListLoading"
        >
          <a-select-option value="0">
            {{ $t("warehouse.not_required") }}
          </a-select-option>
          <a-select-option
            v-for="(value, key) in dropdownData.hitTorrServeList"
            :key="key"
            :value="value?.id"
            :title="
              value.packMaterialName +
              value.materialTypeName +
              value.packMaterialLength +
              'x' +
              value.packMaterialWeight +
              'x' +
              value.packMaterialHeight +
              ' cm ' +
              $t('warehouse.tray_volume_restriction') +
              ': ' +
              cmCubicToM(value.packMaterialVolume) +
              ' m3 ' +
              $t('warehouse.tray_weight_limit') +
              ': ' +
              gToKg(value.packBearingQuantity) +
              'kg '
            "
          >
            {{ value.packMaterialName }} - {{ value.materialTypeName }}
            -
            {{ value.packMaterialLength }}x{{ value.packMaterialWeight }}x{{
              value.packMaterialHeight
            }}cm {{ $t("warehouse.tray_volume_restriction") }}:
            {{ cmCubicToM(value.packMaterialVolume) }}m<sup>3</sup>
            {{ $t("warehouse.tray_weight_limit") }}:
            {{ gToKg(value.packBearingQuantity) }}kg
          </a-select-option>
        </a-select>
      </div>

      <div class="mt-2">
        <!-- 运输方式 -->
        <span style="color: red">*</span>
        {{ $t("warehouse.type_of_shipping") }}:
        <a-select
          style="min-width: 200px"
          v-model:value="data.transitDeliveryMethod"
          @change="handleChangeTransitDeliveryMethod"
        >
          <a-select-option
            v-for="(value, key) in transitDeliveryMethodEnum"
            :key="key"
            :value="value"
          >
            {{ $t($enumLangkey("transitDeliveryMethod", value)) }}
          </a-select-option>
        </a-select>

        <!-- 物流 -->
        <div
          v-if="
            data.transitDeliveryMethod ==
              transitDeliveryMethodEnum.seLogistics && logistics.isSearch
          "
          class="mt-2"
        >
          <a-button
            class="mb-1"
            v-if="logistics.selectedLogisticsSchemeId"
            @click="handleGetLogisticsScheme"
            >{{ $t("warehouse.reacquire_logistics_solutions") }}</a-button
          >

          <a-tabs
            v-if="
              !logistics.selectedLogisticsSchemeId &&
              logistics.isShowCalculationMethod
            "
            v-model:activeKey="logistics.calculationMethod"
            @change="handleChangeLogisticsScheme"
          >
            <a-tab-pane
              :key="feeCalculationMethodEnum.template"
              :disabled="logistics.loading"
              :tab="$t('logistics.logistics')"
            ></a-tab-pane>
            <a-tab-pane
              :key="feeCalculationMethodEnum.truckApi"
              :disabled="logistics.loading"
              :tab="$t('logistics.truck')"
            ></a-tab-pane>
          </a-tabs>
          <a-table
            :columns="logistics.columnsLogisticsScheme"
            :data-source="logistics.logisticsSchemeExhibitionList"
            :pagination="false"
            size="small"
            :scroll="{ x: 1200, y: 1000 }"
            :loading="logistics.loading"
            :locale="{
              emptyText: $t(
                'logistics.not_currently_available_logistics_scheme'
              ),
            }"
          >
            <template #logisticsScheme>
              <span>
                {{ $t("warehouse.logistics_scheme") }} /
                {{ $t("warehouse.logistics_code") }}
              </span>
            </template>

            <template #logisticsWay>
              <span>
                {{ $t("warehouse.logistics_type") }}
              </span>
            </template>

            <template #deliveryWay>
              <span>
                {{ $t("warehouse.delivery_way") }}
              </span>
            </template>

            <template #referenceAging>
              <span>
                {{ $t("warehouse.reference_aging") }}
              </span>
            </template>
            <template #freights>
              <span>
                {{ $t("warehouse.freight_estimate") }}
              </span>
            </template>

            <template #details>
              <span>
                {{ $t("common.details") }}
              </span>
            </template>
            <template
              #optionCustom="{ record }"
              v-if="!logistics.selectedLogisticsSchemeId"
            >
              <a-button @click="handleSelectedLogistics(record)">{{
                $t("common.select")
              }}</a-button>
            </template>
            <template #logisticsSchemeCustom="{ record }">
              <div class="text-warning">
                {{ record.scheme }} / {{ record.code }}
              </div>
              <a-row :gutter="[8, 8]">
                <a-col v-for="item in record.descriptionItems" :key="item">
                  <CheckCircleOutlined
                    class="text-warning mr-1"
                    :style="{ fontSize: '12px' }"
                  />
                  <span style="font-size: 12px; color: rgba(102, 102, 102, 1)">
                    {{ item }}
                  </span>
                </a-col>
              </a-row>
            </template>
            <template #logisticsWayCustom="{ record }">
              {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
            </template>
            <template #deliveryWayCustom="{ record }">
              {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
            </template>
            <template #referenceAgingCustom="{ record }">
              <div>
                {{ record.daysMin }}-{{ record.daysMax }}
                {{ $t("warehouse.workday") }}
                ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
              </div>
            </template>
            <template #freightsCustom="{ record }">
              <div class="text-warning">
                {{ $t("warehouse.average_unit_price") }}:
                {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.averagePrice) }}
              </div>
              <div class="text-warning">
                {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.expressFee) }}
              </div>
              <div
                style="font-size: 10px; color: #808080"
                v-if="!logistics?.selectedLogisticsSchemeId"
              >
                {{ $t("warehouse.tooltip_text_6") }}
              </div>
            </template>
            <template #detailsCustom="{ record }">
              <div v-if="record.logisticsCalcMethod == 0">
                {{ $t("logistics.chargeable_weight") }}:
                {{ record.chargeableWeight }}kg
              </div>
              <div v-else>
                {{ $t("logistics.chargeable_volume") }}:
                {{ record.chargeableVolume }}m<sup>3</sup>
              </div>
              <div>
                {{ $t("logistics.volume_weight_coefficient")
                }}{{ record.volumeWeightParam }}
              </div>
            </template>
            <template #optionalServicesCustom="{ record }">
              <template v-if="logistics.selectedLogisticsSchemeId">
                <div v-if="record.logisticsRegionInfo.isDdpSupported">
                  <a-checkbox v-model:checked="logistics.isSelectedDDP">
                    DDP({{ record.currencySymbol }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }})
                  </a-checkbox>
                </div>
                <CSignSupported
                  v-if="record.logisticsRegionInfo.isSignSupported"
                  v-model:value="logistics.signatureType"
                  type="editable"
                  :symbol="record.currencySymbol"
                  :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                />
              </template>
              <template v-else>
                <div v-if="record.logisticsRegionInfo.isDdpSupported">
                  DDP({{ record.currencySymbol }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }})
                </div>
                <CSignSupported
                  v-if="record.logisticsRegionInfo.isSignSupported"
                  type="preview"
                  :symbol="record.currencySymbol"
                  :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                />
              </template>
            </template>
            <!-- 卡车专属 -->
            <template #quoteOptionsTitle="{ record }">
              {{ $t("logistics.quote_options") }}
              <CTooltip :title="$t('logistics.quote_options_tips')" />
            </template>
            <template #quoteOptions="{ record }">
              <a-row :gutter="[16, 8]">
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span class="text-error">*</span
                      >{{ $t("logistics.quote_type") }}</a-col
                    >
                    <a-col flex="auto">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.quoteTypeId"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(item, index) in logistics.quoteTypeList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <span v-else>{{
                        record.truckBaseInfo?.quoteType?.name
                      }}</span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col
                  :span="24"
                  v-if="
                    (logistics.selectedLogisticsSchemeId &&
                      record?.truckBaseInfo?.serviceLevel?.name) ||
                    handleShowServiceLevelSelect(record)
                  "
                >
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span class="text-error">*</span
                      >{{ $t("logistics.service_level") }}</a-col
                    >
                    <a-col flex="1">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.serviceLevel"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(item, index) in logistics.serviceLevelList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <template v-else>
                        <span>{{
                          record.truckBaseInfo.serviceLevel.name
                        }}</span>
                      </template>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"
                      ><span>&nbsp;&nbsp;</span
                      >{{ $t("logistics.additional_options") }}</a-col
                    >
                    <a-col flex="1">
                      <a-select
                        v-if="!logistics.selectedLogisticsSchemeId"
                        v-model:value="record.accessorials"
                        mode="multiple"
                        size="small"
                        style="width: 150px"
                        :disabled="record.loading"
                        @change="record.quoteDetail = null"
                      >
                        <a-select-option
                          v-for="(
                            item, index
                          ) in logistics.additionalOptionsList"
                          :key="index"
                          :value="item.id"
                          :title="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                      <span v-else>
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px">
                              {{
                                getAccessorialsName(
                                  record.truckBaseInfo?.accessorials
                                )
                              }}
                            </div>
                          </template>
                          <div
                            style="
                              max-width: 250px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            {{
                              getAccessorialsName(
                                record.truckBaseInfo?.accessorials
                              )
                            }}
                          </div>
                        </a-popover>
                      </span>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row type="flex">
                    <a-col flex="100px"></a-col>
                    <a-col flex="1">
                      <a-button
                        v-if="!logistics.selectedLogisticsSchemeId"
                        size="small"
                        type="ghost"
                        :loading="record.loading"
                        @click="handleSearchTruckFee(record)"
                        >{{ $t("logistics.inquiry_immediately") }}</a-button
                      >
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </template>
            <template #quoteDetails="{ record }">
              <template v-if="!logistics.selectedLogisticsSchemeId">
                <a-space>
                  <span>{{ $t("logistics.quote") }}:</span>
                  <span v-if="record.quoteDetail"
                    >{{ record.quoteDetail.carrierName }} [{{
                      $filters.formatCurrencyAmount(
                        record.quoteDetail.currencySymbol,
                        record.quoteDetail.totalCharge,
                        false
                      )
                    }}, {{ record.quoteDetail.transitTime }} day(s)]</span
                  >
                  <span v-else>-</span>
                </a-space>
                <div class="mt-3" v-if="record.quoteDetail">
                  <a-button
                    type="ghost"
                    :disabled="record.loading"
                    @click="handleSelectedLogistics(record)"
                    >{{ $t("common.confirm") }}</a-button
                  >
                </div>
              </template>
              <template v-else>
                <span v-if="record.truckBaseInfo"
                  >{{ record.truckBaseInfo.carrierName }} [{{
                    $filters.formatCurrencyAmount(
                      record.truckBaseInfo.currencySymbol,
                      record.truckBaseInfo.totalCharge,
                      false
                    )
                  }}, {{ record.truckBaseInfo.transitTime }} day(s)]</span
                >
                <span v-else>-</span>
              </template>
            </template>
          </a-table>

          <div class="mt-2">
            <a-tag color="warning" v-if="logistics.isRemoteArea">{{
              $t("logistics.remote_site")
            }}</a-tag>
            <template v-if="logistics.isCommercial != null">
              <a-tag color="warning" v-if="logistics.isCommercial">{{
                $t("logistics.business_site")
              }}</a-tag>
              <a-tag color="warning" v-else>{{
                $t("logistics.residence")
              }}</a-tag>
            </template>
          </div>
          <QuotedPriceModal
            ref="quotedPriceModalRef"
            @confirm="handleConfirmQuotedPrice"
          />

          <div v-show="logistics.isShowVATInput">
            <span style="color: red"> * </span>
            <label>VAT: </label>
            <a-input style="max-width: 200px" v-model:value="logistics.VAT" />
          </div>
        </div>

        <!-- 选择时间类型 -->
        <div
          class="mt-2"
          v-if="
            data.transitDeliveryMethod ==
              transitDeliveryMethodEnum.seLogistics &&
            logistics.selectedLogisticsSchemeId
          "
        >
          <span style="color: red">*</span>
          {{ $t("warehouse.time_type") }}:
          <a-select
            style="min-width: 200px"
            v-model:value="data.scheduleDeliveryType"
          >
            <a-select-option :value="scheduleDeliveryTypeEnum.anytime"
              v-if="logistics.logisticsType != logisticsWayEnum.truckTransportation">
              {{
                $t(
                  $enumLangkey(
                    "scheduleDeliveryType",
                    scheduleDeliveryTypeEnum.anytime
                  )
                )
              }}
            </a-select-option>
            <a-select-option :value="scheduleDeliveryTypeEnum.date"
              v-if="logistics.logisticsType != logisticsWayEnum.truckTransportation">
              {{
                $t(
                  $enumLangkey(
                    "scheduleDeliveryType",
                    scheduleDeliveryTypeEnum.date
                  )
                )
              }}
            </a-select-option>
            <a-select-option
              :value="scheduleDeliveryTypeEnum.dateAndTime"
              v-if="logistics.logisticsType == logisticsWayEnum.truckTransportation"
            >
              {{
                $t(
                  $enumLangkey(
                    "scheduleDeliveryType",
                    scheduleDeliveryTypeEnum.dateAndTime
                  )
                )
              }}
            </a-select-option>
          </a-select>
        </div>

        <!-- 选择时间-------------------------------------------------------------- -->
        <div
          class="mt-2"
          v-if="
            data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir ||
            (data.transitDeliveryMethod ==
              transitDeliveryMethodEnum.seLogistics &&
              data.scheduleDeliveryType &&
              data.scheduleDeliveryType != scheduleDeliveryTypeEnum.anytime)
          "
        >
          <span style="color: red"> * </span>
          <span
            v-if="
              data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir
            "
          >
            {{ $t("warehouse.pick_up_time") }}:
          </span>
          <span
            v-if="
              data.transitDeliveryMethod ==
                transitDeliveryMethodEnum.seLogistics &&
              logistics.calculationMethod == feeCalculationMethodEnum.template
            "
          >
            {{ $t("warehouse.shipping_time") }}:
          </span>
          <span
            v-if="
              data.transitDeliveryMethod ==
                transitDeliveryMethodEnum.seLogistics &&
              logistics.calculationMethod == feeCalculationMethodEnum.truckApi
            "
          >
            {{ $t("warehouse.delivery_time") }}:
          </span>
          <a-date-picker
            style="min-width: 200px"
            :disabled-date="disabledDate"
            :disabled-time="disabledDateTime"
            v-model:value="data.dateMoment"
            :format="getDateFormat()"
            :show-time="showTimePart ? {
                    format: 'HH:00',
                    defaultValue: moment(
                      new Date().getHours() + 1 + ':00:00',
                      'HH:00'
                    ),
                  }
                : false
            "
          />
          <a-tag color="warning" class="mt-1 ml-1">{{
            $t("warehouse.local_time")
          }}</a-tag>
        </div>
      </div>
      
      <!-- 费用明细 与下一步-->
      <a-spin :spinning="data.loading">
        <CFee
          :fees="data.cost?.warehouseFrees"
          :totalFee="data.cost?.totalFee"
          v-if="data.cost"
        ></CFee>

        <a-row type="flex" justify="end" class="mt-2 mb-5">
          <a-col>
            <div style="width: 500px">
              <a-row type="flex" justify="center" :gutter="[24, 12]">
                <a-col>
                  <a-popconfirm
                    :title="$t('common.are_you_sure')"
                    @confirm="handleCancelPlan"
                  >
                    <a-button>{{ $t("warehouse.cancel_plan") }}</a-button>
                  </a-popconfirm>
                </a-col>
                <a-col>
                  <a-button @click="handleLastStep">{{
                    $t("common.back_step")
                  }}</a-button>
                </a-col>
                <a-col>
                  <a-button type="primary" @click="handleValidator">{{
                    $t("warehouse.finalize_the_plan_and_pay_for_it")
                  }}</a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </a-spin>
      <!-- 费用支付 -->
      <Payment
        ref="refPayment"
        :currencyId="data.cost?.totalFee?.currencyId ?? ''"
        :unpaidSymbol="data.cost?.totalFee?.symbol ?? ''"
        :unpaidSum="data.cost?.totalFee?.value"
        @handlePayFee="handleOrderPay"
        :loading="data.payModal.loading"
        v-model:visible="data.payModal.visible"
      ></Payment>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import {
  defineComponent,
  reactive,
  ref,
  watchEffect,
  watch,
  computed,
  onMounted
} from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  Steps,
  message,
  Popconfirm,
  Modal,
  Checkbox,
  TimePicker,
  Typography,
  Popover,
  Tabs,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CFee from "@/views/components/CFee.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import Payment from "@/views/components/Payment.vue";
import QuotedPriceModal from "./components/QuotedPriceModal.vue";
import UploadLetter from "./components/Upload.vue";
import moment from "moment-timezone";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import { transitOutPlanKeys } from "@/consts/index";
import { useSupplierHelper } from "@/utils/supplierHelper.js";
import {
  getLinkman,
  getAddressByLanguageV2,
  cmCubicToM,
  gToKg,
  kgToG,
  currentTimeToUtc,
} from "@/utils/general";
import {
  getCreateShipmentDetailList,
  getHitTorrServeList,
  getCost,
  create,
  getLogisticsScheme,
  getLogisticsAddressInfo,
} from "@/api/modules/transit/outPlan/plan";
import {
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "@/utils/store";
import {
  destinationType as destinationTypeEnum,
  deliveryWay as deliveryWayEnum,
  transitDeliveryMethod as transitDeliveryMethodEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  logisticsWay as logisticsWayEnum,
} from "@/enum/enum.json";
import filters from "@/filters";
export default defineComponent({
  name: "transit_out_plan_confirm",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATooltip: Tooltip,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ADatePicker: DatePicker,
    ATimePicker: TimePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    APopconfirm: Popconfirm,
    AModal: Modal,
    ACheckbox: Checkbox,
    ATypographyText: Typography.Text,
    APopover: Popover,
    Content,
    Payment,
    CPager,
    CTooltip,
    CFee,
    CSignSupported,
    UploadLetter,
    QuotedPriceModal,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const refForm = ref();
    const { delVisitedRoute, visitedRoutes } = useTab();

    const getDefaultMoment = () => {
      let date = new Date();
      date.setDate(date.getDate() + 2);
      date.setMinutes(0);
      date.setSeconds(0);
      return moment(date);
    };
    const data = reactive({
      loading: false,
      planName: null,
      warehouseId: null, //库存仓库id
      inventoryWarehouseAddress: null, //库存仓库地址对象
      destinationType: null, //出库类型
      toWarehouseId: null, //目标仓库代码
      targetAddress: null,
      address: null,
      sendoutType: null, //为谁发货

      list: [],
      total: {
        shipment: 0,
        box: 0,
        volume: 0,
        weight: 0,
      },
      daycareServiceId: null, //打托服务
      transitDeliveryMethod: null, //物流方式
      scheduleDeliveryType: null, //时间类型
      dateMoment: getDefaultMoment(),
      cost: null,
      payModal: {
        visible: false,
        loading: false,
      },
      wareZoneAppointmentLimitHours: 48,

      //这是自己定义的字段, 有接口了,需要更改
      logisticsSchemeId: null,
    });

    const dropdownData = reactive({
      hitTorrServeList: [],
      hitTorrServeListLoading: false,
    });

    watchEffect(() => {
      data.total.shipment = data.list?.length;
      data.total.box = 0;
      data.total.volume = 0;
      data.total.weight = 0;
      data.list.forEach((x) => {
        data.total.box = data.total.box + x.boxCount?.actual * 1;
        data.total.volume = data.total.volume + x.volume?.actual * 1;
        data.total.weight = data.total.weight + x.weight?.actual * 1;
      });
    });

    const getAddress = (address) => {
      if (!address) {
        return;
      }
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleGetRowDetail = (expanded, record) => {
      if (
        expanded &&
        record?.id &&
        (!record?.innerData || record?.innerData?.length == 0)
      ) {
        record.loading = true;
        getCreateShipmentDetailList({ shipmentId: record.id })
          .then(({ result }) => {
            if (Array.isArray(result)) {
              record.innerData = result;
            } else {
              record.innerData = [];
            }
            record.loading = false;
          })
          .catch(() => {
            record.loading = false;
          });
      }
    };

    const columnsBase = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_name"),
        dataIndex: "name",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        dataIndex: "seShipmentNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_volume"),
        slots: {
          customRender: "shipment_volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_weight"),
        slots: {
          customRender: "shipment_weight",
        },
        width: 100,
      },
      {
        className: "column-reservation-file",
        slots: {
          title: "uploadTitle",
          customRender: "upload",
        },
        width: 100,
      },
    ];

    const innerColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.identifiable_box_number"),
        dataIndex: "canScanedBoxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_id"),
        dataIndex: "boxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.out_data"),
        slots: {
          customRender: "out_data",
        },
        width: 100,
      },
    ];

    //-----------------------费用 start-----------------------------------------

    const handleValidator = () => {
      if (
        data.destinationType == destinationTypeEnum.FBA ||
        data.destinationType == destinationTypeEnum.mercadolibre
      ) {
        let isNotBeenUploaded = data.list.some((x, index) => {
          if (!x.scheduleFileUrl) {
            message.error(
              vueI18n.t(
                "common.reservation_file_whose_serial_number_is_p0_is_not_uploaded",
                [index + 1]
              )
            );
            return true;
          }
        });
        if (isNotBeenUploaded) {
          return;
        }
      }

      //验证打托
      if (!data.daycareServiceId) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.hit_torr_serve"),
          ])
        );
        return;
      }

      if (!data.transitDeliveryMethod) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.type_of_shipping"),
          ])
        );
        return;
      }

      //下方统一验证
      // if (
      //   data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir &&
      //   !data.dateMoment
      // ) {
      //   message.error(
      //     vueI18n.t("common.p0_is_required", [
      //       vueI18n.t("warehouse.date_of_reservation"),
      //     ])
      //   );
      //   return;
      // }

      //物流
      if (data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics) {
        //物流方案
        if (!logistics.selectedLogisticsSchemeId) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.logistics_scheme"),
            ])
          );
          return;
        }

        if (logistics.isShowVATInput && !logistics.VAT) {
          message.error(vueI18n.t("common.p0_is_required", ["VAT"]));
          return;
        }

        if (!data.scheduleDeliveryType) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.time_type"),
            ])
          );
          return;
        }

        //下方统一验证
        // if (
        //   data.scheduleDeliveryType != scheduleDeliveryTypeEnum.anytime &&
        //   !data.dateMoment
        // ) {
        //   message.error(
        //     vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.time")])
        //   );
        //   return;
        // }
      }

      //验证时间是否正确
      if (
        data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir ||
        data.scheduleDeliveryType != scheduleDeliveryTypeEnum.anytime
      ) {
        if (!data.dateMoment) {
          message.error(
            vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.time")])
          );
          return;
        } else {
          let isDisabledDate = disabledDate(data.dateMoment);
          if (isDisabledDate) {
            data.dateMoment = null;
            message.error(
              vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.time")])
            );
            return;
          }
        }
      }

      handleShowPayModal();
    };

    const refPayment = ref(null);
    const handleShowPayModal = () => {
      data.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    const _funcCombinedParameters = () => {
      data.outBoundTransitShipments = data.list?.map((x) => {
        return {
          id: x.id,
          scheduleFileUrl: x.scheduleFileUrl,
        };
      });

      data.toAddress = data.address;
      data.payFee = filters.amountToFixed2(data.cost?.totalFee?.value);
      data.scheduleDeliveryTime = currentTimeToUtc(
        // data.dateMoment?.format("YYYY-MM-DD HH:mm:ss"),
        data.dateMoment?.format(getDateFormat()),
        data.inventoryWarehouseAddress.timezone
      );
    };

    const _funcCombinedParametersTruck = () => {
      let logisticesFeeDetailDto = null;

      logisticesFeeDetailDto = {
        logisticsId: logistics?.selectedLogisticsSchemeId,
        isChooseDdp: logistics.isSelectedDDP,
        signatureType: logistics.signatureType,
        isChooseSign: logistics.signatureType > 0 ? true : false,
        deliveryType: logistics.selectedLogisticsDeliveryType,
        vatCode: logistics.VAT,

        warehouseId: data.warehouseId,
        destinationType: data.destinationType,
        toWarehouseId: data.toWarehouseId,
        toAddress: data.address,
        outBoundTransitShipments: data.outBoundTransitShipments,
        daycareServiceId: data.daycareServiceId,
      };

      if (logistics.calculationMethod === feeCalculationMethodEnum.truckApi) {
        logisticesFeeDetailDto.truckApiLogistics = {
          quoteDetailId: logistics.record?.quoteDetail.quoteDetailId,
          quoteTypeId: logistics.record?.quoteTypeId,
          serviceLevel: logistics.record?.serviceLevel,
          accessorials: logistics.record?.accessorials ?? [],
        };
      }

      return logisticesFeeDetailDto;
    };

    const handleOrderPay = async () => {
      _funcCombinedParameters();

      if (data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics) {
        data.logisticesFeeDetailDto = _funcCombinedParametersTruck();
      }

      data.payModal.loading = true;
      create({ ...data })
        .then((res) => {
          data.payModal.loading = false;
          message.success(vueI18n.t("common.succeed"));
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "transit_out_plan_list",
          });
          data.payModal.visible = false;
        })
        .catch(() => {
          data.payModal.loading = false;
        });
    };

    const handleChangeTransitDeliveryMethod = () => {
      data.cost = null;
      data.scheduleDeliveryType = null;
      if (
        data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics &&
        data.daycareServiceId
      ) {
        handleGetLogisticsScheme();
      } else if (
        data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir
      ) {
        handleGetCost();
      }
    };

    const handleGetCost = (record) => {
      //运输方式有值  且打托服务有值   且= 自提
      //运输方式有值  且打托服务有值   且= 赛易  且选择了物流
      if (
        !(
          data.daycareServiceId &&
          data.transitDeliveryMethod &&
          (data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir ||
            (data.transitDeliveryMethod ==
              transitDeliveryMethodEnum.seLogistics &&
              record.id))
        )
      ) {
        return;
      }
      let shipmentIds = [];
      data.list.forEach((x) => shipmentIds.push(x.id));
      //避免后端取不到 outBoundTransitShipments的值, 后端要求,他们接口不清晰.
      _funcCombinedParameters();
      let parameter = {
        transitDeliveryMethod: data.transitDeliveryMethod,
        warehouseId: data.warehouseId,
        daycareServiceId: data.daycareServiceId,
        shipmentIds: shipmentIds,
        logisticesFeeDetailDto: null,
      };

      //计划总查询费用
      if (data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics) {
        parameter.logisticesFeeDetailDto = {
          logisticsId: record?.id,
          isChooseDdp: logistics.isSelectedDDP,
          signatureType: logistics.signatureType,
          isChooseSign: logistics.signatureType > 0 ? true : false,
          deliveryType: record.deliveryWay,
          vatCode: logistics.VAT,

          warehouseId: data.warehouseId,
          destinationType: data.destinationType,
          toWarehouseId: data.toWarehouseId,
          toAddress: data.address,
          outBoundTransitShipments: data.outBoundTransitShipments,
          daycareServiceId: data.daycareServiceId,
        };

        if (logistics.calculationMethod === feeCalculationMethodEnum.truckApi) {
          parameter.logisticesFeeDetailDto.truckApiLogistics = {
            quoteDetailId: record.quoteDetail.quoteDetailId,
            quoteTypeId: record.quoteTypeId,
            serviceLevel: record.serviceLevel,
            accessorials: record.accessorials ?? [],
          };
        }
      }
      logistics.loading = true;
      data.loading = true;
      getCost(parameter)
        .then(({ result }) => {
          if (result) {
            data.cost = result;
          }
        })
        .catch(() => {
          data.cost = null;
        }).finally(() => {
          logistics.loading = false;
          data.loading = false;
        });
    };

    //-----------------------费用 end-----------------------------------------

    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(
        transitOutPlanKeys.createOutPlanData
      );
      if (cacheData) {
        Object.assign(data, cacheData);

        if (!data.warehouseId) {
          return handleCancelPlan();
        }
        handleGetHitTorrServeList();
      } else {
        handleCancelPlan();
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = [
        "/transit/outplan/create",
        "/transit/outplan/addshipment",
      ];
      if (!cacheRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(transitOutPlanKeys.createOutPlanData);
        }
      }
      next();
    });

    const handleLastStep = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_out_plan_add_shipment" });
    };

    const handleCancelPlan = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_out_plan_list" });
    };

    const handleGetHitTorrServeList = () => {
      dropdownData.hitTorrServeListLoading = true;
      getHitTorrServeList(data.warehouseId)
        .then(({ result }) => {
          dropdownData.hitTorrServeList = result;
          dropdownData.hitTorrServeListLoading = false;
        })
        .catch(() => {
          dropdownData.hitTorrServeListLoading = false;
        });
    };

    //--------------------------时间选择 start--------------------------------------------------
    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    //计算仓库时区时间
    const getWareZoneNowMoment = () => {
      var now = moment();
      if (data.inventoryWarehouseAddress?.timezone) {
        now = moment().tz(data.inventoryWarehouseAddress.timezone).local(true);
      }
      return now;
    };

    const disabledDate = (current) => {
      if (
        data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir ||
        (data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics &&
          data?.scheduleDeliveryType == scheduleDeliveryTypeEnum.dateAndTime)
      ) {
        //只有时间类型为 日期和时间 (选择日期可选择包含48小时后的一天)
        return (
          moment(current)
            .endOf("day")
            .startOf("hours")
            .diff(getWareZoneNowMoment(), "hours") <
          data.wareZoneAppointmentLimitHours
        );
      } else {
        //只有时间类型为 日期 (选择日期不可选择包含48小时后的一天)
        return (
          moment(current) <
          getWareZoneNowMoment()
            .endOf("day")
            .add(data.wareZoneAppointmentLimitHours, "hours")
        );
      }
    };

    const disabledDateTime = () => {
      return {
        disabledHours: () => {
          var afterXxHours = getWareZoneNowMoment().add(
            data.wareZoneAppointmentLimitHours,
            "hours"
          );
          var hour = afterXxHours.hour();
          if (moment(data.dateMoment).isSame(afterXxHours, "day")) {
            return range(0, hour + 1);
          } else {
            return range(0, 0);
          }
        },
      };
    };
    //--------------------------时间选择 end--------------------------------------------------

    //--------------------------物流选择 start------------------------------------------------
    const getLogisticsData = () => {
      return {
        isSearch: false,
        columnsLogisticsScheme: [],
        selectedLogisticsSchemeId: null,
        selectedLogisticsDeliveryType: null,
        logisticsType: null,

        logisticsSchemeList: [],
        logisticsSchemeExhibitionList: [],

        isShowVATInput: false,
        VAT: null,
        isSelectedDDP: false,
        isSelectedSignFee: false,
        signatureType: null,
        isRemoteArea: null, //是否为偏远地址
        isCommercial: null, //是否为商业地址
      };
    };

    const quotedPriceModalRef = ref(null);
    const supplierHelper = useSupplierHelper();

    const logistics = reactive({
      isSearch: false,
      columnsLogisticsScheme: null,
      selectedLogisticsSchemeId: null,
      selectedLogisticsDeliveryType: null, //派送方式(选择的物流方案包含的方式)
      logisticsType: null,

      logisticsSchemeList: [], //请求的数据包含商业物流和卡车
      logisticsSchemeExhibitionList: [], //最后展示出的物流 商业物流或卡车

      isShowVATInput: false,
      VAT: null,
      isSelectedDDP: false,
      isSelectedSignFee: false,
      signatureType: null,
      isRemoteArea: null,
      isCommercial: null,

      quoteTypeList: [], // 卡车-报价类型
      serviceLevelList: [], // 卡车-服务等级
      additionalOptionsList: [], // 卡车-附加选项

      loading: false,
      isShowCalculationMethod: false,
      calculationMethod: feeCalculationMethodEnum.template, //枚举商业物流或卡车
    });


    const showTimePart = computed(()=>{
      return  (data.transitDeliveryMethod == transitDeliveryMethodEnum.seLogistics &&
                data.scheduleDeliveryType == scheduleDeliveryTypeEnum.dateAndTime) 
              || data.transitDeliveryMethod == transitDeliveryMethodEnum.takeTheir;
    })

    const getDateFormat = ()=>{
      return showTimePart.value ?'YYYY-MM-DD HH:00:00':'YYYY-MM-DD';
    }

    watch(()=>logistics.logisticsType,(newVal, olbVal)=>{
      if(newVal != null){
        if(newVal == logisticsWayEnum.truckTransportation){
          data.scheduleDeliveryType = scheduleDeliveryTypeEnum.dateAndTime;
        }else{
          data.scheduleDeliveryType = scheduleDeliveryTypeEnum.date;
        }
      }
    })
    const handleGetLogisticsScheme = () => {
      data.scheduleDeliveryType = null; //时间类型清空
      data.cost = null;
      if (!data.daycareServiceId) {
        return;
      }
      Object.assign(logistics, getLogisticsData());
      _funcCombinedParameters();

      logistics.selectedLogisticsSchemeId = "";
      logistics.isSearch = true;
      logistics.loading = true;
      getLogisticsScheme({ ...data })
        .then(({ result }) => {
          if (result && result.length > 0) {
            logistics.logisticsSchemeList = result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                  ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                  : "0", //计费重
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                declareSupported: x.logisticsBaseInfo.declareSupported, //是否支持一般贸易报关
                logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字
                isVatRequired: x.logisticsRegionInfo.isVatRequired, //是否支持Vat

                // 卡车相关
                calculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                apiAccountId: x.logisticsBaseInfo.apiAccountId,
                loading: false,
              };
            });
          } else {
            logistics.logisticsSchemeList = [];
            logistics.logisticsSchemeExhibitionList = [];
          }
          handleChangeLogisticsScheme();
          logistics.loading = false;
        })
        .catch((e) => {
          logistics.loading = false;
        });
    };

    const handleChangeLogisticsScheme = () => {
      logistics.logisticsSchemeExhibitionList =
        logistics.logisticsSchemeList.filter(
          (item) => item.calculationMethod === logistics.calculationMethod
        );
      if (logistics.logisticsSchemeList.length) {
        // 如果只有（物流或卡车中的）一种方案就不显示tab
        if (
          logistics.logisticsSchemeExhibitionList?.length === 0 ||
          logistics.logisticsSchemeExhibitionList?.length ===
            logistics.logisticsSchemeList?.length
        ) {
          logistics.isShowCalculationMethod = false;
        } else {
          logistics.isShowCalculationMethod = true;
        }
        if (logistics.logisticsSchemeExhibitionList?.length === 0) {
          if (
            logistics.calculationMethod === feeCalculationMethodEnum.template
          ) {
            logistics.calculationMethod = feeCalculationMethodEnum.truckApi;
          } else {
            logistics.calculationMethod = feeCalculationMethodEnum.template;
          }
          logistics.logisticsSchemeExhibitionList =
            logistics.logisticsSchemeList.filter(
              (item) => item.calculationMethod === logistics.calculationMethod
            );
        }
      } else {
        logistics.isShowCalculationMethod = false;
      }

      if (logistics.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        logistics.columnsLogisticsScheme = columnsLogisticsScheme;
      } else if (
        logistics.calculationMethod === feeCalculationMethodEnum.truckApi
      ) {
        // 卡车
        logistics.columnsLogisticsScheme = columnsLogisticsTruck;
        getSupplierList();
      }
    };

    const getSupplierList = async () => {
      try {
        logistics.quoteTypeList = await supplierHelper.getQuoteTypeList();
        logistics.serviceLevelList = await supplierHelper.getServiceLevelList();
        logistics.additionalOptionsList =
          await supplierHelper.getAdditionalOptionsList();
      } catch (error) {}
    };

    const handleSelectedLogistics = (record) => {
      if (!record) {
        return;
      }
      logistics.record = record; //用于支付的时候

      if (logistics.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        logistics.columnsLogisticsScheme =
          logistics.columnsLogisticsScheme.filter(
            (x) => x.dataIndex != "option"
          );
      }

      let pra = {
        warehouseId: data.warehouseId,
        destinationType: data.destinationType,
        toWarehouseId: data.toWarehouseId,
        toAddress: data.address,
        outBoundTransitShipments: data.outBoundTransitShipments,
        daycareServiceId: data.daycareServiceId,
        logisticsId: record.id,
        deliveryType: record.deliveryWay,
      };
      // 判断是否 选择为卡车
      if (logistics.calculationMethod === feeCalculationMethodEnum.truckApi) {
        pra.truckApiLogistics = {};
        pra.truckApiLogistics["quoteDetailId"] =
          record.quoteDetail.quoteDetailId;
        pra.truckApiLogistics["quoteTypeId"] = record.quoteTypeId;
        pra.truckApiLogistics["serviceLevel"] = record.serviceLevel;
        pra.truckApiLogistics["accessorials"] = record.accessorials ?? [];
      }

      logistics.loading = true;
      getLogisticsAddressInfo(pra)
        .then((res) => {
          if (res.result) {
            logistics.logisticsSchemeList = [];
            logistics.logisticsSchemeExhibitionList = [];

            let r = [res.result];
            logistics.isRemoteArea = res.result.logisticsBaseInfo.isRemoteArea;
            // 是否为商业地址: null - 未知地址; true - 商业地址; false - 私人(住宅)地址
            logistics.isCommercial = res.result.logisticsBaseInfo.isCommercial;

            //主要重新返回了新的费用
            logistics.logisticsSchemeList = r?.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                  ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                  : "0", //计费重
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                declareSupported: x.logisticsBaseInfo.declareSupported, //是否支持一般贸易报关
                logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字
                isVatRequired: x.logisticsRegionInfo.isVatRequired, //是否支持Vat

                // 卡车相关
                calculationMethod: x.logisticsBaseInfo.feeCalculationMethod,
                apiAccountId: x.logisticsBaseInfo.apiAccountId,
                truckBaseInfo: x.logisticsBaseInfo.truckBaseInfo,
              };
            });
            logistics.selectedLogisticsSchemeId = record.id;
            logistics.selectedLogisticsDeliveryType = record.deliveryWay;
            logistics.logisticsType = record.logisticsWay;
            logistics.isShowVATInput = record.isVatRequired;
            logistics.isDeclareSupported = record.declareSupported;
            handleChangeLogisticsScheme();
          }
          logistics.loading = false;
        })
        .catch(() => {
          logistics.loading = false;
        });

      handleGetCost(record);
    };

    // 物流可选服务
    const columnsLogisticsScheme = [
      {
        dataIndex: "option",
        slots: {
          title: "option",
          customRender: "optionCustom",
        },
        width: 100,
      },
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 100,
      },
      {
        dataIndex: "freights", //运费
        width: 220,
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("warehouse.optional_services"),
        slots: {
          customRender: "optionalServicesCustom",
        },
        width: 180,
      },
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ];

    //---卡车--------
    const handleShowServiceLevelSelect = (record) => {
      let item = logistics.quoteTypeList.find(
        (item) => item.id === record.quoteTypeId
      );
      if (item) {
        return item.needService;
      }
      return false;
    };

    const handleSearchTruckFee = async (record) => {
      if (!record.quoteTypeId) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.quote_type"),
          ])
        );
        return;
      }
      if (!handleShowServiceLevelSelect(record)) {
        record.serviceLevel = null;
      } else {
        if (!record.serviceLevel) {
          message.error(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.service_level"),
            ])
          );
          return;
        }
      }
      const searchData = {
        warehouseId: data.warehouseId,
        destinationType: data.destinationType,
        toWarehouseId: data.toWarehouseId,
        toAddress: data.toAddress,
        outBoundTransitShipments: data.outBoundTransitShipments,
        daycareServiceId: data.daycareServiceId,
        truckApiLogistics: {
          quoteTypeId: record.quoteTypeId,
          serviceLevel: record.serviceLevel,
          accessorials: record.accessorials ?? [],
        },
        logisticsId: record.id,
      };
      quotedPriceModalRef.value.open(searchData);
    };

    //确定卡车
    const handleConfirmQuotedPrice = ({ logisticsId, record }) => {
      let index = logistics.logisticsSchemeExhibitionList.findIndex(
        (item) => item.id === logisticsId
      );
      if (index > -1) {
        logistics.logisticsSchemeExhibitionList[index].quoteDetail = record;
      }
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return "-";
      }
      return list.map((item) => item.name).join(", ");
    };
    //--------------------------物流选择 end------------------------------------------------

    watch(() => [logistics.isSelectedDDP, logistics.signatureType], (val) => {
      handleGetCost({ id: logistics.selectedLogisticsSchemeId })
    });

    getCacheData();

    const columns = computed(() => {
      let result = [];
      if ( data.list.findIndex((x) => x.representedShop) >= 0) {
        const columnCustomer = {
          title: () => vueI18n.t("account.customer"),
          slots: {
            customRender: "proxy_tenant_info",
          },
          width: 100,
        };
        result = [...columnsBase.map((item) => ({ ...item }))];
        result.splice(2, 0, columnCustomer);
      } else {
        result = columnsBase;
      }
      return result;
    });

    return {
      cmCubicToM,
      gToKg,
      kgToG,
      destinationTypeEnum,
      deliveryWayEnum,
      transitDeliveryMethodEnum,
      scheduleDeliveryTypeEnum,
      feeCalculationMethodEnum,
      logisticsWayEnum,
      data,
      refForm,
      //卡车
      quotedPriceModalRef,
      refPayment,
      showTimePart,
      logistics,
      moment,
      columns,
      innerColumns,
      dropdownData,
      getLinkman,
      getAddress,
      disabledDateTime,
      disabledDate,
      handleGetRowDetail,
      handleGetLogisticsScheme,
      handleChangeTransitDeliveryMethod,
      handleSelectedLogistics,
      handleChangeLogisticsScheme,
      getDateFormat,
      handleShowServiceLevelSelect,
      handleSearchTruckFee,
      handleConfirmQuotedPrice,
      getAccessorialsName,
      handleGetCost,
      handleShowPayModal,
      handleValidator,
      handleOrderPay,
      handleLastStep,
      handleCancelPlan,
    };
  },
});
</script>

<style lang="less" scoped>
.delete-label {
  position: absolute;
  top: 100%;
  left: 10%;
  font-size: 15px;
  color: #d9d9d9;
  :hover {
    color: #04befe;
  }
}
</style>
