//仓库信息相关:中文
export default {
  warehouse: {
    average_unit_price: "平均单价",
    total_prices: "合计",
    workday: "工作日",
    add_product: "添加产品",
    confirm_and_pay: "确定并支付",
    picture: "图片",
    product_name: "产品名称",
    status: "状态",
    cancel_out_warehouse: "取消出库",
    set_user_defined_box_no: "设置自定义箱号",
    acquisition_logistics: "获取物流方案",
    warehouse_code: "仓库代码",
    label_processing: "贴标方式",
    from_product_address: "发货地址",
    to_product_address: "收货地址",
    receiving_address: "收货地址",
    encasement_specification: "装箱规格",
    in_warehouse_size: "入库规格",
    in_warehouse_product_size: "入库产品尺寸",
    in_warehouse_time: "入库时间",
    return_goods_in_warehouse_time: "退货入库时间",
    in_warehouse_weight: "入库重量",
    in_warehouse_product_weight: "入库产品重量",
    encasement_count: "装箱数量",
    product_gross_weight: "产品毛重",
    product_label: "产品标签",
    not_select_service: "不选择服务",
    transport_characteristics: "运输特征",
    box_no: "箱号",
    box_no_is_not_added: "还未添加箱号!",
    please_add_the_container_number: "请添加箱号",
    plan_no: "计划编号",
    consignment_code: "配送仓代码",
    consignment_warehouse_code: "配送仓-仓库代码",
    storage_code: "存储仓代码",
    can_identification_content: "可识别内容",
    hit_torr_serve: "打托服务",
    tray_volume_restriction: "每托盘体积限制",
    tray_weight_limit: "每托盘限重",
    not_required: "不需要",
    warehouse_no: "仓库代码",
    await_in_warehouse_count: "待入库数量",
    in_warehouse_count: "入库数量",
    await_in_warehouse_box_count: "待入库箱数",
    in_warehouse_box_count: "入库箱数",
    on_warehouse_count: "在库数量",
    on_warehouse_usable_count: "在库可用数量",
    on_warehouse_usable_box_count: "在库可用箱数",
    await_out_warehouse_count: "待出库数量",
    await_out_warehouse_box_count: "待出库箱数",
    inventory_affiliation_warehouse: "库存所在仓",
    out_warehouse_no: "出库仓编号",
    product_inventory: "产品库存",
    inventory_total: "库存总计",
    storage_warehouse_total: "存储仓合计",
    storage_warehouse_inventory: "存储仓库存",
    delivery_warehouse_inventory: "配送仓库存",
    storage_warehouse_in_plan: "存储仓入库计划",
    storage_in_plan_no: "存储仓入库编号",
    consignment_warehouse_in_plan: "配送仓入库计划",
    consignment_warehouse_total: "配送仓合计",
    alter_product_info: "修改产品信息",
    storage_logistics_info: "仓储物流信息",
    you_need_at_least_one_product: "至少需要一个产品",
    at_least_one_box_of_data_is_required: "至少需要一条箱数据",
    cannot_create_out_plan: "无法创建出库计划",
    we_no_have_your_goods_in_the_warehouse: "在我们仓库中没有您的库存货物.",

    select_consignment: "选择配送商品",
    consignment_manage: "配送仓管理",
    relevance_info: "关联信息",
    general_cargo: "普货",

    currency_code: "币种代码",
    battery_type: "电池类型",
    battery_ingredient: "电池成分",
    apply_content: "申请内容",
    battery_capacity: "电池容量",
    encasement_size: "装箱尺寸",
    encasement_weight: "装箱重量",
    consignment_warehouse_verified_packaging_size: "配送仓已校验的包装尺寸",
    consignment_warehouse_verified_packaging_weight: "配送仓已校验的包装重量",
    customs_declare_info: "海关申报信息",
    declare_price: "申报单价",
    english_trade_name: "英文品名",
    chinese_trade_name: "中文品名",
    english_texture: "英文材质",
    chinese_texture: "中文材质",
    is_a_brand: "是否有品牌",
    label_dispose: "标签处理",
    in_warehouse_status: "入库状态",
    in_warehouse_encasement_size: "入库装箱尺寸",
    in_warehouse_encasement_weight: "入库装箱重量",
    approved_package_size: "核定包装尺寸",
    approved_package_weight: "核定包装重量",
    in_warehouse_img: "入库图片",
    despatch_and_pay: "发运并支付",
    product_not_filled_full: "产品数据未填完整",
    incomplete_packing_data: "装箱数据未填完整",
    upload_prove_file: "上传证明文件",
    alias: "别名",
    labels_cannot_be_scanned_handling_expense_p0: "标签无法扫描,处理费用{0}",

    customs_no: "海关编码",
    logistics_delivery_status: "物流交付状态",
    create_in_warehouse_plan: "创建入库计划",
    create_out_warehouse_plan: "创建出库计划",
    plan_name: "计划名称",
    in_plan_name: "入库计划名称",
    in_warehouse_no: "入库编号",
    plan_no: "计划编号",
    in_warehouse_plan_no: "入库计划编号",
    out_warehouse_plan_no: "出库计划编号",

    out_plan_detail: "出库计划详情",
    out_plan_name: "出库计划名称",
    out_warehouse_time: "出库时间",
    approved_packaging_info: "核定的包装信息",
    error_state: "异常状态",
    request_to_investigate: "请求调查",
    confirm_out_warehouse_plan: "确认出库计划",

    check_in_warehouse: "查看入库计划",
    check_out_warehouse: "查看出库计划",

    belonging_to_warehouse: "所在仓库",
    add_box_no: "添加箱号",

    select_out_warehouse_way: "选择出库方式",
    add_box_info: "添加箱信息",
    add_plan_name: "添加计划名称",
    confirm_out_plan: "确定出库计划",

    please_input_plan_name: "请输入计划名称",

    outer_box_label: "外箱标签",
    planned_quantity: "计划数量",
    planned_box_quantity: "计划箱数",
    product_quantity: "产品数量",
    receive_box_quantity: "接收箱数",

    // 运输计划-----> 运输计划
    transportation_plan_number: "运输计划编号",
    destination_type: "目的地类型",
    purpose_country_city: "目的国家/城市",
    plan_status: "计划状态",
    payment: "支付方式",
    selected_pallet_packing_serve: "已选打托服务",
    pallet_packing_tips: "（该服务将影响您可选的物流方案）",
    anew_in_warehouse: "重新入库",
    reserved_take_goods_info: "预留提货信息",
    reserved_info_title: "可输入提货对象的运单号，司机身份信息等可识别信息",
    only_allow_input_letter_and_number: "仅可输入字母和数字",
    out_warehouse: "出库仓",
    transportation_delivery_way: "运输交付方式",
    delivery_way: "交付方式",
    transportation_delivery_status: "运输交付状态",
    subsequent_steps: "后续步骤",
    plan_details: "计划详情",

    label_serve: "标签服务",
    paid: "付费",
    label_serve_paid: "标签服务(付费)",
    out_warehouse_encasement_weight: "出库装箱重量",
    out_warehouse_encasement_size: "出库装箱尺寸",
    created_transport_plan: "创建运输计划",

    please_select_transportation_or_delivery_way: "请选择运输交付方式",
    please_select_the_pick_up_method: "请选择自提方式",
    pick_up_method: "自提方式",
    in_warehouse_type: "入库类型",
    in_target_warehouse: "入库目标仓",
    target_warehouse: "目标仓",
    out_warehouse_type: "出库类型",
    selected_common_address: "选择一个常用地址",
    inbound_target_warehouse: "入库目标仓",
    charge: "费用",
    address: "地址",

    warehouse_address: "仓库地址",
    serial_number: "序号",
    created_plan: "创建计划",
    target_warehouse_code: "目标仓库代码",
    originate: "发自",
    destination: "发至",

    send_goods_address: "发货方地址",
    detail_address: "详细地址",
    refresh_address: "更新常用地址",
    save_as_common_address: "保存为常用地址",
    common_address: "常用地址",
    select_address: "选择地址",
    receive_goods_address: "收货地址",
    transport_goods_address: "运送地址",
    amazon_shipping_number: "亚马逊运输编号",
    shipment_tracking_number: "货件追踪编号",
    warehousing_number: "货件 ID",
    seller_id: "Seller ID",

    tracking_shipment: "追踪货件",
    tracking_no: "追踪号",
    product_no: "产品货号",
    product_imag: "产品图",
    product_packing_imag: "产品包装图",
    tracking_ticket_no: "跟踪单号",
    //  创建运输计划-设置物流信息

    select_warehouse_nonsupport_consignment:
      "当前库存所在仓库不支持配送服务,请更换库存所在仓库",
    logistics_way: "物流方式",
    delivery_way: "派送方式",
    please_select_delivery_way: "请选择派送方式",
    fba_warehouse_code: "FBA仓库代码",
    mercado_libre_warehouse_code: "美客多仓库代码",
    fbm_warehouse_code: "FBM仓库代码",
    international_transport: "国际运输",
    product_info: "产品信息",
    encasement_info: "装箱信息",
    rests: "其他",
    channel: "渠道",
    express_sheet: "面单",
    third_side_list: "第三方面单",
    box: "箱",
    out_total_volume: "出库总体积",
    out_total_weight: "出库总重量",
    customs_clearance_info: "清关信息",
    loading_default_settings: "加载默认设置",
    declared_price: "申报价格",
    add_inbound_goods: "添加入库商品",
    please_enter_product_name_product_number_seSku: "请输入产品名称、产品货号、SESKU",
    please_enter_box_no: "请输入箱号",

    dispose_in_warehouse_plan: "处理入库计划",
    dispose_out_warehouse_plan: "处理出库计划",
    send_shipping_in_warehouse_plan: "发运入库计划",
    need_operate: "需操作",

    logistics_scheme: "物流方案",
    logistics_code: "物流代码",
    logistics_type: "物流类型",
    reference_aging: "参考时效",
    freight: "运费",
    freight_estimate: "运费预估",
    delete_transport_plan: "删除运输计划",
    optional_services: "可选服务",

    business_number: "业务编号",
    happen_warehouse_code: "发生仓库代码",
    business_happen_country: "业务发生国家",
    time: "时间",
    time_type: "时间类型",

    business_type: "业务类型",

    in_warehouse_plan: "入库计划",
    warehouse_type: "仓库类型",
    tracking_number: "跟踪号",
    request_time: "请求时间",
    investigation_status: "调查状态",
    survey_result: "调查结果",
    end_time: "结束时间",

    product_has_not_added: "产品还未添加",

    error_customs_declaration: "海关申报表格中一行的数据需要填写完整",
    please_input_search_product_no_name: "请输入产品货号、名称、SESKU",

    total_box_count: "总箱数",
    total_volume: "总体积",
    total_encasement_weight: "总装箱重量",
    table_in_amount_is_required: "表格中的数量是必填的",
    please_add_products: "请添加产品!",
    additional_packing_material_selection: "附加包装材料选择",
    additional_packing_material: "附加包装材料",
    packing_material_selection: "包装材料选择",
    no_additional_packaging_required: "无需额外包装",

    print_sesku_label: "打印SE标签",
    download_se_label: "下载SE标签",

    create_allot_to_storage_of_plan: "创建调拨至存储仓的计划",
    select_need_operate_consignment: "选择需操作的配送仓",
    select_goods_and_packaging: "选择商品与包装",
    set_box_number: "设置装箱数量",
    current_warehouse_usable_inventory: "当前仓库可用库存",
    box_amount: "箱数",
    select_packaging_box: "包装箱选择",
    product_name: "产品名称",
    product_sku: "产品货号",

    weight: "重量",
    data_error: "数据错误",
    product_error: "产品错误",
    yes: "是",
    no: "否",
    packing_material_name: "包装材料名称",
    materials: "材料",
    outer_diameter_size: "外径尺寸",
    material_fee: "材料费用",
    total_weight_after_packing: "装箱后总重量",
    space_utilization: "空间利用率",
    create_box_info: "生成箱信息",
    allot_amount: "调拨数量",
    allot_to_storage_plan_detail: "调拨至存储仓的计划详情",

    my_product: "我的产品",
    sku_number: "SKU数量",

    storage_warehouse: "存储仓",
    on_warehouse_sku_number: "在库SKU数量",
    inventory_box_count: "库存箱数",
    estimated_inventory_quantity: "预估库存数量",

    consignment_warehouse: "配送仓",
    inventory_count: "库存数量",

    planing: "计划中",
    await_pay_transport_plan: "等待支付的运输计划",

    await_receive_in_pan: "待接收的入库计划",

    out_pan: "出库计划",
    submitted: "已提交",
    in_the_outbound_plan: "出库中的出库计划",
    await_logistics_pan: "等待物流方案的计划",

    consignment_order: "配送订单",
    the_quantity_of_batch_import_order_is_p0: "批量导入订单数量为:{0}",
    the_quantity_of_batch_import_is_p0: "批量导入数量为:{0}",
    current_product_information: "当前产品信息",
    await_operate_consignment_order: "待操作的配送订单",
    placed_an_order: "已下单",
    order_in_process: "处理中的订单",
    create_full_container_in_appointment: "创建整柜入库预约",
    add_in_plan: "添加入库计划",
    create_in_appointment_warning:
      "请正确选择集装箱类型和填写集装箱编号, 并且按照预约日期送达，迟到或错误的信息将有可能导致仓库拒收",
    landing_charges: "卸货费",
    create_appointment: "创建预约",
    please_add_the_warehousing_plan_for_the_warehouse: "请添加仓库的入库计划",

    reservation_number: "预约号",
    date_created: "创建日期",
    appointment_warehouse: "预约仓库",
    container_type: "集装箱类型",
    date_of_reservation: "预约日期",
    details_of_FCL_storage_booking: "整柜入库预约详情",
    local_time: "当地时间",
    pick_up_time:"提货时间",
    shipping_time:"发运时间",
    delivery_time:"送达时间",
    reacquire_logistics_solutions:"重新获取物流方案",

    select_warehouse: "选择仓库",
    add_in_plan: "添加该仓库的入库计划",
    in_warehouse_code: "入库仓库代码",
    out_warehouse_code: "出库仓库代码",
    contain_in_plan_count: "包含入库计划数",
    fcl_storage_reservation: "整柜入库预约",
    reservation_number_or_cabinet_number: "预约编号或柜号",

    total_volume_of_transport: "运输总体积",
    gross_shipping_weight: "运输总重量",

    create_similar_product: "创建类似产品",

    tooltip_text_1: "用户预设的每箱产品数量",
    tooltip_text_2: "用户预设的尺寸重量信息",
    tooltip_text_3: "仓库或物流中心扫描的尺寸重量信息",
    tooltip_text_4: "用户预设的尺寸信息",
    tooltip_text_5: "用户预设的重量信息",
    tooltip_text_6: "预估运费不包含任何附加费用项目",

    plan_number_or_out_warehouse_number: "计划名称或出库编号",
    cancel_plan: "取消计划",
    timed_out: "已超时",
    liquidated_damages_tips: "请确认预约真实有效，若爽约将收取爽约金",
    warn: "警告",
    modify_box_product_data_warning:
      "修改箱的产品数据会导致与该箱有关的入库计划，运输计划的历史数据同时被修改，请谨慎操作",

    return_of_the_source: "退货来源",
    the_goods_warehouse: "收货仓库",
    return_the_parcel: "退货包裹",
    return_plan_no: "退货计划编号",
    returns_the_number_of: "退货数量",
    received_quantity: "已接收数量",
    parcel_tracking_number: "货包裹跟踪号",
    scannable_number: "可扫描编号",
    adding_returned_products: "添加退货产品",
    product_size: "产品包装尺寸",

    product_dimensions: "产品尺寸",
    product_of_size: "产品的包装尺寸",
    product_weight: "产品重量",
    product_contains_the_weight_of_the_package: "产品含包装的重量",

    identifiable_information: "可识别信息",
    product_SKU: "产品SKU",
    returns_the_total: "退货总数",
    confirm_and_pay: "确认并支付",
    the_parcel_status: "包裹状态",
    please_adding_returned_products: "请添加退货产品",
    product: "产品",
    destruction_of_prompt_p0:
      "判定为不合格产品将自动选择销毁，并收取销毁服务费{0}/单位",
    agree_to_destroy_service_fee: "同意自动收取不合格产品的销毁服务费",
    agree_to_differential_charges:
      "同意自动收取实际重量与预报重量差异造成的差额费用",
    destroyed: "已销毁",
    received: "已接收",
    destruction_of_the_number: "销毁数量",
    quality_inspection_results: "质检结果",
    the_processing_results: "处理结果",
    processing_records: "处理记录",
    destruction_handling_charge: "销毁操作费",
    is_qualified_1: "合格",
    is_qualified_2: "不合格",

    creation_time: "创建时间",
    update_time: "更新时间",

    source_country: "始发国家",
    recipients: "收件人",
    recipient_company: "收件公司",
    phone: "电话",
    customer_number: "客户单号",
    customer_name: "客户名称",
    system_number: "系统单号",
    tracking_number_1: "转单号",
    originating_country: "始发国家",
    operating_state: "操作状态",
    shipping_lines: "运输线路",
    number: "件数",
    actual_weight: "实重",
    volume: "体积",
    channel_code: "渠道代码",
    channel_name: "渠道名称",
    turn_single_track: "转单轨迹",
    total_weight: "总重量",
    add_box: "添加箱",
    batch_add: "批量添加",
    box_is_not_added: "还未添加箱",
    size: "尺寸",
    girth: "围长",
    add_single_box: "添加单箱",
    applicable: "可用",
    not_applicable: "不可用",
    not_applicable_tips: "包括待出库、其它操作或流程处理中",
    print_waybill: "打印运单",
    cancel_waybill: "取消运单",
    check_the_track: "查看轨迹",
    confirm_cancellation_of_waybill: "是否确认取消运单？",
    no_waybill_channel_available_at_present: "暂无可用的渠道",
    please_select_the_receiving_warehouse_first: "请先选择收货仓库",
    no_approved_record: "未有核定记录",
    p0_is_not_repeat: "{0}不能重复",
    FNSKU_and_other_unique_information: "FNSKU等唯一性信息",
    scannable_information_error: "可扫描信息错误",
    whether_to_keep_the_outer_packaging: "是否保留外包装",
    need_to_keep_the_outer_packaging: "需保留外包装",
    do_not_keep_the_outer_packaging: "勿须保留外包装",
    please_enter_the_correct_p0: "请输入正确的{0}",
    trajectory: "轨迹",
    trajectory_status: "轨迹状态",
    untraceable: "不可追踪",
    modify_the_number_of_times: "可修改次数",
    total_cost: "费用总计",
    product_item_no: "产品货号",
    print_out_the_list: "打印出库单",
    product_label_name: "产品标签名称",
    the_code_cannot_be_recognized_please_change_the_file:
      "无法识别编码,请更换文件",

    apply_id: "应用ID",
    license_application: "授权应用",
    application_list: "授权列表",
    permission: "权限",
    privileged_time: "授权时间",
    please_input_apply_name_or_id: "请输入应用名称或ID",
    cancel_application: "取消授权",
    texture: "材质",
    cancel_count: "取消数量",
    cancel_box_count: "取消箱数",
    cancel_order: "取消订单",
    cancel_shipment: "申请取消发货",
    relation_no: "相关单号",
    are_you_sure_cancel_order: "你确定要取消订单吗?",
    are_you_sure_cancel_shipment: "你确定要申请取消发货吗?",
    are_you_sure_cancel_destruction: "你确定要申请取消销毁吗?",
    cancel_shipment_processing: "取消发货- 处理中",
    cancel_shipment_succeed: "取消发货- 成功",
    cancel_shipment_unsuccessful: "取消发货- 失败",
    product_no_format: "(只允许输入4-64长度的字母、数字、'-'、'_')",
    product_no_format_hint: "请输入4-64长度的字母、数字、'-'、'_'",

    inbound_plan: "入库计划",
    inbound_plan_of_storage_warehouse: "存储仓入库",
    inbound_plan_of_consignment_warehouse: "配送仓入库",
    inbound_plan_of_transit_warehouse: "中转仓入库",
    outbound_plan: "出库计划",
    outbound_plan_of_transit_warehouse: "中转仓出库",
    outbound_plan_of_storage_warehouse: "存储仓出库",
    transport_plan: "运输计划",

    brand_info: "品牌信息",
    brand_name: "品牌名称",
    purpose_description: "用途",
    have_name: "有品牌",
    no_brand: "无品牌",
    purpose_chinese_description: "中文用途",
    purpose_english_description: "英文用途",

    max_volume_weight: "最大容重",
    when_the_total_number_of_products_is_greater_than_1_this_option_is_mandatory:
      "当产品总数量大于1时,该选项为必选",

    received_by_warehouse_at_p0_has_been_stored_for_p1_days:
      "在{0} 由仓库接收，已存放{1}天",
    the_return_hold_fee_of_p0_will_be_automatically_deducted_by_the_system_and_can_be_viewed_in_the_return_plan_details:
      "退货暂存费 {0} 将由系统自动扣除，并在退货计划详情中可查看该费用",

    please_create_a_return_plan_before_the_return_arrives_at_warehouse:
      "请在退货到达赛易仓库前及时创建退货计划",
    tips_for_creating_a_return_plan_1:
      "在赛易仓库接收退货后，将收取退货暂存费",
    tips_for_creating_a_return_plan_2:
      "退货到达赛易仓库，{0}日无匹配的退回计划的，将自动销毁",

    the_goods_with_package_number_p0_have_been_destroyed_time_of_entry_p1_time_of_destruction_p3:
      "包裹号为 {0} 的货物已被销毁，进库时间: {1}，销毁时间: {2}",
    return_package_number: "退货包裹号",

    compensation: "赔付",
    whether_to_refund: "是否退款",
    whether_to_compensate: "是否赔付",

    import_products: "导入产品",
    select_import_file: "选择导入文件",
    click_download_template: "点击下载模板",
    when_you_confirm_the_import_only_the_correct_product_data_will_be_imported:
      "注意: 确认导入时, 只有正确的产品数据才会被导入",
    note_data_can_only_be_imported_if_all_data_is_correct:
      "注意: 只有数据全部正确才能够被导入",

    please_first: "请先",
    download_template: "下载模板",
    then_follow_the_prompts_to_complete: "然后根据提示填写完整",

    consignment_order_bulk_import_text2: "单次最多导入100单",
    consignment_order_bulk_import_text3:
      "当一单中有多个产品时，请保持相关单号一致。会根据第一个单号数据，自动合并产品到对应配送单中",
    consignment_order_bulk_import_text4:
      "当物选择流类型为第三方面单时(Others)，可直接导入第三方面单图片文件，也可稍后在操作界面中上传其他类型文件",

    american: "美国",

    mexico: "墨西哥",
    p0_is_abnormal_and_cannot_be_modified: "{0}存在异常无法修改",

    packing_methods: "包装方式",
    merge_packaging_tips: "将多个产品合并打包成一个包装，根据产品数量计费",
    merge_packaging_fee: "合并包装费用",
    merge_packaging_has_been_selected: "已选择合并包装",
    merge_packaging_not_supported: "不支持合并包装",
    fee_code: "费用代码",
    standby_identification1: "备用识别信息1",
    standby_identification2: "备用识别信息2",
    returned_package_and_alternate_identification_information_are_unique:
      "退货包裹和备用识别信息是唯一的.",

    reserve_warehousing: "预约入库",
    in_warehouse_reservation: "入库预约",
    create_transfer_warehouse_reserve_warehousing: "创建中转仓预约入库",
    warehousing_reservation_number: "入库预约号",
    type_of_shipping: "运输方式",
    number_of_packages: "货件数",
    create_date: "创建日期",
    update_date: "更新日期",
    print_reserve_information: "打印预约信",
    transfer_warehouse: "中转仓库",
    estimated_arrival_date: "预计到库日期",
    reserve_warehousing_warning:
      "请正确选择运输方式并且按照预约日期送达，迟到或错误的信息将有可能导致仓库拒收",
    add_shipment: "添加入库货件",
    edit_shipment: "编辑入库货件",
    click_or_drag_file_to_this_area_to_upload: "单击或拖动文件到此区域进行上传",
    cancel_the_reservation:"取消预约",
    upload_successful:"上传成功",
    fail_to_upload:"上传失败",
    shipment_quantity:"货件数量",
    error_quantity:"错误数量",
    container_loading_list:"装箱清单",
    shipment_list:"货件列表",
    scannable_box_no:"可扫描的箱号",
    setb_number: "SETB编号",
    scannable_box_no_hint:"请提供可扫描的条码编号，条码编号需要唯一",
    shipment_name:"货件名称",
    system_box_no:"系统箱号",
    system_box_number_SETB:"系统箱号-SETB",
    setb_box_number: "SETB箱号",
    se_box_number: "SE箱编号",
    box_id:"箱ID",
    prediction_size:"预报尺寸",
    prediction_weight:"预报重量",
    box_type:"箱型",
    se_shipment_number:"SE货件号",
    total_volume_of_shipment:"货件总体积",
    total_weight_of_the_shipment:"货件总重量",
    transit_type:"中转类型",
    print_SE_shipment_label:"打印SE货件标签",
    print_SETB_labels_inside_the_shipment:"打印货件内SETB标签",
    print_SETB_labels:"打印SETB标签",
    add_shipment:"添加货件",
    create_new_shipment:"创建新货件",
    add_type:"添加类型",
    set_out_plan:"设置出库计划",
    identifiable_box_number: "可识别箱号",
    forecast_data: "预报数据",
    inbound_data: "入库数据",
    inbound_date: "入库日期",
    outbound_date: "出库日期",
    shipment_volume:"货件体积",
    shipment_weight:"货件重量",
    out_data:"出库数据",
    remove_out_shipment:"移除出库货件",
    in_warehouse_reservation_name :"入库预约名称",
    in_warehouse_reservation_no :"入库预约编号",

    transfer_warehouse_reservation_removal_prompt:"提示：当货件中最后一箱被删除后，货件将会被同步删除",
    delete_shipment:"删除货件",
    detail_shipment:"货件详情",
    p0_transfer_warehouse_reservation_delete_prompt:"删除该货件将同时会删除该货件下的{0}个箱号信息，请谨慎操作",
    se_shipment_no:"SE货件编号",
    p0_shipment_have_been_selected: "已选中{0}货件",
    p0_outbound_shipments:"{0}个出库货件",
    se_warehouse_code:"赛易仓库代码",
    reservation_file:"预约文件",
    transit_in_plan_detail:"入库预约详情",
    modify_reservation_time:"修改预约日期",
    reservation_time:"预约日期",
    box_status:"箱状态",
    modify_reservation_time_text1:"提示: 须提前48小时修改预约日期",
    finalize_the_plan_and_pay_for_it:"确定计划并支付",
    edit_box_info:"编辑箱信息",
    add_to_existing_shipment:"添加至已有货件",
    reservations_are_required_p0_hours_in_advance:"预约需要提前{0}小时",
    please_add_outbound_shipments:"请添加出库货件",
    outbound_appointment_number: "出库预约号",
    outbound_destination: "出库目的地",
    shipment_status:"货件状态",
    jump_to_in_plan_prompt:"关联的入库计划创建成功，请及时确认信息和支付费用.",
    jump_to_transport_plan_prompt:"关联的运输计划创建成功，请及时确认信息和支付费用.",
    shipment_not_set:"为谁发货",
    
    adjustment_type: "调整类型",
    operator: "操作人",
    inventory_remaining_quantity: "库存剩余数量",
    inventory_adjustment_quantity: "库存调整数量",
    current_location: "当前所在库位",
    recognizable_barcode: "可识别条码",
    receiving_time: "接收时间",
    returns_are_transferred_to_the_warehouse: "退货转入仓库",
    temporary_storage_time: "暂存时间",
    temporary_storage_fee: "临时存放费",
    destruction_fee: "销毁费",
    return_warehouse_code: "退货仓库代码",
    additional_identification_information: "额外的识别信息",
    create_channel_return_plan: "创建渠道退货计划",
    destruction: "销毁",
    return_operation_completed: "已完成退货操作",
    matched_return_plan: "已匹配退货计划",
    no_matching_return_plan: "无匹配的退货计划",
    can_be_automatically_destroyed: "可自动销毁",
    whether_to_confirm_the_destruction: "是否确认销毁?",
    label_task: "标签任务",
    label_task_name: "标签任务名称",
    tag_task_number: "标签任务编号",
    label_service_type: "标签服务类型",
    task_time: "任务时间",
    change_the_shipment_name: "改变货件名称",
    new_shipment_name: "新货件名称",
    number_of_task_boxes: "任务箱数",
    number_of_cargo_boxes: "货件箱数",
    number_of_labels_per_box: "每箱标签数",
    task_status: "任务状态",
    target_warehouse: "目标仓库",
    first_step: "第一步",
    second_step: "第二步",
    third_step: "第三步",
    fourth_step: "第四步",
    upload_tags_and_set_the_quantity: "上传标签及设置数量",
    select_shipment: "选择货件",
    all_selection: "全部选择",
    p0_boxes_have_been_selected_the_total_shipment_is_p1_boxes: "已选择{0}箱, 货件总计{1}箱",
    create_and_next: "创建并下一步",
    new_box_label: "新的箱标签",
    box_label_quantity: "箱标签数量",
    upload_box_label: "上传箱标签",
    add_product_tags: "添加产品标签",
    identifiable_product_information: "可识别产品信息",
    identifiable_product_information_already_exists: "可识别产品信息已存在",
    recognizable_product_images: "可识别产品图片",
    new_product_labeling_document: "新产品标签文件",
    new_product_label: "新产品标签",
    modify_shipment_name: "修改货件名称",
    save_and_next: "保存并下一步",
    agree_on_the_difference: "同意自动支付因数量差异导致的服务费用差额",
    please_agree_to_authorize_automatic_deduction: "请同意授权自动扣款",
    cancel_task: "取消任务",
    quantity_of_finished_products: "完成产品数量",
    box_information: "箱信息",
    select_box: "选择箱",
    please_select_box: "请选择箱",
    replacement_box_label: "更换箱标签",
    the_box_label_needs_to_be_greater_than_p0: "箱标签数量需大于{0}",
    cancel_all_of_them: "全部取消",
    box_label_or_product_information_not_empty: "请上传箱标或者填写产品信息",
    temporarily_unavailable: "暂不可用",
    add_by_the_product: "按产品添加",
    quick_import: "快速导入",
    print_product_labels: "打印产品标签",
    create_transport_plan_in_cws: "在赛易物流系统中创建运输计划",
    process_other_warehousing_plans: "处理其他入库计划",
    product_label_processing: "产品标签处理",
    box_list: "箱列表",
    upload_product_labels: "上传产品标签",
    survey_information: "调查信息",
    out_warehouse_info: "出库信息",
    improve_product_data:"完善产品数据",
    export_products: "导出产品",
    shipment_name_or_shipment_number:"货件名称或货件号",
    case_no_or_scannable_case_no:"箱号或可扫描箱号",
    box_id_or_scannable_box_number:"箱ID或可扫描箱号",
    transport_planning_requires_complete_product_information: "运输计划需要完整的产品信息",
    product_already_exists:"产品已存在",
    please_complete_all_exception_handling:"请完成所有的异常处理",
    set_packing_plan:"设置装箱方案",
    confirm_transfer_plan:"确认调拨计划",
    add_box_scheme:"添加箱方案",
    verify_available_packaging_materials:"验算可用的包装材料",
    do_not_choose_packaging_materials:"不选择包装材料",
    the_product_was_not_found_in_warehouse:"仓库中未找到该产品",
    set_the_number_of_boxes_according_to_this_plan:"按照该方案设置箱数",
    the_modified_number_must_be_less_than_or_equal_to_p0:"修改的数字必须小于等于{0}",
    modified_number_tip:"被修改的数字要小于等于 “添加箱” 时填好的装箱数量",
    unlabeled_service:"无标签服务",
    product_information_summary:"产品信息汇总",
    please_add_a_box_first:"请先添加箱",
    please_save_the_packaging_quantity_or_label_service_being_modified_first:"请先保存正在修改的装箱数量或标签服务",
    the_product_with_SESKU_p0_exceeds_available_inventory_please_modify_the_quantity:"SESKU为{0}的产品超出可用库存,请修改数量",
    track_info:"轨迹信息",
    container_no: "集装箱编号",
    batch_import_of_waybills:"批量导入运单",
    waybill_info:"运单信息",
    box_data_error:"箱数据错误",
    order_placed: "已下单",
    select_product:"选择产品",
    batch_upload_of_container_labels:"批量上传箱标",
    export_packing_list:"导出装箱单",
    export_box_number_list: "导出箱号列表",
    confirm_and_create_transportation_plan: "确认并创建运输计划",
    transportation_measurement_info:"运输测量信息",
    declare:"报关",
    mexican_tax_reporting_news_1:"应墨西哥税务管理局(SAT)要求，墨西哥本土运输需要申报包裹中所有产品的价格及数量。",
    mexican_tax_reporting_news_2:"操作: 产品管理-编辑产品-填写申报价格",
    products_that_need_to_be_updated:"需更新的产品",
    extra_tags:"额外标签",
    source_related_doc_no: "来源相关单号",
    target_related_doc_no: "目标相关单号",
    product_import_error_prompt:"产品信息有误,请点击'+'查看详情",
    total_inbound_receipt_quantity: "总入库数量",
    please_enter_the_container_number: "请输入箱号",
    wrapping_film: "缠膜",
    wrapping_film_fee: "缠膜费用",
    not_supported: "不支持",
    not_supported_wrapping_film: "包装方式不支持缠膜",
    only_supports_up_to_p0_single_products:"仅支持{0}个以下的单一产品",
    supports_up_to_p0_single_products:"最多支持{0}个单一产品",
    the_postal_code_you_entered_does_not_match:"您输入的邮编与州/省不匹配，请检查邮编或州/省的正确性。",
    the_correctness_of_the_postal_code_you_entered_is_unknown:"您输入的邮编正确性未知，如果确认邮编无误，请选择继续。",
    store_name: "店铺名称",
    cancel_destruction: "申请取消销毁",
    cancel_destruction_processing: "取消销毁- 处理中",
    cancel_destruction_succeed: "取消销毁- 成功",
    cancel_destruction_unsuccessful: "取消销毁- 失败",
    destroy_order: "销毁订单",
    do_you_want_to_continue_with_products_with_unselected_labels:"有未选择标签的产品,是否继续",
    no_return_temporary_storage_fee_within_p0_days: "{0}日内免退货暂存费",
    return_temporary_storage_fee_will_be_calculated_after_p0_days: "{0}日后将计算退货暂存费",
    return_information_that_does_not_match_for_more_than_p0_days_can_be_destroyed: "超过{0}日无匹配的退货信息仓库有权销毁",
    warehouse_not_received_goods:"仓库未收到货",
    warehouse_temporarily_stored_p0_days: "仓库已暂存{0}天",
    add_the_customer_information_of_the_agent: "添加代理的客户信息",
    edit_the_customer_information_of_the_agent: "修改代理的客户信息",
    transit_out_plan_list:"中转仓出库计划",
    transit_out_plan_detail:"中转仓出库计划详情",
  },
};
