<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.transit_shipment")
    }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input-group compact style="width: 300px">
            <a-select
              v-model:value="searchTypeState.searchKeyType"
              :placeholder="$t('')"
              style="width: 40%"
            >
              <a-select-option
                v-for="(item, index) in searchKeyList"
                :key="index"
                :value="item.key"
                :title="$t(item.title)"
                >{{ $t(item.title) }}</a-select-option
              >
            </a-select>
            <a-input
              v-model:value="searchTypeState.searchKey"
              :placeholder="$t('')"
              style="width: 60%"
              allow-clear
            ></a-input>
          </a-input-group>
        </a-col>
        <!-- 是否是代理客户 -->
        <a-col v-if="isAgentUser">
          <a-input v-model:value="searchState.representedShop" :placeholder="$t('account.customer_number') + '/' + $t('account.nick_name')" style="width: 250px;" allow-clear></a-input>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.transitShipmentStatus"
            :placeholder="$t('warehouse.status')"
            style="width: 250px"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in transitShipmentStatusEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('transitShipmentStatus', item))"
              >{{
                $t($enumLangkey("transitShipmentStatus", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-input-group compact style="width: 350px">
            <a-select
              v-model:value="searchTypeState.searchDateType"
              :placeholder="$t('')"
              style="width: 40%"
            >
              <a-select-option
                v-for="(item, index) in searchDateKeyList"
                :key="index"
                :value="item.key.join(',')"
                :title="$t(item.title)"
                >{{ $t(item.title) }}</a-select-option
              >
            </a-select>
            <a-range-picker
              style="width: 60%"
              allow-clear
              v-model:value="searchTypeState.searchDate"
            ></a-range-picker>
          </a-input-group>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>
              <span>{{ $t('warehouse.date_of_reservation') }}:</span>
            </a-col>
            <a-col>
              <a-input-group compact style="width: 350px">
                <a-select
                  v-model:value="searchState.warehouseId"
                  :placeholder="$t('warehouse.target_warehouse')"
                  allow-clear
                  :showSearch="true"
                  optionFilterProp="search-key"
                  style="width: 40%"
                  @change="handleWarehouseChange()"
                >
                  <a-select-option
                    v-for="(item, key) in warehouseList"
                    :key="key"
                    :value="item.id"
                    :title="`${item.warehouseNo}(${item.warehouseName})`"
                    :search-key="item.warehouseNo + item.warehouseName"
                  >
                    {{ item.warehouseNo }}({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
                <a-range-picker
                  style="width: 60%"
                  allow-clear
                  :disabled="!searchState.warehouseId"
                  v-model:value="searchTypeState.scheduleDeliveryTime"
                ></a-range-picker>
              </a-input-group>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tableData.loading"
            @click="handleSearch"
            >{{ $t("common.search") }}</a-button
          >
        </a-col>
      </a-row>
      <a-table
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :row-key="(record) => record.id"
        :loading="tableData.loading"
        class="mt-3"
      >
        <template #shipmentTitle>
          <div>{{ $t("warehouse.shipment_name") }}</div>
          <div>{{ $t("warehouse.se_shipment_number") }}</div>
        </template>
        <template #boxesTitle>
          <div>{{ $t("warehouse.in_warehouse_box_count") }}</div>
          <div>{{ $t("warehouse.total_box_count") }}</div>
        </template>
        <template #deliveryTimeTitle>
          <div class="mr-2">{{ $t("warehouse.date_of_reservation") }}</div>
          <a-tag color="warning">{{ $t("warehouse.local_time") }}</a-tag>
        </template>
        <template #warehouseNo="{ record }">
          {{ record.warehouseNo || '-' }}
        </template>
        <template #shipmentCustomer="{ record }">
          <div>{{ record.name }}</div>
          <div>{{ record.seShipmentNo }}</div>
        </template>
        <template #boxesCustomer="{ record }">
          {{
            $filters.countProgressFormat(
              record.inWarehouseCompleteCount,
              record.inWarehousePlanTotalCount
            )
          }}
        </template>
        <template #transitShipmentStatus="{ record }">
          <span v-if="record.transitShipmentStatus">{{
            $t(
              $enumLangkey(
                "transitShipmentStatus",
                record.transitShipmentStatus
              )
            )
          }}</span>
          <span v-else>-</span>
        </template>
        <template #representedShop="{ record }">
          <template v-if="record.representedShop">
            <div>{{ record.representedShop.shopName || '-' }}</div>
            <div>{{ record.representedShop.shopNo || '-' }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #inPlanNo="{ record }">
          <CPlanNumberItem :no="record.inPlanNo" />
        </template>
        <template #inBoundTime="{ record }">
          {{ $filters.utcToCurrentTime(record.inBoundTime) }}
        </template>
        <template #outPlanNo="{ record }">
          <CPlanNumberItem :no="record.outPlanNo" />
        </template>
        <template #destinationType="{ record }">
          <span v-if="record.destinationType">{{
            $t($enumLangkey("destinationType", record.destinationType))
          }}</span>
          <span v-else>-</span>
        </template>
        <template #outboundDestinationCustomer="{ record }">
          <span v-if="record.transitBoundPlanOutAddress">
            <div>
              {{ record.transitBoundPlanOutAddress?.linkMan }}
              <span v-if="record.transitBoundPlanOutAddress?.telCode">
               +{{
                record.transitBoundPlanOutAddress?.telCode
              }}
              </span>
              {{ record.transitBoundPlanOutAddress?.linkManPhone }}
            </div>
            <div>
              {{ getLanguageAddress(record.transitBoundPlanOutAddress) }}
            </div>
          </span>
          <span v-else>-</span>
        </template>
        <template #scheduleDeliveryTime="{ record }">
          <template v-if="record.transitDeliveryMethod === transitDeliveryMethodEnum.takeTheir">
            {{
              $filters.utcToSpecificTime(
                record.scheduleDeliveryTime,
                record.warehouseTimezone
              )
            }}
          </template>
          <template v-else>
            <template v-if="record.scheduleDeliveryType">
              <span
                v-if="
                  record.scheduleDeliveryType === scheduleDeliveryTypeEnum.anytime
                "
                >{{
                  $t(
                    $enumLangkey(
                      "scheduleDeliveryType",
                      scheduleDeliveryTypeEnum.anytime
                    )
                  )
                }}</span
              >
              <div
                v-else-if="
                  record.scheduleDeliveryType === scheduleDeliveryTypeEnum.date
                "
              >
                {{
                  $filters.utcToSpecificTime(
                    record.scheduleDeliveryTime,
                    record.warehouseTimezone,
                    "yyyy-MM-DD"
                  )
                }}
              </div>
              <div
                v-else-if="
                  record.scheduleDeliveryType ===
                  scheduleDeliveryTypeEnum.dateTime
                "
              >
                {{
                  $filters.utcToSpecificTime(
                    record.scheduleDeliveryTime,
                    record.warehouseTimezone
                  )
                }}
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <div>
          <ScheduleTimeTypeTag
            v-if="record.scheduleTimeType"
            :type="record.scheduleTimeType"
          />
          </div>
        </template>
        <template #operation="{ record }">
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item
                  key="1"
                  :record="record"
                  :disabled="!record.inPlanId"
                  >{{ $t("warehouse.check_in_warehouse") }}</a-menu-item
                >
                <a-menu-item
                  key="2"
                  :record="record"
                  :disabled="!record.outPlanId"
                  >{{ $t("warehouse.check_out_warehouse") }}</a-menu-item
                >
              </a-menu>
            </template>
            <a-button>
              {{ $t("common.operation") }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Table,
  Tag,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import ScheduleTimeTypeTag from "@/views/components/ScheduleTimeTypeTag.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { currentTimeToUtc, getAddressByLanguageV2 } from "@/utils/general";
import { getWarehouses } from "@/api/modules/common/index.js";
import { getTransitShipmentListPaged } from "@/api/modules/transit/index.js";
import {
  warehouseType as warehouseTypeEnum,
  transitShipmentStatus as transitShipmentStatusEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
  transitDeliveryMethod as transitDeliveryMethodEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "transit_shipment",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ATag: Tag,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    ScheduleTimeTypeTag,
    CPlanNumberItem,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();

    const searchKeyList = [
      {
        key: "name",
        title: "warehouse.shipment_name",
      },
      {
        key: "seShipmentNo",
        title: "warehouse.se_shipment_number",
      },
      {
        key: "inPlanNo",
        title: "warehouse.in_warehouse_reservation_no",
      },
      {
        key: "outPlanNo",
        title: "warehouse.outbound_appointment_number",
      },
    ];

    const searchDateKeyList = [
      {
        key: ["inBoundTimeBegin", "inBoundTimeEnd"],
        title: "warehouse.inbound_date",
        timezone: 'timezone',
      },
      {
        key: ["outBoundTimeBegin", "outBoundTimeEnd"],
        title: "warehouse.outbound_date",
        timezone: 'timezone',
      },
    ];

    const state = reactive({
      isAgentUser: getters.userInfo?.isAgent ?? false,
      searchTypeState: {
        searchKeyType: searchKeyList[0].key,
        searchKey: null,
        searchDateType: searchDateKeyList[0].key.join(","),
        searchDate: [],
        scheduleDeliveryTime: [],
      },
      searchState: {
        name: null,
        seShipmentNo: null,
        inPlanNo: null,
        outPlanNo: null,
        transitShipmentStatus: null,
        inBoundTimeBegin: null,
        inBoundTimeEnd: null,
        scheduleDeliveryTimeBegin: null,
        scheduleDeliveryTimeEnd: null,
        outBoundTimeBegin: null,
        outBoundTimeEnd: null,
        // 是否需要目的地址
        isNeedOutAddress: true,
        // 是否需要仓库
        isNeedWarehouse: true,
        // 客户编号/名称
        representedShop: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      warehouseList: [],
    });

    const beforeColumns = [
      {
        width: 120,
        title: () => vueI18n.t("warehouse.warehouse_code"),
        slots: {
          customRender: "warehouseNo",
        },
      },
      {
        width: 150,
        slots: {
          title: "shipmentTitle",
          customRender: "shipmentCustomer",
        },
      },
    {
        width: 100,
        slots: {
          title: "boxesTitle",
          customRender: "boxesCustomer",
        },
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        width: 100,
        slots: {
          customRender: "transitShipmentStatus",
        },
      },
    ];

    const afterColumns = [
      {
        title: () => vueI18n.t("warehouse.in_warehouse_reservation_no"),
        width: 150,
        slots: {
          customRender: "inPlanNo",
        },
      },
      {
        title: () => vueI18n.t("warehouse.inbound_date"),
        width: 150,
        slots: {
          customRender: "inBoundTime",
        },
      },
      {
        title: () => vueI18n.t("warehouse.out_warehouse_plan_no"),
        width: 150,
        slots: {
          customRender: "outPlanNo",
        },
      },
      {
        title: () => vueI18n.t("warehouse.out_warehouse_type"),
        width: 150,
        slots: {
          customRender: "destinationType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.outbound_destination"),
        width: 200,
        slots: {
          customRender: "outboundDestinationCustomer",
        },
      },
      {
        width: 150,
        slots: {
          title: "deliveryTimeTitle",
          customRender: "scheduleDeliveryTime",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const columns = computed(() => {
      if (getters.userInfo?.isAgent) {
        return [
          ...beforeColumns,
          {
            width: 120,
            title: () => vueI18n.t("account.customer"),
            slots: {
              customRender: "representedShop",
            },
          },
          ...afterColumns,
        ];
      } else {
        return [
          ...beforeColumns,
          ...afterColumns,
        ];
      }
    });

    const getLanguageAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleWarehouseChange = () => {
      if (!state.searchState.warehouseId) {
        state.searchTypeState.scheduleDeliveryTime = [];
      }
    };

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case "1":
          // 查看入库计划
          router.push("/transit/inplan/detail/" + record.inPlanId);
          break;
        case "2":
          // 查看出库计划
          router.push("/transit/outplan/detail/" + record.outPlanId);
          break;
        default:
          break;
      }
    };

    const getPageList = () => {
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      state.tableData.loading = true;
      getTransitShipmentListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      for (let i = 0; i < searchKeyList.length; i++) {
        let key = searchKeyList[i].key;
        if (Object.hasOwnProperty.call(state.searchState, key)) {
          if (key === state.searchTypeState.searchKeyType) {
            state.searchState[key] = state.searchTypeState.searchKey;
          } else {
            state.searchState[key] = null;
          }
        }
      }

      let userInfo = getters.userInfo;
      for (let i = 0; i < searchDateKeyList.length; i++) {
        let keys = searchDateKeyList[i].key;
        let timezone = searchDateKeyList[i].timezone;
        let searchDate = state.searchTypeState.searchDate;
        if (
          keys.join(",") === state.searchTypeState.searchDateType &&
          searchDate.length === 2
        ) {
          if (Object.hasOwnProperty.call(state.searchState, keys[0])) {
            state.searchState[keys[0]] = currentTimeToUtc(
              searchDate[0]?.format("YYYY-MM-DD 00:00:00"),
              userInfo[timezone],
            );
          }
          if (Object.hasOwnProperty.call(state.searchState, keys[1])) {
            state.searchState[keys[1]] = currentTimeToUtc(
              searchDate[1]?.format("YYYY-MM-DD 00:00:00"),
              userInfo[timezone],
            );
          }
        } else {
          if (Object.hasOwnProperty.call(state.searchState, keys[0])) {
            state.searchState[keys[0]] = null;
          }
          if (Object.hasOwnProperty.call(state.searchState, keys[1])) {
            state.searchState[keys[1]] = null;
          }
        }
      }

      // 判断预约时间
      if (state.searchState.warehouseId && state.searchTypeState.scheduleDeliveryTime.length === 2) {
        let timezone = state.warehouseList.find(item => item.id === state.searchState.warehouseId).timezone;
        state.searchState.scheduleDeliveryTimeBegin = currentTimeToUtc(
          state.searchTypeState.scheduleDeliveryTime[0].format("YYYY-MM-DD 00:00:00"),
          timezone
        );
        state.searchState.scheduleDeliveryTimeEnd = currentTimeToUtc(
          state.searchTypeState.scheduleDeliveryTime[1].format("YYYY-MM-DD 00:00:00"),
          timezone
        );
      } else {
        state.searchState.scheduleDeliveryTimeBegin = null;
        state.searchState.scheduleDeliveryTimeEnd = null;
      }

      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      getWarehouses({ isActive: true, warehouseType: warehouseTypeEnum.transitWarehouse }).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result
        } else {
          state.warehouseList = []
        }
      }).catch()
    }

    onMounted(() => {
      handleSearch()
      getWarehouseList()
    });

    return {
      ...toRefs(state),
      warehouseTypeEnum,
      transitShipmentStatusEnum,
      scheduleDeliveryTypeEnum,
      transitDeliveryMethodEnum,
      searchKeyList,
      searchDateKeyList,
      columns,
      getLanguageAddress,
      handleWarehouseChange,
      handleMenuClick,
      handleSearch,
      handlePage,
    };
  },
});
</script>
<style scoped></style>
