<template>

  <Content>
    <template #contentTitle>
      {{ $t("logistics.transport_plan_details") }}
    </template>
    <template #contentBody>
      <a-spin size="large"
              :spinning="pageLoading">
        <!-- 仓库信息 -->
        <div class=" mb-3">
          <a-row :gutter="[0,12]">
            <a-col :span="24">
              <a-row :gutter="[0,12]">
                <a-col :span="6">
                  <label>{{$t('logistics.transport_plan_name')}}: </label>
                  {{plan.name}}
                </a-col>
                <a-col :span="4">
                  <label>{{$t('warehouse.creation_time')}}: </label>
                  {{$filters.utcToCurrentTime(plan.creationTime)}}
                </a-col>
                <a-col :span="14">
                  <label>{{$t('warehouse.update_time')}}: </label>
                  {{$filters.utcToCurrentTime(plan.updateTime)}}
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="[0,12]">
                <a-col :span="24">
                  <label>{{$t('warehouse.transportation_plan_number')}}: </label>
                  {{plan.no}}
                  <a-button v-if="plan.status !== transportStatusEnum.canceled"
                            size="small"
                            class="ml-5"
                            :loading="printInWarehourseorderLoading"
                            @click="handlePrintInWarehourseorder">{{$t('common.print_in_warehouse_order')}}</a-button>
                </a-col>
                <a-col :span="24">
                  <a-row>
                    <a-col :span="12"><label>{{$t('warehouse.source_related_doc_no')}}: </label>
                      <CPlanNumberItem :no="plan.inWarehouseNo || plan.outWarehouseNo"></CPlanNumberItem>
                    </a-col>
                    <a-col><label>{{$t('warehouse.target_related_doc_no')}}: </label>
                      <CPlanNumberItem  :no="plan.toInWarehouseNo"></CPlanNumberItem>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="12">
                  <label>{{$t('logistics.destination_type')}}: </label>
                  {{$t($enumLangkey('destinationType',plan.destinationType))}}
                </a-col>
                
                <a-col v-if="plan.destinationType==destinationTypeEnum.FBA" :span="12">
                  <label>{{$t('logistics.fba_warehouse_code')}}: </label>
                  {{plan.toWarehouseCode}}
                </a-col>
                <a-col v-else-if="plan.destinationType==destinationTypeEnum.mercadolibre" :span="12">
                  <label>{{$t('warehouse.fbm_warehouse_code')}}: </label>
                  {{plan.toWarehouseCode}}
                </a-col>
                <a-col v-else-if="plan.destinationType==destinationTypeEnum.SeWarehouse" :span="12">
                  <label>{{$t('logistics.warehouse_code')}}: </label>
                  {{plan.toWarehouseCode}}
                </a-col>
                <a-col v-else-if="plan.destinationType==destinationTypeEnum.OtherAddress" :span="12">
                  <label>{{ $t('warehouse.destination') }}:</label>
                  {{ plan.toNikeName || '-' }}
                </a-col>

                <a-col :span="24">
                  <a-row>
                    <a-col :span="12"><label>{{$t('logistics.recipients')}}: </label>{{plan.toName ?? '-'}}</a-col>
                    <a-col><label>{{$t('logistics.contact_number')}}: </label>
                      <span v-if="plan.toPhone&&plan.toPhone?.trim()?.length>0">{{plan.toPhone}}</span>
                      <span v-else> -</span>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="[0,10]" style="text-align: center;">
                <a-col :span="24">
                  <a-typography-text strong>{{ $t('warehouse.status') }}</a-typography-text>
                </a-col>
                <a-col :span="24">
                  <a-space>
                    <a-typography-text type="success">
                      {{$t($enumLangkey('transportStatus', plan.status))}}
                    </a-typography-text>
                    <div v-if="plan.isPayOnArrival && plan.logisticsNode">
                      <a-tag color="blue" v-if="plan.logisticsNode.accountingPeriodCalculationWay === logisticsAccountingPeriodCalculationWayEnum.nextMonth">
                        {{ $t($enumLangkey("logisticsNode", plan.logisticsNode.logisticsNode)) }}, {{ $t("logistics.next_month") }}, {{ plan.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}
                      </a-tag>
                      <a-tag color="blue" v-else>{{ $t($enumLangkey("logisticsNode", plan.logisticsNode.logisticsNode)) }}, {{ plan.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}</a-tag>
                    </div>
                  </a-space>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="[16, 8]" type="flex" justify="center" class="mt-3">
                    <a-col v-if="
                      logistics.logisticsType !=logisticsWayEnum.commercialExpress &&
                      plan.status >= transportStatusEnum.inTransit && plan.status <= transportStatusEnum.signed"
                    >
                      <a-button  @click="handleShowModalTrackingTrajectory">{{ $t('warehouse.track_info') }}</a-button>
                    </a-col>
                    <a-col v-if="plan.status >= transportStatusEnum.hasDelivered && plan.status <= transportStatusEnum.signed">
                      <a-button @click="handleShowTrackModal">{{ $t('warehouse.tracking_shipment') }}</a-button>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row type="flex" justify="center">
                <a-col>
                  <PaymentCountdown
                    v-if="plan.status === transportStatusEnum.awaitPay"
                    :value="plan.countdownPaidSeconds"
                    @finish="funcSearchPlanInfo(plan.id)"
                  />
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="24">
              <label>{{$t('logistics.shipping_address')}}: </label>
              {{getAddress(plan.toAddressData)}}
              <span class="ml-5"
                    v-if="plan.toAddressData && plan.destinationType == destinationTypeEnum.SeWarehouse">{{ $t('logistics.contacts') }}: {{getLinkman(plan.toAddressData)}}</span>
            </a-col>
            <a-col :span="6">
              <label>{{$t('logistics.originate')}}: </label>
              {{plan.fromAddressData.fromName}}
            </a-col>
            <a-col :span="18">
              <label>{{$t('logistics.shipments_address')}}: </label>
              {{getAddress(plan.fromAddressData)}}
              <a-tag color="warning"
                     v-if="plan.toAddressData.isInternationalTransport"> {{$t('warehouse.international_transport')}}</a-tag>
              <a-tag color="warning"
                     v-else> {{$t('logistics.local_transport')}}</a-tag>
              <a-tag color="default"
                     class="ml-3"
                     v-if="plan.isDoorTook"
                     style="min-height:32px;">
                <a-row class="mt-1">
                  <a-col>
                    <a-tag color="success">{{$t('logistics.door_took')}}</a-tag>
                  </a-col>
                  <a-col class="ml-3">{{$t('logistics.contacts')}}: {{plan.doorLinkUserName}}</a-col>
                  <a-col class="ml-3 mr-2">{{$t('logistics.contact_number')}}: {{plan.doorContactPhone}}</a-col>
                </a-row>
              </a-tag>
            </a-col>
            <a-col :span="24">
              <label>
                {{$t('logistics.logistics_center_harvest_address')}}
                <c-tooltip :title="$t('logistics.logistics_select_hint')" />:
              </label>
              ({{plan?.seLogisticsNo}})
              {{getAddress(plan.seLogisticsAddress)}} 
              <span v-if="plan.seLogisticsAddress && plan.seLogisticsAddress.linkUserName">({{ getLinkUser(plan.seLogisticsAddress) }})</span>
            </a-col>

            <template v-if="plan.destinationType==destinationTypeEnum.FBA">
              <a-col :span="6">
                <label>{{$t('warehouse.amazon_shipping_number')}}: </label>
                {{plan.fbaTransportNo}}
              </a-col>
              <a-col :span="18">
                <label>{{$t('warehouse.shipment_tracking_number')}}: </label>
                {{plan.productTrackNo}}
              </a-col>
            </template>
            <template v-else-if="plan.destinationType==destinationTypeEnum.mercadolibre">
              <a-col :span="6">
                <label>{{$t('warehouse.seller_id')}}: </label>
                {{plan.fbaTransportNo}}
              </a-col>
              <a-col :span="18">
                <label>{{$t('warehouse.warehousing_number')}}: </label>
                {{plan.productTrackNo}}
              </a-col>
            </template>
            <a-col :span="24" v-if="outForDelivery && outForDelivery.endJourneyType">
              <span>{{ $t("logistics.final_delivery_info") }}: </span>
              <a-tag color="blue">{{ $t($enumLangkey('transportPlanEndJourneyType', outForDelivery.endJourneyType)) }}</a-tag>
              <template v-if="outForDelivery.endJourneyType === transportPlanEndJourneyTypeEnum.express">
                <span>{{ $t('logistics.carrier') }}: {{ outForDelivery.express.expressCompanyName }}</span>
                <span>, {{ $t('public.tracking_number') }}: {{ outForDelivery.express.expressNo }}</span>
              </template>
              <template v-if="outForDelivery.endJourneyType === transportPlanEndJourneyTypeEnum.transportationCompany">
                <span>{{ $t('logistics.carrier') }}: {{ outForDelivery.transportationCompany.companyName }}</span>
                <span v-if="outForDelivery.transportationCompany.phone">(+{{ outForDelivery.transportationCompany.telCode }} {{ outForDelivery.transportationCompany.phone }})</span>
                <span>, {{ $t('public.tracking_number') }}: {{ outForDelivery.transportationCompany.trackingNumber }}</span>
              </template>
            </a-col>
          </a-row>
        </div>
        <!-- 箱表格 -->
        <div>
          <a-row justify="end" :gutter="[24,12]" class="mb-1">
            <a-col v-if="plan.status != transportStatusEnum.canceled">
              <ExportBoxListBtn v-if="plan.id" :plan-id="plan.id" :plan-no="plan.no" />
            </a-col>
            <a-col v-if="plan.status>=transportStatusEnum.accomplishConsoleReceived && plan.status!=transportStatusEnum.canceled">
              <a-button type="primary" ghost @click="handleExportPackingList" :loading="exportPackingListLoading">{{ $t('warehouse.export_packing_list') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" ghost @click="handleShowProductStatisticsModal">{{ $t('common.product_statistics') }}</a-button>
            </a-col>
          </a-row>
          <a-table 
            style="overflow-x: auto; min-height:223px;"
            :columns="plan.boxTableColumns"
            :data-source="plan.producTableList"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize: 50,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            @change='(page) => {currentPageIndex = page.current;}'
          >
            <template #encasementCount>
              <span>
                {{ $t("warehouse.encasement_count") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_1')" />
              </span>
            </template>
            <template #encasementInfo>
              <span>
                {{ $t("warehouse.encasement_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_2')" />
              </span>
            </template>
            <template #transportInfo>
              <span>
                {{ $t("warehouse.transportation_measurement_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_3')" />
              </span>
            </template>
            <template #boxNo="{ record }">
              <div :style="record.isCancel ? 'color: #CCCCCC;' : ''">
                {{ record.boxNo }}
                <CTagMixedOrSingle :productKindCount="record.productKindCount * 1"></CTagMixedOrSingle>
              </div>
              <div><small>{{ record.selfBoxNo || '-' }}</small></div>
              <div v-if="record.isCancel">
                <a-tag color="red">{{ $t('logistics.canceled') }}</a-tag>
              </div>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation :open="expanded" @click="e => onExpand(record, e)" />
            </template>

            <template #encasementCountCustom="{record }">
              {{record.containerCount}} Unit
            </template>

            <template #encasementInfoCustom="{record }">
              <span v-if="!record.packBoxInfo||record.packBoxInfo.length<= 0||record.packBoxInfo.width<= 0||record.packBoxInfo.height<= 0">
                -
              </span>
              <span v-else>
                {{ record.packBoxInfo.length }}x{{ record.packBoxInfo.width }}x{{ record.packBoxInfo.height }}cm
              </span>
              <div>
                <span v-if="record.packBoxInfo.weight">{{ gToKg(record.packBoxInfo.weight) }}kg</span>
                <span v-else>-</span>
              </div>
            </template>

            <template #transportInfoCustom="{record }">
              <span v-if="!record.isCancel">
                <span v-if="!record.transportBoxInfo||record.transportBoxInfo.length<= 0||record.transportBoxInfo.width<= 0||record.transportBoxInfo.height<= 0">
                  -
                </span>
                <span v-else>
                  {{ record.transportBoxInfo.length }}x{{ record.transportBoxInfo.width }}x{{ record.transportBoxInfo.height }}cm
                </span>
                <div>
                  <span v-if="record.transportBoxInfo.weight">{{ gToKg(record.transportBoxInfo.weight) }}kg</span>
                  <span v-else>-</span>
                </div>
              </span>
              <span v-else>-</span>
            </template>

            <template #trackingNo="{record }">
              <span v-if="!record.isCancel">{{record.logisticTrackNo??'-'}}</span>
              <span v-else>-</span>
            </template>

            <template #print>
              <span v-if="plan.status !== transportStatusEnum.canceled">
                <a-button :loading="bulkPrintLoading" @click="handleBulkPrint">{{$t('common.batch_print')}}</a-button>
              </span>
            </template>
            <template #printCustom="{ record }">
              <a-button v-if="!record.isCancel && plan.status !== transportStatusEnum.canceled" :loading="record.loading" @click="handlePrintBoxLabel(record)">
                <span v-if="plan.destinationType==destinationTypeEnum.SeWarehouse">
                  {{$t('common.se_outer_box_label')}}
                </span>
                <span v-else>
                  {{$t('common.print_box_label')}}
                </span>
              </a-button>
              <span v-else>-</span>
            </template>

            <template #collectTaskStatus="{ record }">
              <a-popover
                v-if="!record.isCancel && record.checkImgUrls && record.checkImgUrls?.length > 0 "
              >
                <template #content>
                  <a-row :gutter="[8, 8]">
                    <a-col
                      v-for="(item, index) in record.checkImgUrls"
                      :key="index"
                    >
                      <div class="table-list-img-common">
                        <c-image :src="item" :dis-preview-process="true" />
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <span class="text-primary">
                  <FileImageOutlined style="font-size: 30px" />
                </span>
              </a-popover>
              <span v-else>-</span>
            </template>

            <template #expectArriveTime="{ record }">
              <a-row type="flex" justify="space-between" align="middle" v-if="!record.isCancel">
                <a-col>
                  <div>
                    {{ $t("logistics.billing_weight") }}:
                    <span v-if="record.chargeableWeight"
                      >{{ gToKg(record.chargeableWeight) }}kg</span
                    >
                    <span v-else>-</span>
                  </div>
                  <div>
                    {{ $t("logistics.billing_volume") }}:
                    <span v-if="record.chargeableVolume"
                      >{{ cmCubicToM(record.chargeableVolume) }}m³</span
                    >
                    <span v-else>-</span>
                  </div>
                </a-col>
                <a-col>
                  <a-tag color="#f50">
                    <template v-if="record.logisticsCalcMethod === logisticsCalcMethodEnum.weight">
                      {{ record.isVolumeWeight ? $t('logistics.volume_weight') : $t('logistics.actual_weight') }}
                    </template>
                    <template v-else>
                      {{ record.isDensityVolume ? $t('logistics.density_volume') : $t('logistics.actual_volume') }}
                    </template>
                  </a-tag>
                </a-col>
              </a-row>
              <span v-else>-</span>
            </template>

            <template #footer>
              <a-row type="flex"
                     justify="start"
                     class="mr-5"
                     :gutter="32">
                <a-col>{{$t('common.total')}}: {{plan.boxTotalCount}}{{$t('warehouse.box')}}</a-col>
                <a-col v-if="plan.transportTotalVolume>0">{{$t('warehouse.total_volume_of_transport')}}: {{plan.transportTotalVolume}}m<sup>3</sup></a-col>
                <a-col v-if="plan.transportTotalWeight>0">{{$t('warehouse.gross_shipping_weight')}}: {{plan.transportTotalWeight}}kg</a-col>
                <a-col>{{$t('warehouse.total_volume')}}: {{plan.totalContainerVolume}}m<sup>3</sup></a-col>
                <a-col>{{$t('warehouse.total_encasement_weight')}}: {{plan.totalContainerWeight}}kg</a-col>
                <a-col>
                  {{$t('warehouse.transport_characteristics')}}:
                  <a-tag color="default"
                         v-for="item in plan.transportTotalCharacteristic"
                         :key="item">
                    {{$t($enumLangkey('transportCharacteristics',item))}}
                  </a-tag>
                </a-col>
              </a-row>
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="plan.innerColumns"
                :data-source="record.productInfos"
                :pagination="false"
                :row-key="(record) => record.productId"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.productName }}</div>
                      <div>{{ record.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #containerCount="{ record }">
                  {{ record.containerCount }} Unit
                </template>
                <template #restsCustom="{record }">
                  <a-row 
                         v-if="!record.isCancel">
                    <a-col :span="24">
                        <a-row type="flex"
                        justify="space-between">
                            <a-col :span="12">
                          {{$t('warehouse.chinese_trade_name')}}: {{record.customsInfo.brandCName}}
                        </a-col>
                        <a-col :span="12">
                          {{$t('warehouse.english_trade_name')}}: {{record.customsInfo.brandEnName}}
                        </a-col>
                        </a-row>
                    </a-col>

                    <a-col :span="24">
                      <a-row type="flex"
                             justify="space-between">
                        <a-col :span="12">
                      HS Code: {{record.customsInfo.hsCode}}
                        </a-col>
                        <a-col :span="12">
                          {{$t('warehouse.declare_price')}}: {{record.customsInfo.currencySymbol}}{{$filters.amountToFixed2(record.customsInfo.totalPrice)}}
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </template>
          </a-table>
        </div>
        <!-- 清关信息汇总 -->
        <div v-if="plan.toAddressData.isInternationalTransport">
          <p><strong>{{$t('logistics.summary_customs_clearance_information')}}</strong></p>
          <a-table :columns="customsInfoColumns"
                   :data-source="customsInfo"
                   :scroll="{ x: 800}"
                   size="small"
                   :pagination="false">

            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
              </span>
            </template>

            <template #productNameAndSeSkuCustom="{ record }">
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </template>

            <template #tradeNameCustom="{record }">
              <div>
                {{ record.enTradeNameCustom}}
              </div>
              <div>
                {{ record.chTradeNameCustom}}
              </div>
            </template>

            <template #texture="{record }">
              <div>
                {{ record.enMaterial}}
              </div>
              <div>
                {{ record.cnMaterial}}
              </div>
            </template>

            <template #brand="{ record }">
              {{ record.brand || '-' }}
            </template>

            <template #purposeDescription="{ record }">
              <div>{{ record.purposeEnDescription || '-' }}</div>
              <div>{{ record.purposeCnDescription || '-' }}</div>
            </template>

            <template #applyMiddleRate="{ record }">
              {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.applyMiddleRate) }}
            </template>
            <template #subtotal="{ record }">
              {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.subtotal) }}
            </template>
            <template #footer>
              <a-row type="flex"
                     justify="end"
                     class="mr-5"
                     :gutter="32">
                <a-col>{{$t('common.total')}}:{{customsSymbol}}{{ $filters.amountToFixed2(customsTotalFee) }}</a-col>
              </a-row>
            </template>
          </a-table>
        </div>
        <!-- 物流方案 -->
        <div class="mt-3">
          <p><strong>{{$t('logistics.logistics_channel')}}</strong></p>
          <div class="mb-3">
            <a-table :columns="logistics.columnsLogisticsScheme"
                     :data-source="logistics.logisticsSchemeList"
                     :scroll="{ x:true}"
                     size="small"
                     :pagination="false">
              <template #logisticsScheme>
                <span>
                  {{ $t("warehouse.logistics_scheme") }} / {{ $t("warehouse.logistics_code") }}
                </span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("warehouse.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("warehouse.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("warehouse.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span v-if="!fee">
                  {{ $t("logistics.freight_forecast_no_audit") }}
                </span>
                <span v-else>
                  {{ $t("logistics.freight") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">{{record.scheme}} / {{record.code}}</div>
                <a-row :gutter=[8,8]>
                  <a-col v-for="item in record.descriptionItems"
                         :key="item">
                    <CheckCircleOutlined class="text-warning mr-1"
                                         :style="{fontSize: '12px'}" />
                    <span style="font-size: 12px; color: rgba(102,102,102,1)">
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{$t($enumLangkey('logisticsWay',record.logisticsWay))}}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{record.daysMin}}-{{record.daysMax}}
                  {{$t("warehouse.workday")}}
                  ({{$t($enumLangkey('aging',record.channelTimeType))}})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{$t('warehouse.average_unit_price')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.averagePrice)}}
                </div>
                <div class="text-warning">
                  {{$t('warehouse.total_prices')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.expressFee)}}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod == logisticsCalcMethodEnum.weight">
                  {{$t('logistics.chargeable_weight')}}: {{record.chargeableWeight}}kg
                </div>
                <div v-else>
                  {{$t('logistics.chargeable_volume')}}: {{record.chargeableVolume}}m<sup>3</sup>
                </div>
                <div>
                  {{$t('logistics.volume_weight_coefficient')}}{{record.volumeWeightParam}}
                </div>
              </template>
              <template #optionalServicesCustom="{  record}">
                <template v-if="record.logisticsRegionInfo.isChooseDdp || record.logisticsRegionInfo.isChooseSign">
                  <div v-if="record.logisticsRegionInfo.isChooseDdp">
                    DDP({{record.currencySymbol}}{{$filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)}})
                  </div>
                  <CSignSupported
                    v-if="record.logisticsRegionInfo.isChooseSign"
                    v-model:value="record.logisticsRegionInfo.signatureType"
                    type="readonly"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                  />
                </template>
                <span v-else>-</span>
              </template>
              <!-- 卡车专属 -->
              <template #quoteOptionsTitle="{ record }">
                {{ $t('logistics.quote_options') }}
                <CTooltip :title="$t('logistics.quote_options_tips')" />
              </template>
              <template #quoteOptions="{ record }">
                <a-row :gutter="[16, 8]">
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.quote_type') }}</a-col>
                      <a-col flex="1">
                        <span>{{ record.truckBaseInfo.quoteType?.name }}</span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24" v-if="record.truckBaseInfo.serviceLevel">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.service_level') }}</a-col>
                      <a-col flex="1">
                        <template>
                          <span>{{ record.truckBaseInfo.serviceLevel.name }}</span>
                          <span>-</span>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.additional_options') }}</a-col>
                      <a-col flex="1">
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                          </template>
                          <div style="max-width: 250px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                        </a-popover>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </template>
              <template #quoteDetails="{ record }">
                <span v-if="record.truckBaseInfo">{{ record.truckBaseInfo.carrierName }} [{{ $filters.formatCurrencyAmount(record.truckBaseInfo.currencySymbol, record.truckBaseInfo.totalCharge, false) }}, {{ handleParseTime(record.truckBaseInfo.transitTime) }} day(s)]</span>
                <span v-else>-</span>
              </template>
            </a-table>
            <div class="mt-2">
              <a-tag color="warning"
                     v-if="logistics.isRemoteArea">{{$t('logistics.remote_site')}}</a-tag>
              <template v-if="logistics.isCommercial!=null">
                <a-tag color="warning"
                       v-if="logistics.isCommercial">{{$t('logistics.business_site')}}</a-tag>
                <a-tag color="warning"
                       v-else>{{$t('logistics.residence')}}</a-tag>
              </template>
            </div>

            <a-form-item class="mt-3" v-show="logistics.isShowVATInput">
              <template #label>
                <div style="width: 150px;">
                  <label>VAT</label>
                </div>
              </template>
              {{ logistics.VAT }}
            </a-form-item>
          </div>
        </div>
        <div class="mt-3" v-if="plan.toAddressData.isInternationalTransport">
          <a-form-item>
            <template #label>
              <div style="width: 150px;">
                <label>{{ $t('logistics.purchase_insurance') }}</label>
              </div>
            </template>
            {{ plan.isInsure === true ? $t('common.necessary') : plan.isInsure === false ? $t('common.unnecessary') : '-' }}
          </a-form-item>
        </div>
        <!-- 报关方式 -->
        <div class="mt-5"
             v-if="logistics.isSelectedCustoms">
          <p><strong>{{$t('logistics.clearance_way')}}</strong>: {{$t('logistics.ordinary_trade_clearance')}}</p>
          <a-row :gutter=[32,8]>
            <a-col v-for="(item,index) in logistics.imgUrl"
                   :key="index">
              <div v-if="item.url">
                <div class="text-center">{{$t(item.name)}}</div>
                <div>
                  <CFileDisplay :fileUrl="item.url"></CFileDisplay>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 费用明细 -->
        <CFee
          v-if="fee"
          :fees="getCommonFeeList(fee?.fees)"
          :totalFee="{ symbol: fee?.currencySymbol, value: fee?.totalAmount }"
        />
        <!-- button 返回和支付modal -->
        <div class="mt-5 mr-5 mb-5 ">
          <a-row type="flex"
                 justify="end">
            <a-col class="ml-3"
                   v-if="isAllowCancel">
              <a-popconfirm :title="$t('common.are_you_sure_cancel')"
                            :ok-text="$t('common.confirm')"
                            :cancel-text="$t('common.cancel')"
                            @confirm="handleCancel">
                <a-button :loading="cancelBtnLoading">{{$t('common.cancel')}}</a-button>
              </a-popconfirm>
            </a-col>
            <a-col class="ml-3">
              <a-button @click="handleBacktrack">{{$t('common.backtrack')}}</a-button>
            </a-col>
            <a-col class="ml-3"
                   v-if="fee">
              <a-button :disabled="true"
                        v-if="plan.status >= transportStatusEnum.havePaid &&
                        plan.status <= transportStatusEnum.signed">
                {{$t('logistics.have_paid')}}
              </a-button>
              <template v-else-if="plan.status==transportStatusEnum.awaitPay">
               <a-button v-if="plan.isPayOnArrival"
                          type="primary"
                          :loading="payOnArrivalLoading"
                          @click="handleConfirm()">
                  {{ $t('common.confirm') }}
                </a-button>
                <a-button v-else
                          type="primary"
                          @click="handleShowOrdeModal">
                  {{$t('warehouse.confirm_and_pay')}}
                </a-button>
              </template>
            </a-col>
          </a-row>
        </div>

        <Payment ref="refPayment"
                 :orderNo="plan.no"
                 :currencyId="fee?.currencyId??''"
                 :unpaidSymbol="fee?.currencySymbol??''"
                 :unpaidSum="fee?.totalAmount??0"
                 :payModalTitleType="4"
                 @handlePayFee="handleOrderPay"
                 :loading="payModal.loading"
                 v-model:visible="payModal.visible"></Payment>

      </a-spin>
      <TraceModal ref="traceModalRef" />
      <CProductStatisticsModal ref="productStatisticsModalRef" :show-inbound="true" />
      <CPlanCanceledAfterModal ref="cPlanCanceledAfterModalRef" />

       <CTrackInfoModal 
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
       >
        <template v-slot:numberName>
          {{ $t("logistics.transportation_plan_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.no}}
        </template>
      </CTrackInfoModal>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row, Col, Tag, Typography,
  Table, Input, Select,
  Button, Form, Spin, message, Popover, Space, Popconfirm
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue"
import CTooltip from "@/views/components/CTooltip.vue"
import Payment from "@/views/components/Payment.vue"
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CProductStatisticsModal from '@/views/components/CProductStatisticsModal.vue';
import CFee from '@/views/components/CFee.vue';
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CPlanCanceledAfterModal from "@/views/components/CPlanCanceledAfterModal.vue";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CTagMixedOrSingle from "@/views/components/CTagMixedOrSingle.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import TraceModal from '../components/TraceModal.vue'
import PaymentCountdown from "./components/PaymentCountdown.vue";
import ExportBoxListBtn from "./components/ExportBoxListBtn.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useTab } from "@/hooks/tabs/index";
import { useStore } from "vuex";
import { gToKg, cmCubicToM, getLinkman, getLinkUser, getAddressByLanguageV2,dateString } from "@/utils/general"
import urlHelper from "@/utils/urlHelper";
import localPrint from "@/utils/localPrint"
import { getCurrencys } from "@/api/modules/common/index";
import {
  getTransportPlanDetails,
  payOrder,
  cancelTransportPlan,
  confirmPayOnArrival,
  exportPackingList
} from "@/api/modules/transportation/index";
import {
  batchPrintBoxOfPlanTransport,
  printBoxOfPlanTransport,
  printInWarehourseorder,
} from "@/api/modules/printUrl/pdf.js"
import {
  destinationType as destinationTypeEnum,
  transportStatus as transportStatusEnum,
  productStatisticsType as productStatisticsTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  warehouseType as warehouseTypeEnum,
  logisticsWay as logisticsWayEnum,
  logisticsAccountingPeriodCalculationWay as logisticsAccountingPeriodCalculationWayEnum,
  logisticsCalcMethod as logisticsCalcMethodEnum,
  transportPlanEndJourneyType as transportPlanEndJourneyTypeEnum,
} from "@/enum/enum.json";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import { getTrackList } from "@/api/modules/transportation/index";
import filters from "@/filters";
import { downloadFile } from "@/utils/downloader";

export default ({
  name: "transport_plan_details",
  components: {
    CTrackInfoModal,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ATypographyText: Typography.Text,
    APopover: Popover,
    ASpace: Space,
    APopconfirm: Popconfirm,
    Content,
    CFileDisplay,
    CPlanNumberItem,
    CImage,
    CTooltip,
    Payment,
    CProductStatisticsModal,
    CFee,
    CPlanCanceledAfterModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    CSignSupported,
    TraceModal,
    PaymentCountdown,
    ExportBoxListBtn,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const refPayment = ref(null);
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const traceModalRef = ref(null);
    const currentPageIndex = ref(1);
    const productStatisticsModalRef = ref();
    const cPlanCanceledAfterModalRef = ref();

    const boxTableColumns = [
      {
        dataIndex: "serialNumber",
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
      },
      {
        title: vueI18n.t('warehouse.box_no'),
        width: 150,
        slots: {
          customRender: 'boxNo'
        }
      },
      { width: 50, },
      {
        slots: {
          title: "encasementCount",
          customRender: "encasementCountCustom",
        },
        width: 100,
      },
      {
        slots: {
          title: "encasementInfo",
          customRender: "encasementInfoCustom",
        },
        width: 150,
      },
      {
        slots: {
          title: "transportInfo",
          customRender: "transportInfoCustom",
        },
        width: 150,
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.tracking_ticket_no'),
        slots: {
          customRender: "trackingNo",
        },
      },
      {
        slots: {
          title: "print",
          customRender: "printCustom",
        },
        width: 150,
      },
        {
        width: 60,
        title: "",
        slots: {
          customRender: "collectTaskStatus",
        },
      },
      {
        width: 150,
        title: "",
        slots: {
          customRender: "expectArriveTime",
        },
      },
    ];

    const innerProductColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        dataIndex: "seSku",
        width: 150,
        title: "SESKU",
      },
      {
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 500,
        dataIndex: 'rests',
        title: vueI18n.t('warehouse.customs_clearance_info'),
        slots: {
          customRender: "restsCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 400,
      },
      {
        dataIndex: "logisticsWay",//物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay",//交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging",//参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 100,
      },
      {
        dataIndex: "freights",//运费
        slots: {
          title: "freights",
          customRender: "freightsCustom",//平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details",//详情
        slots: {
          title: "details",
          customRender: "detailsCustom",//计费重、体积重系数：除600
        },
        width: 180,
      },
      {
        dataIndex: "optionalServices",//可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 180,
      }
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('logistics.quote_details'),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ]

    const customsInfoColumns = [
      {
        title: vueI18n.t("warehouse.serial_number"),
        dataIndex: "serialNumber ",
        slots: {

        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
        width: 150,
      },
      {
        title: vueI18n.t('logistics.trade_name'),
        dataIndex: "tradeName",
        slots: {
          customRender: "tradeNameCustom",
        },
        width: 150,
      },
      {
        title: vueI18n.t('warehouse.texture'),
        slots: {
          customRender: "texture",
        },
        width: 150,
      },
      {
        title: vueI18n.t('warehouse.brand_name'),
        slots: {
          customRender: "brand",
        },
        width: 150,
      },
      {
        title: vueI18n.t('warehouse.purpose_description'),
        slots: {
          customRender: "purposeDescription",
        },
        width: 250,
      },
      {
        title: "HS Code",
        dataIndex: "hsCode",
        width: 150,
      },
      {
        title: vueI18n.t('logistics.apply_middle_rate'),
        slots: {
          customRender: "applyMiddleRate",
        },
        width: 150,
      },
      {
        title: vueI18n.t('logistics.number'),
        dataIndex: "number",
        width: 150,
      },
      {
        title: vueI18n.t('logistics.subtotal'),
        slots: {
          customRender: "subtotal",
        },
        width: 150,
      },
    ]

    const state = reactive({
      pageLoading: false,
      payLoading: false,
      bulkPrintLoading: false,
      exportPackingListLoading: false,
      printInWarehourseorderLoading: false,
      currencys: [],
      plan: {
        boxTableColumns,
        innerColumns: innerProductColumns,
        id: "",
        no: "",
        name: "",
        creationTime: null,
        updateTime: null,

        status: null,
        countdownPaidSeconds: null,
        destinationType: null,
        toWarehouseId: "",
        toWarehouseType: "",
        toWarehouseCode: "",
        toNikeName: "",
        fbaTransportNo: "",
        productTrackNo: "",
        fromAddressData: {},
        toAddressData: {},
        producTableList: [],
        boxTotalCount: 0,
        transportTotalVolume: 0,
        transportTotalWeight: 0,
        totalContainerVolume: 0,
        totalContainerWeight: 0,
        transportTotalCharacteristic: null,
        isDoorTook: false,//是否上门揽件
        doorLinkUserName: "",
        doorContactPhone: "",

        toName: "",
        toPhone: "",
        outWarehouseNo: null,
        inWarehouseNo: null,
        toInWarehouseNo: null,

        seLogisticsAddress: "",
        seLogisticsContact: "",
        seLogisticsPhone: "",
        isInsure: null, //是否需要保险
      },
      customsInfo: [],
      customsTotalFee: "",
      customsSymbol: "",

      // 是否显示取消按钮
      isAllowCancel: false,
      cancelBtnLoading: false,

      logistics: {
        columnsLogisticsScheme: [],
        logisticsSchemeList: [],
        isShowVATInput: false,

        VAT: null,
        isRemoteArea: null,//是否为偏远地址
        isCommercial: null,//是否为商业地址,
        isSelectedCustoms: false,
        imgUrl: []
      },

      fee: {
        totalAmount: 0,
        currencyId: '',
        currencyCode: "",
        currencySymbol: "",
        fees: [
          {
            feeType: "",
            price: 0,
            amount: 0,
            num: 0
          }
        ]
      },
      isShowOrdeModal: false,
      payModal: {
        visible: false,
        loading: false
      },
      // 物流到付
      payOnArrivalLoading: false,
      // 尾程派送
      outForDelivery: {
        endJourneyType: null,
        express: null,
        transportationCompany: null,
      },
    })

    const getCommonFeeList = (list = []) => {
      if (Array.isArray(list)) {
        return list.map(item => ({
          feeType: item.feeType,
          symbol: state.fee.currencySymbol,
          value: item.amount,
          checkValue: item.amount,
          remark: item.remark,
        }))
      }
      return []
    }

    //币种
    const funcGetCurrencys = () => {
      return getCurrencys({ "isActive": true }).then((obj) => {
        let { result } = obj;
        state.currencys = result;
      });
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return '-'
      }
      return list.map(item => item.name).join(', ')
    };

    const handleParseTime = (num) => {
      let number = Number.parseInt(num)
      return Number.isNaN(number) ? num : number
    }

    const funcSearchPlanInfo = async (planId) => {
      state.pageLoading = true;
      getTransportPlanDetails({ "id": planId })
        .then((res) => {
          let r = res.result;
          try {
            state.plan.id = r.planBasicInfo.planId;
            state.plan.logisticsWarehouseId = r.planBasicInfo.logisticsWarehouseId;
            state.plan.no = r.planBasicInfo.planNo;
            state.plan.name = r.planBasicInfo.planName;
            state.plan.status = r.planBasicInfo.planStatus;
            state.plan.countdownPaidSeconds = r.planBasicInfo.countdownPaidSeconds;
            state.plan.creationTime = r.planBasicInfo.createTime;
            state.plan.updateTime = r.planBasicInfo.updateTime;
            state.plan.destinationType = r.planBasicInfo.destinationType;//目的仓库类型
            state.plan.toWarehouseId = r.planBasicInfo.toWarehouseId;
            state.plan.toWarehouseType = r.planBasicInfo.toWarehouseType;
            state.plan.toWarehouseCode = r.planBasicInfo.warehouseCode;
            state.plan.toNikeName = r.planBasicInfo.toName;
            state.plan.fbaTransportNo = r.planBasicInfo.amazonTransportNo;
            state.plan.productTrackNo = r.planBasicInfo.trackNo;
            state.plan.fromAddressData = r.planBasicInfo.fromAddress;
            state.plan.fromAddressData.fromName = r.planBasicInfo.fromName;
            state.plan.toAddressData = r.planBasicInfo.toAddress;
            state.plan.toAddressData.isInternationalTransport = r.planBasicInfo.isInternationalTransport;
            state.plan.seLogisticsAddress = r.planBasicInfo.logisitcWarehouseAddress;
            state.plan.seLogisticsNo = r.planBasicInfo.logisticsWarehouseNo;
            state.plan.seLogisticsPhone = r.planBasicInfo.logisitcWarehouseAddress.linkPhone;
            state.plan.seLogisticsContact = r.planBasicInfo.logisitcWarehouseAddress.linkUserName;
            state.plan.isDoorTook = r.planBasicInfo.isDoorTook;
            state.plan.doorLinkUserName = r.planBasicInfo.doorLinkUserName;
            state.plan.doorContactPhone = r.planBasicInfo.doorContactPhone;
            
            state.plan.toName = r.planBasicInfo.receiptUserName;
            state.plan.toPhone = r.planBasicInfo.receiptContactPhone;
            state.plan.outWarehouseNo = r.planBasicInfo.outWarehouseNo;
            state.plan.inWarehouseNo = r.planBasicInfo.inWarehouseNo;
            state.plan.toInWarehouseNo = r.planBasicInfo.toInWarehouseNo;
            state.plan.logisticsWarehouseNo = r.planBasicInfo.logisticsWarehouseNo;
            state.plan.isPayOnArrival = r.planBasicInfo.isPayOnArrival;
            state.plan.logisticsNode = r.planBasicInfo.logisticsNode;
            state.plan.isSupportLogisticsPayOnArrival = r.planBasicInfo.isSupportLogisticsPayOnArrival;
            state.isAllowCancel = r.planBasicInfo.isAllowCancel;
            state.plan.isInsure = r.planBasicInfo.isInsure;

            state.customsTotalFee = r.customsInfo?.totalPrice?.totalPrice;
            state.customsSymbol = r.customsInfo?.totalPrice?.currencySymbol;

            state.logistics.isRemoteArea = r.transportPlanLogistic?.logisticsBaseInfo?.isRemoteArea;
            state.logistics.isCommercial = r.transportPlanLogistic?.logisticsBaseInfo?.isCommercial;
            state.logistics.logisticsType = r.transportPlanLogistic?.logisticsBaseInfo?.logisticsType;
            state.logistics.isSelectedCustoms = r.isUploadHsInfo;

            state.plan.producTableList = r.planBoxInfo.transportPlanBoxData.map((x, index) => {
              x.loading = false;
              x.key = index + 1;
              x.serialNumber = index + 1;
              return x;
            })

            state.plan.boxTotalCount = r.planBoxInfo.totalBox ?? '0';
            state.plan.transportTotalVolume = r.planBoxInfo.totalVolume ? cmCubicToM(r.planBoxInfo.totalVolume) : '-';
            state.plan.transportTotalWeight = r.planBoxInfo.totalBoxWeight ? gToKg(r.planBoxInfo.totalBoxWeight) : '-';
            state.plan.transportTotalCharacteristic = r.planBoxInfo.transportCharacteristics;
            state.plan.totalContainerVolume = r.planBoxInfo.totalContainerVolume ? cmCubicToM(r.planBoxInfo.totalContainerVolume) : 0;
            state.plan.totalContainerWeight = r.planBoxInfo.totalContainerWeight ? gToKg(r.planBoxInfo.totalContainerWeight) : 0;

            let columns = [];
            if (!state.plan.toAddressData.isInternationalTransport) {
              columns = innerProductColumns.filter(x => x.dataIndex != "rests");
            } else {
              columns = innerProductColumns;
            }
            state.plan.innerColumns = columns;

            if (r.customsWayInfo) {
              if (r.customsWayInfo.integrationUrl) {
                state.logistics.imgUrl =
                  [{
                    name: "logistics.four_in_one_file",
                    url: r.customsWayInfo.integrationUrl
                  }];
              } else {
                state.logistics.imgUrl = [
                  {
                    name: "logistics.customs_declaration",
                    url: r.customsWayInfo.customsDeclarationUrl
                  },
                  {
                    name: "logistics.contract",
                    url: r.customsWayInfo.contractUrl
                  },
                  {
                    name: "logistics.invoice",
                    url: r.customsWayInfo.invoiceUrl
                  },
                  {
                    name: "logistics.packing_documents",
                    url: r.customsWayInfo.packingUrl
                  }
                ];
              }
            }


            state.customsInfo = r.customsInfo?.customsInfoCollect?.map((x, index) => {
              return {
                key: index + 1,
                productName: x.productName,
                productNo: x.productNo,
                enTradeNameCustom: x.brandEnName,
                chTradeNameCustom: x.brandCName,
                enMaterial: x.enMaterial,
                cnMaterial: x.cnMaterial,
                brand: x.brand,
                purposeEnDescription: x.purposeEnDescription,
                purposeCnDescription: x.purposeCnDescription,
                currencySymbol: x.currencySymbol,
                applyMiddleRate: x.averagePrice,
                hsCode: x.hsCode,
                number: x.unit,
                subtotal: x.totalPrice,
              }
            });

            let logisticsTemp = r.transportPlanLogistic ? [r.transportPlanLogistic] : [];
            state.logistics.logisticsSchemeList = logisticsTemp?.map((x, index) => {
              if (index == 0) {
                state.logistics.isShowVATInput = x?.logisticsRegionInfo?.isVatRequired;//是否支持Vat
                state.logistics.VAT = x?.logisticsRegionInfo?.vatCode;
              }
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType,//签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice,//平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices,//合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol,//货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight ? gToKg(x.logisticsBaseInfo.chargeableWeight) : '0',//计费重
                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam,//体积系数
                chargeableVolume: cmCubicToM(x.logisticsBaseInfo.chargeableVolume),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,
                logisticsRegionInfo: x.logisticsRegionInfo,//是否支持DDP和签字

                // 卡车相关
                feeCalculationMethod: x.logisticsBaseInfo?.feeCalculationMethod,
                truckBaseInfo: x.logisticsBaseInfo?.truckBaseInfo,
              }
            })

            if (state.logistics.logisticsSchemeList.length) {
              let logisticsSchemeItem = state.logistics.logisticsSchemeList[0]
              if (logisticsSchemeItem.feeCalculationMethod === feeCalculationMethodEnum.template) {
                // 物流
                state.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
              } else {
                // 卡车
                state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
              }
            }

            state.fee = r.fee;

            state.outForDelivery = r.outForDelivery;
          } catch (error) {
            // console.log(error);
          }
        })
        .catch((res) => {
        })
        .finally(() => {
          state.pageLoading = false;
        })
    };

    const handleCancel = () => {
      state.cancelBtnLoading = true
      cancelTransportPlan({ id: state.plan.id }).then(({ result }) => {
        message.success(vueI18n.t("common.succeed"));
        state.plan.status = transportStatusEnum.canceled
        state.isAllowCancel = false
        let inWarehousePlanId  = result?.inWarehousePlanId;
        if (inWarehousePlanId) {
          let name = '';
          let link = '';
          if (state.plan.toWarehouseType === warehouseTypeEnum.storageWarehouse) {
            name = 'menu.storage_in_warehouse_plan';
            link = '/storage/inbound/detail/' + inWarehousePlanId;
          } else if (state.plan.toWarehouseType === warehouseTypeEnum.consignmentWarehouse) {
            name = 'menu.consignment_inbound_detail';
            link = '/consignment/inbound/detail/' + inWarehousePlanId;
          }
          // 出库没有跳转
          // if (!!state.plan.outWarehouseNo) {
          //   name = 'menu.storage_out_plan';
          //   link = '/storage/outplandetails/' + result.id;
          // }
          if (name && link) {
            cPlanCanceledAfterModalRef.value.open({ name, link });
          }
        }
      }).finally(() => {
        state.cancelBtnLoading = false
      })
    }

    const handleBacktrack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transport_plan" });
    }

    const handleShowOrdeModal = () => {
      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    }

    const handleConfirm=()=>{
        state.payOnArrivalLoading = true;
        confirmPayOnArrival({ id: state.plan.id,payFee:filters.amountToFixed2(state.fee?.totalAmount) }).then(() => {
          message.success(vueI18n.t("common.succeed"));
          funcSearchPlanInfo(state.plan.id);
          funcGetCurrencys();
        }).catch(() => { }).finally(() => {
          state.payOnArrivalLoading = false;
        })
    }

    const handleOrderPay = () => {
      let pra = {
        "id": state.plan.id,
         payFee:filters.amountToFixed2(state.fee?.totalAmount)
      };
      state.payModal.loading = true;
      payOrder(pra).then((res) => {
        state.payModal.visible = false;
        message.success(vueI18n.t("common.succeed"));
        state.plan.status = transportStatusEnum.havePaid;
        state.payModal.loading = false;
      }).catch(() => {
        state.payModal.loading = false;
      })
    }

    const handleShowTrackModal = () => {
      traceModalRef.value.handleTrackShowModal(state.plan.producTableList)
    }

    const handleBulkPrint = async () => {
      try {
        state.bulkPrintLoading = true;
        let boxLabelPdfUrls = batchPrintBoxOfPlanTransport(state.plan.id);
        await localPrint(boxLabelPdfUrls, "pdf");
      } catch (error) {
      } finally {
        state.bulkPrintLoading = false;
      }
    }

    const handlePrintBoxLabel = async (record) => {
      try {
        record.loading = true;
        let boxLabelPdfUrl = printBoxOfPlanTransport(state.plan.id, record.planDetailId);
        await localPrint(boxLabelPdfUrl, "pdf")
      } catch (error) {
      } finally {
        record.loading = false;
      }
    }

    const handlePrintInWarehourseorder = () => {
      state.printInWarehourseorderLoading = true;
      let boxLabelPdfUrl = printInWarehourseorder(state.plan.id);
      localPrint(boxLabelPdfUrl, "pdf")
        .catch((error) => {
          state.printInWarehourseorderLoading = false;
        })
        .finally(() => {
          state.printInWarehourseorderLoading = false;
        })
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(productStatisticsTypeEnum.userSide, route.params.id)
    }

    const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      warehouseId: null,
      list: [],
      no: null,
    })

     const handleShowModalTrackingTrajectory = () => {
        trackModal.visible=true;
        trackModal.planId= state.plan.id;
        trackModal.warehouseId= state.plan.logisticsWarehouseId;
        trackModal.no= state.plan.no;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const handleExportPackingList=()=>{
        state.exportPackingListLoading = true
      let url = exportPackingList()
      const postData = {
        planId: route.params.id,
      };
      downloadFile(url, `${state.plan.no}_packing_list_${dateString()}.xlsx`, "POST", postData)
        .then(() => {
          state.exportPackingListLoading = false
        })
        .catch(() => {
          state.exportPackingListLoading = false
        })
    }

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        state.plan.id = planId;
        funcSearchPlanInfo(planId);
        funcGetCurrencys();
      }
    });

    return {
      ...toRefs(state),
      transportStatusEnum,
      destinationTypeEnum,
      logisticsWayEnum,
      logisticsAccountingPeriodCalculationWayEnum,
      logisticsCalcMethodEnum,
      transportPlanEndJourneyTypeEnum,
      customsInfoColumns,
      gToKg,
      cmCubicToM,
      getLinkman,
      getLinkUser,
      urlHelper,
      traceModalRef,
      refPayment,
      currentPageIndex,
      productStatisticsModalRef,
      cPlanCanceledAfterModalRef,
      trackModal,

      getCommonFeeList,
      getAddress,
      getAccessorialsName,
      handleParseTime,
      handleCancel,
      handleBacktrack,
      handleShowOrdeModal,
      handleConfirm,
      handleOrderPay,
      handleShowTrackModal,
      handleBulkPrint,
      handlePrintBoxLabel,
      handlePrintInWarehourseorder,
      handleShowProductStatisticsModal,
      handleShowModalTrackingTrajectory,
      handleExportPackingList,
      funcSearchPlanInfo,
    };
  }
})
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}

.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 45px;
  }
}
</style>