import siteOptions from "../../../siteconfigs/index";
import urlHelper from "../../../utils/urlHelper.js";

const Api = {
  //批量打印入库计划箱标
  batchPrintBoxesOfPlan:
    "/api/WarehouseService/Print/GetInWarehpusePlanBoxBatchPrint/inplanboxbatch.pdf",

  //打印箱标(入库计划)
  printBoxOfPlan:
    "/api/WarehouseService/Print/GetInWarehpusePlanBoxPrint/inplanbox.pdf",

  //打印库位
  printLocation: "/api/WarehouseService/Print/GetLocationPrint/location.pdf",

  //批量打印待执行任务表
  batchPrintLocationTask:
    "/api/WarehouseService/Print/GetLocationTaskBatchPrint/locationtaskbatch.pdf",

  //打印任务
  printLocationTask:
    "/api/WarehouseService/Print/GetLocationTaskPrint/locationtask.pdf",

  //批量打印入库计划箱标
  batchPrintBoxOfPlanTransport:
    "/api/WarehouseService/TransportPlan/BatchPrint/TransportPlanBatchPrintBox.pdf",
  //打印箱标(运输计划)
  printBoxOfPlanTransport:
    "/api/WarehouseService/TransportPlan/Print/TransportPlanPrintBox.pdf",

  // 批量打印箱标(运输计划)
  printBoxOfTransportPlanBatchPlan: "/api/WarehouseService/TransportPlan/BatchPlanPrint/TransportPlanBatchPlanPrintBox.pdf",

  //打印账单
  printBill: "/api/ProductService/MonthAccountBill/GetOrCreateMonthBillPdf",

  //获取送仓单
  printInWarehourseorder:
    "/api/WarehouseService/TransportPlan/GetSEInWarehourseOrderPdf/seinwarehourseorder.pdf",

  //获得出库单
  printOutTheList:
    "/api/WarehouseService/Print/GetOutwarehousePlanPrint/outplan.pdf",
};

//获取批量打印入库计划箱标url
export const batchPrintBoxesOfPlanUrl = (id) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchPrintBoxesOfPlan
  );
  url = urlHelper.addQueryString(url, "id", id);
  return url;
};

//获取打印箱标url (入库计划)
export const printBoxOfPlanUrl = (id, detailId) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printBoxOfPlan);
  url = urlHelper.addQueryString(url, "id", id);
  url = urlHelper.addQueryString(url, "detailId", detailId);
  return url;
};

//打印库位url
export const printLocationUrl = (id) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printLocation);
  url = urlHelper.addQueryString(url, "id", id);
  return url;
};

//批量打印待执行库位任务表url
export const batchPrintLocationTaskUrl = () => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchPrintLocationTask
  );
  return url;
};

//批量打印库位任务表url
export const printLocationTaskUrl = (id) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printLocationTask);
  url = urlHelper.addQueryString(url, "id", id);
  return url;
};

//获取批量打印运输计划箱标url
export const batchPrintBoxOfPlanTransport = (id) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchPrintBoxOfPlanTransport
  );
  url = urlHelper.addQueryString(url, "id", id);
  return url;
};

//获取打印箱标url (运输计划)
export const printBoxOfPlanTransport = (id, detailId) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.printBoxOfPlanTransport
  );
  url = urlHelper.addQueryString(url, "id", id);
  url = urlHelper.addQueryString(url, "detailId", detailId);
  return url;
};

// 获取批量打印箱标url (运输计划)
export const printBoxOfTransportPlanBatchPlan = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.printBoxOfTransportPlanBatchPlan
  );
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
};

//获取打印账单
export const printBill = (monthAccountBillId) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printBill);
  url = urlHelper.addQueryString(url, "monthAccountBillId", monthAccountBillId);
  return url;
};

//获取送仓单
export const printInWarehourseorder = (planId) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.printInWarehourseorder
  );
  url = urlHelper.addQueryString(url, "id", planId);
  return url;
};

//获取出库单
export const printOutTheList = (planId) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.printOutTheList);
  url = urlHelper.addQueryString(url, "id", planId);
  return url;
};
